import { useEffect, useState } from "react";
import { Loading, PageTitle, Pagination, SearchBar, SearchCount } from "../../components";
import { useLocalization } from "../../hooks/useLocalization";
import { TeacherFilter } from "../../services/Teacher/TeacherService";
import { TeacherTable } from "./components";
import TeacherDetailSearch from "./components/TeacherList/TeacherDetailSearch";
import useAuth from "../../hooks/useAuth";
import {getButtonPermission} from "../../components/Buttons";

const Teacher = () => {

  const strings = useLocalization();

  const [totalRecord, setTotalRecord] = useState(0);

  const { checkPermission, employeeTypeId } = useAuth();
  const roles = getButtonPermission({ group: "teacherList", name: "list" });
  const permission = checkPermission(roles, employeeTypeId);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [detailSearch, setDetailSearch] = useState({
    status: 1,
    searchText: '',
  })

  //Arama verileri değiştiğinde değerleri sıfırlıyoruz
  const resetValue = () => {
    setLoading(loading => true);
  }

  //input değişiklikleri bu alandan yönetiliyor
  const changeValue = (value) => {
    setDetailSearch({ ...detailSearch, searchText: value });
    resetValue();
  }

  //select değişiklikleri bu alandan yönetiliyor
  const changeDetail = (values) => {
    setDetailSearch({ ...detailSearch, ...values });
    resetValue();
  }

  const getTeachers = async () => {

    const model = {
      search: JSON.stringify({ 'status': detailSearch.status, 'gender': -1, 'searchText': detailSearch.searchText })
    }
    
    const result = await TeacherFilter(model);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      if(permission) {
          setData(data => resultContent.data);
      } else {
          // TeacherID 1500 -> Teacher Josiah (Yedek Eğitmen)
          // TeacherID 1501 -> Teacher Barnie (Sanal Eğitmen)
          let filteredData = resultContent.data.filter(data => data.id !== +process.env.REACT_APP_TEACHER_JOSIAH_ID && data.id !== +process.env.REACT_APP_TEACHER_BARNIE_ID);
          setData(data => filteredData);
      }

      setTotalRecord(totalRecord => resultContent.recordsTotal)
      setLoading(loading => false)
    }
  }

  useEffect(() => {

    let abortController = new AbortController();

    getTeachers();

    return () => {
      abortController.abort();
    }
  }, [detailSearch, permission])

  return (
    <>
      <TeacherDetailSearch
        loading={loading}
        changeDetail={changeDetail}
      />
      <SearchBar
          changeValue={changeValue}
          placeHolder={strings.teacher.teacher_list.search_text}
          isDisabled={loading}
      />
      {!loading && <SearchCount count={totalRecord} />}
      <PageTitle title={strings.sidebar.teacher.list} />
      {loading ?
        (
          <Loading />
        )
        :
        (
          <>
            <TeacherTable data={data} />
          </>
        )
      }
    </>
  );
};

export default Teacher;
