import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {Dropdown, Input, showCenteredAlertTwoButtons, showTopAlert} from '../../../../components';
import {Minutes} from '../../../../components/Constants';
import {adjustTimeWithTimeZone, calculateEndTime, convertToAMPMFormat,} from '../../../../components/Utility';
import {useLocalization} from '../../../../hooks/useLocalization';
import {AddBreak} from '../../../../services/Teacher/ScheduleService';
import useAuth from "../../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {url} from "../../../../routes/utility";
import {getButtonPermission} from "../../../../components/Buttons";

const AddBreakModal = (props) => {

    const {closeButton, classes, time, day, teacherId} = props;
    const strings = useLocalization();
    const navigate = useNavigate();

    const timeZone = useSelector(state => state.timeZoneStore.timeZone);
    const {employeeTypeId, checkPermission} = useAuth();

    const timeZoneRoles = getButtonPermission({group: "timeZone", name: "timeZoneTR"});
    const timeZonePermission = checkPermission(timeZoneRoles, employeeTypeId);

    const minuteErrorRef = useRef();

    const firstStatus = {id: -1, name: strings.detail_search_titles.status};

    const firstDuration = {id: -1, name: strings.detail_search_titles.minutes};
    const durationList = [firstDuration, ...Minutes];

    const [status, setStatus] = useState(firstStatus);
    const [duration, setDuration] = useState(firstDuration);
    const [date, setDate] = useState(new Date());

    const [alertText, setAlertText] = useState(null);

    //Onay modalının text'ini kontrol eder.
    const handleBlockAlertText = () => {
        setAlertText(strings.teacher.teacher_program.lesson_blocked);
    }

    //Bloke ve geçiçi bloke eklerken validation yapar.
    const submitHandle = (e) => {

        e.preventDefault();
        let valid = true;

        minuteErrorRef.current.innerText = "";

        if (duration.id === -1) {
            valid = false;
            minuteErrorRef.current.innerText = strings.errors.minute_null_error;
        }

        if (valid) {
            showCenteredAlertTwoButtons(
                strings.alert.centered_alert_two_buttons.title_confirm,
                alertText,
                strings.button.confirm,
                strings.button.cancel,
                sendToEndpoint
            );
        }
    }

    //Bloke veya geçiçi bloke ekleme işlemi için servise gider.
    const sendToEndpoint = async () => {

        let timeWithoutTimeZone = adjustTimeWithTimeZone(time, timeZone);
        let model = {
            teacherId: parseInt(teacherId),
            beginTime: timeWithoutTimeZone,
            endTime: calculateEndTime(timeWithoutTimeZone, duration.name),
            day: day,
            status: 4,
            duration: duration.name,
            blockTypeStatus: 4
        }

        const result = await AddBreak(model);

        if (result.status === 200) {

            if (status.id === 4) {
                showTopAlert(strings.teacher.add_break.block_success_message, "success");
            }

            closeButton();
        } else {
            showTopAlert(result.content, "error");
        }
    }

    useEffect(() => {
        if (time && duration.id !== -1) {
            calculateEndTime(time, duration.name);
        }
    }, [time, duration])

    useEffect(() => {
        handleBlockAlertText();
    }, [status, date])

    return (
        <>
            <form onSubmit={submitHandle} className={`flex flex-col gap-4 mt-5 ${classes}`}>
                <div className="flex justify-center">
                    <p className="font-bold text-[15px]">{strings.teacher.teacher_program.blocked}</p>
                </div>
                <div className="flex flex-col md:flex-row text-start gap-4 w-full">
                    <div className="flex flex-col px-[10px] pb-0 w-full">
                        <label htmlFor="member_hour">{strings.table.hour}</label>
                        <Input
                            id="member_hour"
                            type="text"
                            isDisabled={true}
                            value={convertToAMPMFormat(time, timeZonePermission)}
                        />
                    </div>
                    <div className="flex flex-col px-[10px] pb-0 w-full">
                        <label htmlFor="member_minute">{strings.table.minute}</label>
                        <Dropdown
                            classnames="md:max-w-full"
                            data={durationList}
                            selectedData={duration}
                            setSelectedData={setDuration}
                        />
                        <span ref={minuteErrorRef} id="minuteError"
                              className="text-danger field-validation-valid px-[10px]"></span>
                    </div>
                </div>
                <div className="flex gap-3 justify-center">
                    <button
                        type='submit'
                        className="button search-button gap-3 text-white bg-blue border-blue mt-7 px-10"
                    >
                        {strings.button.save_upper}
                    </button>
                    <button
                        type='button'
                        className="button search-button gap-3 text-white bg-red border-red focus:border-red mt-7 px-10"
                        onClick={() => {
                            //closeButton
                            navigate(url("dashboard"));
                        }}
                    >
                        {strings.button.cancel_upper}
                    </button>
                </div>
            </form>
        </>
    )
};

export default AddBreakModal;