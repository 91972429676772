import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { getCookie } from 'ko-basic-cookie';
import { cookieName } from '../../components/Constants';
import { url } from '../../routes/utility';
import { decodeToken } from '../../components/Utility';
import { CheckEmployeeStatus } from '../../services/Auth/AuthService';
import { useDispatch } from 'react-redux';
import { employeeLogout } from '../../store/EmployeeSlice';

const CheckAuth = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = getCookie(cookieName) || false;

    const [employeeStatus, setEmployeeStatus] = useState(false);
    const firstVisit = useRef(true);


    useEffect(() => {

        if (token) {
            async function checkEmployeeStatus(employeeId) {
                return await CheckEmployeeStatus(employeeId);
            }

            const employee = decodeToken(token);
            checkEmployeeStatus(employee.EmployeeId).then(data => {
                if (data.status === 200) {
                    setEmployeeStatus(employeeStatus => data.content);
                    firstVisit.current = false;
                }
            });

        }
        else {
            navigate(url("auth.login"));
        }
    }, [])

    useEffect(() => {

        //Sayfa ilk kez yüklenmiyorsa
        if (!firstVisit.current) {

            let redirectUrl = "dashboard";

            //kullanıcı durumu pasif ise login sayfasına yönlendirilmeli
            if (employeeStatus === "Pasif") {
                dispatch(employeeLogout());
                redirectUrl = "login";
            }

            //navigate
            navigate(url(redirectUrl));

        }

    }, [employeeStatus])

}

export default CheckAuth