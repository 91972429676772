import { useRoutes } from 'react-router-dom';
import routes from './routes/routes';
import useTimeZoneService from "./hooks/useTimeZoneService";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {_setTimeZone} from "./store/TimeZoneSlice";

function App() {

  const { timeZone } = useTimeZoneService();
  const dispatch = useDispatch();

  useEffect(() => {
    if(timeZone !== -1){
      dispatch(_setTimeZone(timeZone));
    }
  }, [timeZone]);

  return useRoutes(routes);
}

export default App;
