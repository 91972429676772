import React, { forwardRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useLocalization } from "../../hooks/useLocalization";
import { url } from "../../routes/utility";
import { employeeLogout } from "../../store/EmployeeSlice";
import useAnalytics from "../../hooks/useAnalytics";
import classnames from "classnames";

const NavbarProfile = forwardRef(() => {
    
  const dispatch = useDispatch();
  const strings = useLocalization();
  const { employee } = useAuth();
  const analytics = useAnalytics();

  return (
    <>
      <div className="flex px-4">
        <img
          className="rounded-lg"
          src={process.env.REACT_APP_PROFILE_URL + "/employee/" + employee.ProfilePicture}
          width="60"
          height="60"
          alt=""
        />
        <div className="ml-2">
          <p className="my-1">{employee.FullName}</p>
          <p className="text-[12px] text-[#6c757d] mb-1">{employee.Email}</p>
        </div>
      </div>

      <hr className="mb-3 mt-4 px-4" />

        <Link
            to={url("profile.changepassword")}
            className="block px-5 py-[3px] hover:bg-[#F8F9FA] focus:bg-[#F8F9FA] w-full text-start"
        >
            {strings.sidebar.profile.change_password}
        </Link>

      <button
        onClick={() => {
          dispatch(employeeLogout());
          analytics.useAnalytics('Kullanici_Cikis_Yapti');
        }}
        className="block px-5 py-[3px] cursor-pointer hover:bg-[#F8F9FA] focus:bg-[#F8F9FA] w-full text-start"
        tabIndex="-1"
      >
        {strings.sidebar.profile.logout}
      </button>
    </>
  );
});

export default NavbarProfile;
