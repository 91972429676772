import { createSlice } from "@reduxjs/toolkit";

export const TimeZoneSlice = createSlice({
    name: 'timeZone',
    initialState : {
        timeZone: -1,
    },
    reducers: {
        _setTimeZone: (state, action) => {
            state.timeZone = action.payload;
        },
    }
})

export const { _setTimeZone } = TimeZoneSlice.actions

export default TimeZoneSlice.reducer