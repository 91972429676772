
export const cookieName = process.env.REACT_APP_JWT_COOKIE_NAME;
export const termStorageName = process.env.REACT_APP_JWT_TERM_NAME;

export const EmployeeStatusType = Object.freeze(
    {
        "Sistem": 1,
        "Yazılımcı": 2,
        "Yönetici": 3,
        "Satış Temsilcisi": 4,
        "Satış Takım Lideri": 5,
        "Satış Takım Lideri Yardımcısı": 6,
        "Verimlilik Temsilcisi": 7,
        "Verimlilik Takım Lideri": 8,
        "Verimlilik Takım Lideri Yardımcısı": 9,
        "Kurumsal Destek Temsilcisi": 10,
        "Kurumsal Takım Lideri": 11,
        "Kurumsal Takım Lideri Yardımcısı": 12,
        "Kurumsal Kilit Hesap Yöneticisi": 13,
        "Muhasebe": 14,
        "Destek": 15,
        "Referansatör": 16,
        "İnsan Kaynakları": 17,
        "Pazarlama": 18,
        "Ürün Yöneticisi": 19,
        "Santral": 20,
        "Supervisor": 21,
        "Eğitmen Supervisor": 22,
        "Eğitmen İnsan Kaynakları": 23
    }
);

export const TeacherStatus = [
    { id: 0, name: 'Pasif', eng: 'Passive' },
    { id: 1, name: 'Aktif', eng: 'Active' },
    // { id: 2, name: 'Ayrılacak', eng: 'Will Leave'},
];

export const GeneralStatus = [
    { id: 0, name: 'Pasif', eng: 'Passive' },
    { id: 1, name: 'Aktif', eng: 'Active' }
];

export const Gender = [
    { id: 0, name: 'Erkek', eng: 'Male', icon: 'man' },
    { id: 1, name: 'Kadın', eng: 'Female', icon: 'woman' },
];

export const MaritalStatus = [
    { id: 0, name: 'Bekar', eng: 'Single'},
    { id: 1, name: 'Evli', eng: 'Married'},
    { id: 2, name: 'Boşanmış', eng: 'Divorced'},
    { id: 3, name: 'Dul', eng: 'Widowed'}
];

export const TeacherBlockStatus = [
    { id: 4, name: "Bloke Et", eng: 'Block'},
    { id: 6, name: "Geçiçi Olarak Bloke Et", eng: 'Temporarily Block'}
];

export const WeekDays = [
    // { id: 0, name: "Pazar", eng: 'Sunday'},
    { id: 1, name: "#1", eng: '#1'},
    { id: 2, name: "#2", eng: '#2'},
    { id: 3, name: "#3", eng: '#3'},
    { id: 4, name: "#4", eng: '#4'},
    { id: 5, name: "#5", eng: '#5'},
    // { id: 6, name: "Cumartesi", eng: 'Saturday'},
];

export const Minutes = [
    { id: 0, name: 10 },
    { id: 1, name: 20 },
    { id: 2, name: 30 },
    { id: 3, name: 40 },
    { id: 4, name: 50 },
    { id: 5, name: 60 }
];

export const StateList = [
    { id: 1, name: "Alabama" },
    { id: 2, name: "Alaska" },
    { id: 3, name: "Arizona" },
    { id: 4, name: "Arkansas" },
    { id: 5, name: "California" },
    { id: 6, name: "Colorado" },
    { id: 7, name: "Connecticut" },
    { id: 8, name: "Delaware" },
    { id: 9, name: "Florida" },
    { id: 10, name: "Georgia" },
    { id: 11, name: "Hawaii" },
    { id: 12, name: "Idaho" },
    { id: 13, name: "Illinois" },
    { id: 14, name: "Indiana" },
    { id: 15, name: "Iowa" },
    { id: 16, name: "Kansas" },
    { id: 17, name: "Kentucky" },
    { id: 18, name: "Louisiana" },
    { id: 19, name: "Maine" },
    { id: 20, name: "Maryland" },
    { id: 21, name: "Massachusetts" },
    { id: 22, name: "Michigan" },
    { id: 23, name: "Minnesota" },
    { id: 24, name: "Mississippi" },
    { id: 25, name: "Missouri" },
    { id: 26, name: "Montana" },
    { id: 27, name: "Nebraska" },
    { id: 28, name: "Nevada" },
    { id: 29, name: "New Hampshire" },
    { id: 30, name: "New Jersey" },
    { id: 31, name: "New Mexico" },
    { id: 32, name: "New York" },
    { id: 33, name: "North Carolina" },
    { id: 34, name: "North Dakota" },
    { id: 35, name: "Ohio" },
    { id: 36, name: "Oklahoma" },
    { id: 37, name: "Oregon" },
    { id: 38, name: "Pennsylvania" },
    { id: 39, name: "Rhode Island" },
    { id: 40, name: "South Carolina" },
    { id: 41, name: "South Dakota" },
    { id: 42, name: "Tennessee" },
    { id: 43, name: "Texas" },
    { id: 44, name: "Utah" },
    { id: 45, name: "Vermont" },
    { id: 46, name: "Virginia" },
    { id: 47, name: "Washington" },
    { id: 48, name: "West Virginia" },
    { id: 49, name: "Wisconsin" },
    { id: 50, name: "Wyoming" }
];

export const ServiceErrorMessages = Object.freeze(
    {
        "TEACHER_ALREADY_REGISTERED": "Teacher is already registered!",
        "TWO_HOUR_RULE" : "The teacher couldn't set to passive because of students who didn't follow the 2-hour rule.(2-hour rule compliant students moved to Teacher Unknown.)",
        "ESD_NOT_IN_TIME_INTERVAL": "You are not in the change timeframe!",
        'ESD_TWO_HOURS_ERROR': 'There is no 2 hours difference between the time you selected and the current time!',
    }
);
