import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {DefaultProfile} from "../../assets/img";
import {Dropdown, Input, PageTitle, showTopAlert} from "../../components";
import {Gender, MaritalStatus, StateList, TeacherStatus} from "../../components/Constants";
import {checkNumber} from "../../components/Utility";
import {useLocalization} from "../../hooks/useLocalization";
import {AddTeacher, UpdateInterests, UpdatePersonals} from "../../services/Teacher/TeacherService";
import {CreateShift} from "./components";
import {useNavigate} from "react-router-dom";
import {url} from "../../routes/utility";
import {ServiceErrorMessages} from "../../consts/Constants";

const CreateTeacher = () => {

  const strings = useLocalization();
  const language = useSelector((state) => state.localizationStore.language);
  const navigate = useNavigate();

  const topRef = useRef();
  const endRef = useRef();

  const teacherIdErrorRef = useRef();
  const fullNameErrorRef = useRef();
  const genderErrorRef = useRef();
  const birthDateErrorRef = useRef();
  const statusErrorRef = useRef();

  //kişisel bilgiler stateler
  const [teacher, setTeacher] = useState({
    teacherId: null,
    about: "",
    fullName: "Teacher",
    gender: -1,
    birthPlace: "",
    birthDate: "",
    university: "",
    department: "",
    profilePictureUrl: "",
    soundUrl: "",
    status: -1,
    maritalStatus: -1,
  });

  useEffect(() => {
    if(teacher.teacherId){
      setTeacher({
        ...teacher,
        profilePictureUrl: `${teacher.teacherId}.jpg`
      })
    } else {
      setTeacher({
        ...teacher,
        profilePictureUrl: ""
      })
    }
  }, [teacher.teacherId]);

  const [isTeacherCreated, setIsTeacherCreated] = useState(false);
  const [isPersonalInformationsDisabled, setIsPersonalInformationsDisabled] = useState(false);

  const firstState = { id: -1, name: strings.form_info.choose_state};
  const [selectedState, setSelectedState] = useState(firstState);

  //Dropdown'a state göndermek zorunlu olduğu için dropdown kısımları için ayrı state'ler oluşturuluyor.
  const firstGender = { id: -1, name: strings.form_info.choose_gender };
  const [gender, setGender] = useState(firstGender);

  const firstTeacherStatus = { id: -1, name: strings.form_info.choose_status };
  const [teacherStatus, setTeacherStatus] = useState(firstTeacherStatus);

  const firstMaritalStatus = {id: -1, name: strings.form_info.choose_marital_status,};
  const [maritalStatus, setMaritalStatus] = useState(firstMaritalStatus);

  //Birth year değeri servise gönderilirken manipüle edileceği için ayrı bir state üzerinden ilerlendi.
  const [birthYear, setBirthYear] = useState("");

  //Statik data'lar için state'ler.
  const [genderData, setGenderData] = useState([]);
  const [maritalStatusData, setMaritalStatusData] = useState([]);
  const [teacherStatusData, setTeacherStatusData] = useState([]);

  //İlgi alanları için state'ler
  const [areasOfInterestInput, setAreasOfInterestInput] = useState("");
  const [areasOfInterestData, setAreasOfInterestData] = useState([]);

  //Personal alanları için state'ler
  const [areasOfPersonalInput, setAreasOfPersonalInput] = useState("");
  const [areasOfPersonalData, setAreasOfPersonalData] = useState([]);

  //Input alanları için onChange durumu name'e göre kontrol ediliyor.
  //Name alanı state'teki değer ile aynıysa değişiklik state'e yansıtılıyor.
  const handleChange = (e) => {
      setTeacher({
        ...teacher,
        [e.target.name]: e.target.value,
      });
  };

  const addInterests = async (teacherId, areasOfInterestData) => {
    let model = {
      teacherId: teacherId,
      interests: areasOfInterestData,
    };

    await UpdateInterests(model);
  };

  const addPersonals = async (teacherId, areasOfPersonalData) => {
    let model = {
      teacherId: teacherId,
      personals: areasOfPersonalData,
    };

    await UpdatePersonals(model);
  };

  const submitHandle = async (e) => {
    e.preventDefault();

    let valid = true;

    teacherIdErrorRef.current.innerText = "";
    fullNameErrorRef.current.innerText = "";
    genderErrorRef.current.innerText = "";
    birthDateErrorRef.current.innerText = "";
    statusErrorRef.current.innerText = "";

    if (teacher.teacherId === null) {
      valid = false;
      teacherIdErrorRef.current.innerText =
        strings.errors.teacher_id_null_error;
    }
    if (teacher.fullName === "") {
      valid = false;
      fullNameErrorRef.current.innerText = strings.errors.full_name_null_error;
    }
    if (birthYear.length !== 4) {
      valid = false;
      birthDateErrorRef.current.innerText = strings.errors.birth_date_format_error;
    }
    if (teacher.gender === -1) {
      valid = false;
      genderErrorRef.current.innerText = strings.errors.gender_null_error;
    }
    if (teacher.status === -1) {
      valid = false;
      statusErrorRef.current.innerText = strings.errors.status_null_error;
    }

    if (valid) {
      const result = await AddTeacher(teacher);

      if (result.status === 200) {

        addInterests(teacher.teacherId, areasOfInterestData);
        addPersonals(teacher.teacherId, areasOfPersonalData);
        setIsTeacherCreated((isTeacherCreated) => true);
        endRef.current.focus();
        showTopAlert(
          strings.teacher.create_teacher.create_teacher_success_message,
          "success"
        );
      } else {

        if(result.content === ServiceErrorMessages.TEACHER_ALREADY_REGISTERED) {
          showTopAlert(result.content, "error");
        } else {
          showTopAlert(strings.errors.an_error_occurred, "error");
        }
      }
    }
  };

  useEffect(() => {
      setTeacher({
        ...teacher,
        birthDate: `${birthYear}-01-01T00:00:00`
      })
  }, [birthYear]);

  useEffect(() => {
    //Statik datalar dile göre kontrol yapılıp state'ler dolduruluyor.
    let tmpGenderData = [
      firstGender,
      ...Gender.map((gender) =>
        language === "en"
          ? { id: gender.id, name: gender.eng }
          : { id: gender.id, name: gender.name }
      ),
    ];
    setGenderData((genderData) => tmpGenderData);

    let tmpTeacherStatusData = [
      firstTeacherStatus,
      ...TeacherStatus.map((status) =>
        language === "en"
          ? { id: status.id, name: status.eng }
          : { id: status.id, name: status.name }
      ),
    ];
    setTeacherStatusData((teacherStatusData) => tmpTeacherStatusData);

    let tmpMaritalStatusData = [
      firstMaritalStatus,
      ...MaritalStatus.map((maritalStatus) =>
        language === "en"
          ? { id: maritalStatus.id, name: maritalStatus.eng }
          : { id: maritalStatus.id, name: maritalStatus.name }
      ),
    ];
    setMaritalStatusData((maritalStatusData) => tmpMaritalStatusData);
  }, []);

  //Dropdown değişiklikleri ayrıca kontrol ediliyor.
  useEffect(() => {
    setTeacher({
      ...teacher,
      gender: gender.id,
    });
  }, [gender]);

  //Dropdown değişiklikleri ayrıca kontrol ediliyor.
  useEffect(() => {
    setTeacher({
      ...teacher,
      status: teacherStatus.id,
    });
  }, [teacherStatus]);

  //Dropdown değişiklikleri ayrıca kontrol ediliyor.
  useEffect(() => {
    setTeacher({
      ...teacher,
      maritalStatus: maritalStatus.id,
    });
  }, [maritalStatus]);

  useEffect(() => {
    setTeacher({
      ...teacher,
      birthPlace: selectedState.name,
    });
  }, [selectedState]);

  useEffect(() => {
    isTeacherCreated &&
      setIsPersonalInformationsDisabled(
        (isPersonalInformationsDisabled) => true
      );
  }, [isTeacherCreated]);

  //İlgi alanları array'ini oluşturur
  useEffect(() => {
    const inputArray = areasOfInterestInput
        .split(',') // Inputu ',' karakterine göre ayırır
        .map(item => item.trim()) // Her elemanın başındaki ve sonundaki boşlukları temizler
        .filter(item => item !== ''); // Boş olmayan elemanları filtreler

    setAreasOfInterestData(inputArray);
  }, [areasOfInterestInput]);

  //Personal array'ini oluşturur
  useEffect(() => {
    const inputArray = areasOfPersonalInput
        .split(',') // Inputu ',' karakterine göre ayırır
        .map(item => item.trim()) // Her elemanın başındaki ve sonundaki boşlukları temizler
        .filter(item => item !== ''); // Boş olmayan elemanları filtreler

    setAreasOfPersonalData(areasOfPersonalData => inputArray);
  }, [areasOfPersonalInput]);

  return (
    <>
      <PageTitle title={strings.sidebar.teacher.add_teacher} />
      <p tabIndex={-1} ref={topRef} className="h-0"></p>
      <div className="h-[100px] rounded-md mb-8">
        <div className="flex justify-center">
          <div className="relative mt-8 flex flex-col items-center">
            <img
              className="w-[100px] h-[100px] shadow-profilePhoto rounded-full border-[2px]"
              src={teacher.profilePictureUrl ? process.env.REACT_APP_TEACHER_PROFILE_URL + teacher.profilePictureUrl : DefaultProfile}
              alt=""
            />
            <p className="text-lg font-bold text-center">{teacher.fullName}</p>
          </div>
        </div>
      </div>
      <div className="p-5 max-xs:px-2">
        <h5 className="font-bold text-[15px] pl-3 my-3">
          {strings.teacher.create_teacher.personal_informations}
        </h5>
        <hr />
        <form onSubmit={submitHandle}>
          <div className="flex flex-col lg:flex-row lg:justify-between">
            <div className="mt-5 w-full lg:w-[32.5%]">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="teacherId">
                  {strings.teacher.teacher_title} Id{" "}
                  <span className="text-red">*</span>
                </label>
                <Input
                  id="teacherId"
                  name="teacherId"
                  isDisabled={isPersonalInformationsDisabled}
                  onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                  value={teacher.teacherId === null ? "" : teacher.teacherId}
                  onChange={(e) =>
                    setTeacher({
                      ...teacher,
                      teacherId:
                        e.target.value !== "" ? parseInt(e.target.value) : null,
                    })
                  }
                />
                <span
                  ref={teacherIdErrorRef}
                  id="teacherIdError"
                  className="text-danger field-validation-valid px-[10px]"
                ></span>
              </div>
            </div>
            <div className="mt-5 w-full lg:w-[32.5%]">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="username">
                  {strings.form_info.full_name}{" "}
                  <span className="text-red">*</span>
                </label>
                <Input
                  id="username"
                  name="fullName"
                  isDisabled={isPersonalInformationsDisabled}
                  value={teacher.fullName}
                  onChange={handleChange}
                />
                <span
                  ref={fullNameErrorRef}
                  id="fullNameError"
                  className="text-danger field-validation-valid px-[10px]"
                ></span>
              </div>
            </div>
            <div className="mt-5 w-full lg:w-[32.5%]">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="gender">
                  {strings.form_info.gender} <span className="text-red">*</span>
                </label>
                <Dropdown
                  classnames="md:!max-w-full"
                  isDisabled={isPersonalInformationsDisabled}
                  data={genderData}
                  selectedData={gender}
                  setSelectedData={setGender}
                />
                <span
                  ref={genderErrorRef}
                  id="genderError"
                  className="text-danger field-validation-valid px-[10px]"
                ></span>
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-x-5">
            <div className="mt-5 w-full lg:w-[32.5%]">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="birthPlace">
                  {strings.form_info.birth_place}
                </label>
                <Dropdown
                  data={[firstState, ...StateList]}
                  selectedData={selectedState}
                  setSelectedData={setSelectedState}
                  classnames="md:!max-w-full"
                  isDisabled={isPersonalInformationsDisabled}
                />
              </div>
            </div>
            <div className="mt-5 w-full lg:w-[32.5%]">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="birthDate">
                  {strings.form_info.birth_date}{" "}
                  <span className="text-red">*</span>
                </label>
                <div className="w-full md:max-w-full flex flex-col relative">
                  <Input
                      name="birthDate"
                      isDisabled={isPersonalInformationsDisabled}
                      value={birthYear}
                      onChange={(e) => setBirthYear(e.target.value)}
                      onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                  />
                </div>
                <span
                  ref={birthDateErrorRef}
                  id="birthDateError"
                  className="text-danger field-validation-valid px-[10px]"
                ></span>
              </div>
            </div>
            <div className="mt-5 w-full lg:w-[32.5%]">
              <div className="flex flex-col px-[10px] pb-0">
                <label
                  className="text-[13px] font-bold"
                  htmlFor="marital_status"
                >
                  {strings.form_info.marital_status}
                </label>
                <Dropdown
                  id="marital_status"
                  classnames="md:!max-w-full"
                  isDisabled={isPersonalInformationsDisabled}
                  data={maritalStatusData}
                  selectedData={maritalStatus}
                  setSelectedData={setMaritalStatus}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-x-5">
            <div className="mt-5 w-full lg:w-[32.5%]">
              <div className="flex flex-col px-[10px] pb-0">
                <div className="flex align-middle gap-1">
                  <label htmlFor="profilePictureUrl">
                    <span>{strings.form_info.image_name}</span>
                  </label>
                  <div className="relative group">
                    <span className="material-symbols-outlined flex-shrink-0 text-[19px]">info</span>
                    <span
                        className="tooltip-top right-[-150px] left-[-150px]">{strings.form_info.tooltip_profile}</span>
                  </div>
                </div>
                <Input
                    id="profilePictureUrl"
                    name="profilePictureUrl"
                    isDisabled={isPersonalInformationsDisabled}
                    value={teacher.profilePictureUrl}
                    onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-5 w-full lg:w-[32.5%]">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="university">
                  {strings.form_info.sound_name}
                </label>
                <Input
                    id="soundUrl"
                    name="soundUrl"
                    isDisabled={isPersonalInformationsDisabled}
                    value={teacher.soundUrl}
                    onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-5 w-full lg:w-[32.5%]">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="university">
                  {strings.form_info.university}
                </label>
                <Input
                    id="university"
                    name="university"
                    isDisabled={isPersonalInformationsDisabled}
                    value={teacher.university}
                    onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row lg:gap-x-5">
            <div className="mt-5 w-full lg:w-[32.5%]">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="department">
                  {strings.form_info.department}
                </label>
                <Input
                    id="department"
                    name="department"
                    isDisabled={isPersonalInformationsDisabled}
                    value={teacher.department}
                    onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-5 w-full lg:w-[32.5%]">
              <div className="flex flex-col px-[10px] pb-0">
                <label htmlFor="status">
                  {strings.form_info.status} <span className="text-red">*</span>
                </label>
                <Dropdown
                    id="status"
                    isDisabled={isPersonalInformationsDisabled}
                    data={teacherStatusData}
                    selectedData={teacherStatus}
                    setSelectedData={setTeacherStatus}
                    classnames="md:!max-w-full"
                />
                <span
                    ref={statusErrorRef}
                    id="statusError"
                    className="text-danger field-validation-valid px-[10px]"
                ></span>
              </div>
            </div>
            <div className="mt-5 w-full lg:w-[32.5%]">
            </div>
          </div>
          <div className="flex flex-col px-[10px] pb-0 w-full my-5">
            <label htmlFor="about">{strings.form_info.basic_information}</label>
            <textarea
                id="about"
                name="about"
                disabled={isTeacherCreated}
                className={`${isTeacherCreated ? "resize-none" : ""
                } border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full`}
                type="text"
                rows="5"
                value={teacher.about}
                onChange={handleChange}
            ></textarea>
          </div>

          <div className="flex flex-col px-[10px] pb-0 w-full my-5">
            <label htmlFor="about">{strings.teacher.create_teacher.areas_of_interest}</label>
            <Input
                isDisabled={isTeacherCreated}
                onChange={(e) => setAreasOfInterestInput(e.target.value)}
            />
          </div>

          <div className="flex flex-col px-[10px] pb-0 w-full my-5">
            <label htmlFor="about">{strings.teacher.create_teacher.areas_of_personals}</label>
            <Input
                isDisabled={isTeacherCreated}
                onChange={(e) => setAreasOfPersonalInput(e.target.value)}
            />
          </div>

          {!isTeacherCreated && (
            <div className="mt-7 mb-5 flex gap-3 justify-center">
              <button
                type="submit"
                className="button search-button text-white bg-blue border-blue px-7"
              >
                <p>{strings.button.save}</p>
              </button>

              <button
                  type="button"
                  className="button search-button text-white bg-red border-red px-7 focus:border-red"
                  onClick={() => navigate(url("dashboard"))}
              >
                <p>{strings.button.cancel}</p>
              </button>
            </div>
          )}
        </form>

        {/* Çalışma Saati ekleme alanı */}
        <CreateShift ref={topRef} teacherId={teacher.teacherId} isTeacherCreated={isTeacherCreated} />

        <p tabIndex={-1} ref={endRef} className="h-0"></p>
      </div>
    </>
  );
};

export default CreateTeacher;
