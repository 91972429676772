import {useLocalization} from "../../../../hooks/useLocalization";
import {GetTeacherSandyScheduleTermDetail, GetTeacherScheduleTerm} from "../../../../services/Teacher/ScheduleService";
import {addTimeZone, convertToAMPMFormat} from "../../../../components/Utility";
import React, {useEffect, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import {Loading, Modal, NoRecordsFound} from "../../../../components";
import {getButtonPermission, SquareButtonWithoutLink} from "../../../../components/Buttons";
import MemberTermDetailModal from "./MemberTermDetailModal";
import {useSelector} from "react-redux";

const TeacherSandyList = () => {

    const strings = useLocalization();
    const timeZone = useSelector(state => state.timeZoneStore.timeZone);
    const {employeeTypeId, checkPermission} = useAuth();

    const timeZoneRoles = getButtonPermission({group: "timeZone", name: "timeZoneTR"});
    const timeZonePermission = checkPermission(timeZoneRoles, employeeTypeId);

    const teacherSandyId = +process.env.REACT_APP_TEACHER_SANDY_ID;

    const [termData, setTermData] = useState([]);
    const [selectedTermData, setSelectedTermData] = useState([]);
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const closeModal = () => {
        setModal(modal => false);
    }

    const getTeacherScheduleTerm = async () => {

        const result = await GetTeacherSandyScheduleTermDetail();

        if(result.status === 200){
            let resultContent = JSON.parse(result.content);
            let tmpTermData = [];
            resultContent?.map((term) => {
                let tmpBeginTime = addTimeZone(term.beginTime, timeZone);
                tmpTermData.push({
                    beginTime: tmpBeginTime,
                    days: term.days,
                    lessonDuration: term.lessonDuration,
                    fullName: term.fullName,
                    memberId: term.memberId,
                    termId: term.termId,
                    studentNo: term.studentNo,
                    priority: term.priority
                });
            });

            setLoading(loading => false);
            setTermData(termData => tmpTermData);
        }
    }

    useEffect(() => {
        getTeacherScheduleTerm();
    }, [modal, timeZone]);

    return (
        <>
            <div className="mx-auto px-4 my-4">
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                    <div className="inline-block min-w-full rounded-lg overflow-hidden">
                        <table className="min-w-full leading-normal text-header">
                            <thead>
                                <tr>
                                    <th className="th sm:w-[20%]"><p className="min-w-max">{strings.table.student_name}</p></th>
                                    <th className="th sm:w-[20%]"><p className="min-w-max">{strings.table.lesson_days}</p></th>
                                    <th className="th sm:w-[20%]"><p className="min-w-max">{strings.table.begin_time}</p></th>
                                    <th className="th sm:w-[20%]"><p className="min-w-max">{strings.table.lesson_duration}</p></th>
                                    <th className="th sm:w-[20%]"></th>
                                    <th className="th sm:w-[20%]"><p className="min-w-max">{strings.table.operation}</p></th>
                                </tr>
                            </thead>
                            <tbody>
                            {loading && (
                                <tr>
                                    <td colSpan="6">
                                        <Loading />
                                    </td>
                                </tr>
                            )}
                            {!loading && (
                                <>
                                    { termData.length > 0 ?
                                        (
                                            termData.map((term,index) => (
                                                <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                                                    <td className="td align-middle">
                                                        <div className="flex flex-col">
                                                            <p className="min-w-max">{term.studentNo}</p>
                                                            <p className="min-w-max">{strings.form_info.student} {term?.fullName.split(" ")[0] ?? term?.fullName}</p>
                                                        </div>
                                                    </td>
                                                    <td className="td align-middle">
                                                        <div className="flex items-center">
                                                            <p className="min-w-max flex">
                                                                {term.days.map((day, index) => (
                                                                    <span key={index} className="ml-1">#{day}</span>
                                                                ))}
                                                            </p>
                                                        </div>
                                                    </td>
                                                    <td className="td align-middle">
                                                        <div className="flex items-center">
                                                            <p className="min-w-max">{convertToAMPMFormat(term.beginTime, timeZonePermission)}</p>
                                                        </div>
                                                    </td>
                                                    <td className="td align-middle">
                                                        <div className="flex items-center">
                                                            <p className="min-w-max">{term.lessonDuration} {strings.table.minutes}</p>
                                                        </div>
                                                    </td>
                                                    <td className="td align-middle">
                                                        {term.priority && (
                                                            <div className="flex items-center justify-center w-14 h-8 rounded-[10px] bg-red">
                                                                <p className="text-white font-bold">60%+</p>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="td align-middle">
                                                        <SquareButtonWithoutLink
                                                            classnames="bg-blue"
                                                            tooltip={strings.teacher.teacher_list.detail}
                                                            iconName="visibility"
                                                            action={() => {
                                                                setSelectedTermData(selectedTermData => term)
                                                                setModal(true);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                        :
                                        (
                                            <tr className="bg-white hover:bg-[#f0f0f0]">
                                                <td colSpan="6">
                                                    <NoRecordsFound />
                                                </td>
                                            </tr>
                                        )
                                    }
                                </>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[90vh] max-w-[1440px]" }} >
                <MemberTermDetailModal data={selectedTermData} teacherId={teacherSandyId} closeModal={closeModal} />
            </Modal>
        </>
    )

}

export default TeacherSandyList;