import { fetchApi } from '../index';


//Eğitmen listesini getiren servis
export const TeacherFilter = async (model) => {

    const url = '/teacher/v1/teacher/list/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Eğitmen detaylarını getiren servis
export const GetTeacherDetail = async (teacherId) => {

    const url = '/teacher/v1/teacher/details/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
}

//Eğitmen bilgilerini düzenleme için kullanılan servis
export const EditTeacherInformations = async (model) => {

    const url = '/teacher/v1/teacher/edit_teacher/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Eğitmen eklemek için kullanılan servis.
export const AddTeacher = async (model) => {

    const url = '/teacher/v1/teacher/add_teacher/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Eğitmenin ilgi alanlarını getiren servis.
export const GetTeacherInterests = async (teacherId) => {

    const url = '/teacher/v1/teacher/interests/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
}

//Eğitmenin ilgi alanlarını güncellemeye yarayan servis.
export const UpdateInterests = async (model) => {

    const url = '/teacher/v1/teacher/update_interests/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Eğitmenin kişilik özelliklerini bilgilerini getiren servis.
export const GetTeacherPersonals = async (teacherId) => {

    const url = '/teacher/v1/teacher/personals/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
}

//Eğitmenin kişilik özelliklerini güncellemeye yarayan servis.
export const UpdatePersonals = async (model) => {

    const url = '/teacher/v1/teacher/update_personals/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetTeacherNameByTeacherId = async (teacherId) => {
    const url = '/teacher/v1/teacher/name/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
}

export const GetTNTTimeZone = async () => {
    const url = '/international/get_tnt_timezone/';

    const payload = {};

    return await fetchApi(url, payload);
}