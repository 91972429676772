import { fetchApi } from '../index';

//Öğretmenin üzerindeki öğrencileri listeyen servis.
export const GetDetailsByTeacherId = async (teacherId) => {

    const url = '/member/v1/term/get_details_by_teacher_id/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
};

export const GetTeacherFreeTimesByRange = async (model) => {
    const url = '/member/v1/support/teacher_free_time_by_range/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Öğrencilere ve ders saatine göre boş eğitmenleri listeyen servis.
//Tek bir öğrenciye göre listeleme yapar.
export const GetEmptyTeachersByTerm = async (model) => {

    const url = '/member/v1/term/get_empty_teachers_by_term/';
    
    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
};

//Öğrencilere ve ders saatine göre boş eğitmenleri listeyen servis.
//Tüm öğrenciler seçilince listeleme yapar.
export const GetEmptyTeachersByTerms = async (model) => {

    const url = '/member/v1/term/get_empty_teachers_by_terms/';

    return await fetchApi(url, model);
};

//Tek bir öğrenciyi taşıyan servis.
export const UpdateTermTeacher = async (model) => { 

    const url = '/member/v1/term/update_term_teacher/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
};

//Eğitmen saat değişikliği yapılan servise istek atıyoruz
export const TeacherLessonTimeChange = async (model) => {
    const url = '/member/v2/support/teacher_lesson_time_change/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Tüm öğrencileri taşıyan servis.
export const UpdateTermsTeacher = async (model) => { 

    const url = '/member/v1/term/update_terms_teacher/';

    return await fetchApi(url, model);
};