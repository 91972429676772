import React, { forwardRef, useEffect, useState } from "react";
import { AddShiftRow, ShiftRowEditable } from "..";
import { showTopAlert } from "../../../../components";
import { useLocalization } from "../../../../hooks/useLocalization";
import { TeacherShiftList } from "../../../../services/Teacher/ShiftService";
import classNames from "classnames";

const EditShifts = forwardRef((props, ref) => {

  const strings = useLocalization();
  const { teacherId, teacherStatus } = props;
  const [shifts, setShifts] = useState([]);
  const [isNewShift, setIsNewShift] = useState(false);

  //Eğitmenlerin çalışma saatlerini getiren servis
  const getTeacherShiftList = async (id) => {
    const result = await TeacherShiftList(id);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setShifts(shifts => resultContent);
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error")
    }
  }

  const sendRequestToShiftListEndpoint = () => {
    getTeacherShiftList(teacherId);
  }

  useEffect(() => {
    sendRequestToShiftListEndpoint();
  }, []);

  useEffect(() => { setIsNewShift(isNewShift => false) }, [shifts]);
  
  return (
    <>
      <h5 className="font-bold flex items-center gap-3 text-[15px] pl-3 mt-3 max-xl:mb-2">
        <p>{strings.teacher.create_teacher.working_hours}</p>
        <button
          className={`${isNewShift ? 'opacity-70 bg-[#fafafa] cursor-not-allowed' : ''} flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-green text-green w-8 h-8 rounded-[3px] relative`}
          onClick={() => setIsNewShift(true)}
          disabled={(isNewShift || teacherStatus === 0)}
        >
          <span 
            className={classNames("material-symbols-outlined text-[18px]", {
                "opacity-70 !cursor-not-allowed" : isNewShift ||teacherStatus === 0
            })}
          >
            add
          </span>

          {(!isNewShift && teacherStatus === 1) && (
            <div className="tooltip-top">
              <p>{strings.teacher.update_teacher.add_new_shift}</p>
            </div>
          )}
        </button>
      </h5>
      <hr />
      {(shifts.length > 0 || (isNewShift && teacherStatus)) ? (
        <>
          {shifts.map((shift, index) => (
            <ShiftRowEditable 
              key={index} 
              ref={ref} 
              shift={shift}
              teacherId={teacherId}
              teacherStatus={teacherStatus}
              sendRequestToShiftListEndpoint={sendRequestToShiftListEndpoint} 
            />
          ))}
          
          {isNewShift && (
            <AddShiftRow 
              ref={ref} 
              teacherId={teacherId} 
              classnames="mr-9"
              sendRequestToEndpoint={sendRequestToShiftListEndpoint} 
            />
          )}
        </>
       ) : (
        <div className="bg-white hover:bg-[#f0f0f0]">
          <p className="py-4 min-w-full text-center">{strings.teacher.update_teacher.shift_not_found}</p>
        </div>
       )
      }
    </>
  );
});

export default EditShifts;
