import React, {useEffect, useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {GeneralStatus} from "../../../../components/Constants";
import usePrimeData from "../../../../hooks/usePrimeData";
import {Dropdown, showTopAlert} from "../../../../components";
import {useSelector} from "react-redux";
import {DeleteShift} from "../../../../services/Teacher/ShiftService";
import {addTimeZone, convertToAMPMFormat} from "../../../../components/Utility";
import useAuth from "../../../../hooks/useAuth";
import {getButtonPermission} from "../../../../components/Buttons";

const ShiftRowReadonly = (props) => {

  const strings = useLocalization();
  const { shiftData, sendRequestToShiftListEndpoint } = props;

  const timeZone = useSelector(state => state.timeZoneStore.timeZone);
  const primeData = usePrimeData();
  const language = useSelector(state => state.localizationStore.language);
  const { employeeTypeId, checkPermission } = useAuth();

  const timeZoneRoles = getButtonPermission({ group: "timeZone", name: "timeZoneTR" });
  const timeZonePermission = checkPermission(timeZoneRoles, employeeTypeId);

  const firstStatus = { id: -1, name: strings.form_info.choose_status };
  const [statusData, setStatusData] = useState([]);

  const deleteShift = async (shiftId) => {

    const result = await DeleteShift(shiftId);

    if(result.status === 200){
      showTopAlert(strings.teacher.create_teacher.delete_shift_success_message, "success");
      sendRequestToShiftListEndpoint();
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error")
    }
  };

  useEffect(() => {
    let tmpStatusData = [firstStatus, ...GeneralStatus.map((status) => language === 'en' ? { id: status.id, name: status.eng } : { id: status.id, name: status.name })];
    setStatusData(statusData => tmpStatusData);
  }, []);

  return (
    <>
      {shiftData.length > 0 &&
        shiftData.map((shift, index) => (
          <div
            key={index}
            className="flex flex-col xl:flex-row xl:items-end items-center"
          >
            <div className="flex flex-col px-[10px] pb-0 mt-5 w-full">
              <label className="font-bold mb-2">
                {strings.teacher.create_teacher.select_start_end_hour}
              </label>
              <div className="flex flex-col max-lg:w-full lg:flex-row lg:gap-3 items-center ">
                <Dropdown
                  data={primeData.rangeList}
                  selectedData={{ id: shift.beginTime, name: convertToAMPMFormat(addTimeZone(shift.beginTime, timeZone), timeZonePermission) }}
                  classnames="md:max-w-full"
                  isDisabled={true}
                />
                <p className="text-[20px]">:</p>
                <Dropdown
                  data={primeData.rangeList}
                  selectedData={{ id: shift.endTime, name: convertToAMPMFormat(addTimeZone(shift.endTime, timeZone), timeZonePermission) }}
                  classnames="md:max-w-full"
                  isDisabled={true}
                />
              </div>
            </div>
            <div className="flex flex-col px-[10px] pb-0 mt-5 w-full">
              <label className="font-bold mb-2">
                {strings.form_info.status}
              </label>
              <div className="flex gap-5 xs:gap-10">
                <Dropdown
                  data={statusData}
                  selectedData={shift.status}
                  classnames="md:max-w-full"
                  isDisabled={true}
                />
              </div>
            </div>
            
            <button
              className="flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-red text-red w-8 h-8 rounded-[3px] relative xl:mt-[52px]"
              onClick={() => deleteShift(shift.shiftId)}
            >
              <span className="material-symbols-outlined text-[18px]">
                delete
              </span>
              <div className="tooltip-top">
                <p>{strings.button.delete}</p>
              </div>
            </button>
          </div>
        ))}
    </>
  );
};

export default ShiftRowReadonly;
