import { fetchApi } from '../index';

//Eğitmenin çalışma programını getiren servis
export const GetTeacherProgram = async (teacherId) => {

    const url = '/teacher/v1/schedule/list/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
};

//Eğitmene ait dersleri listeleyen servis
export const GetTeacherScheduleTerm = async (teacherId) => {

    const url = '/member/v1/term/teacher_schedule_term/';

    const payload = {
      teacherId: teacherId  
    };

    return await fetchApi(url, payload);
}

//Eğitmene ait missed call'ları listeleyen servis
export const GetTeacherMissedCalls = async (teacherId) => {

    const url = '/member/v1/term/get_missed_call_by_teacherId/';

    const payload = {
      teacherId: teacherId  
    };

    return await fetchApi(url, payload);
}

//Eğitmene ait derslerin detaylarını listeyeleyen servis
export const GetTeacherScheduleTermDetail = async (termId) => { 

    const url = '/member/v1/term/teacher_schedule_term_detail/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);
}

export const GetTeacherSandyScheduleTermDetail = async () => {
    const url = '/member/v1/term/teacher_schedule_term_for_sandy/'

    const payload = {};

    return await fetchApi(url, payload);
}

//İlgili term'e ait ders geçmişini getiren servis.
export const GetLessonHistory = async (termId) => {
    const url = '/member/v1/term_schedule/term_schedule_status/';

    const payload = {
        termId: termId
    };

    return await fetchApi(url, payload);    
}

//İlgili teacher'a bloke veya geçiçi bloke ekleyen servis.
export const AddBreak = async (model) => {

    const url = '/teacher/v1/schedule/add_break/';

    const payload = { 
        ...model
    };

    return await fetchApi(url, payload);
}

//İlgili teacher'a eklenen bloke veya geçiçi blokeyi kaldıran servis.
export const DeleteBreak = async (model) => {

    const url = '/teacher/v1/schedule/delete_schedule/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//İlgili teacher'a ait bloke veya geçiçi bloke listesini getiren servis.
export const BreakList = async (teacherId) => {

    const url = '/teacher/v1/schedule/break_list/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
}

//İlgili teacher'a ait bloke veya geçiçi bloke listesini getiren servis.
export const GetAllTeacherBlockList = async () => {

    const url = '/teacher/v1/schedule/get_all_teacher_block_list/';

    const payload = {};

    return await fetchApi(url, payload);
}
