import { TeacherTableRow } from "../index";
import { useLocalization } from "../../../../hooks/useLocalization";
import { NoRecordsFound } from "../../../../components";
import {useEffect, useState} from "react";

const TeacherTable = ({ data }) => {

  const strings = useLocalization();

  const [sortedData, setSortedData] = useState(data);
  const [sortMethod, setSortMethod] = useState("default");

  //Verileri sıralamak için kullanılan fonksiyon
  const sortData = (data, sortMethod) => {
    return data.slice().sort((a, b) => {
      // 1320 id'li (Teacher Sandy) eğitmen en üste sabitlenmek için kontrol yapılıyor.
      if (a.id === 1320) return -1;
      if (b.id === 1320) return 1;

      const ratingA = a.averageRating === null ? -1 : parseFloat(a.averageRating.replace(',', '.'));
      const ratingB = b.averageRating === null ? -1 : parseFloat(b.averageRating.replace(',', '.'));

      // true ise 'asc' sıralama yapılıyor
      if (sortMethod) {
        return ratingA - ratingB;
      }
      // false ise 'desc' sıralama yapılıyor
      else if (!sortMethod) {
        return ratingB - ratingA;
      }
    });
  };

  //Sıralama metodunun belirlenip, istenen sıralama metoda gönderiliyor
  const handleSort = (sortMethod) => {
    const newData = sortData(data, sortMethod);
    setSortedData(newData);
  };

  //Sıralama sıfırlanarak ilk haline getiriliyor.
  const handleReset = () => {
    setSortedData(data);
    setSortMethod(sortMethod => "default");
  };

  useEffect(() => {
    if(sortMethod !== "default") {
      handleSort(sortMethod);
    }
  }, [sortMethod]);

  return (
    <>
      <div className="mx-auto px-4 my-4">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
          <div className="inline-block min-w-full rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal text-header">
              <thead>
              <tr>
                <th className="th sm:w-[75%]">{strings.table.teacher_name}</th>
                <th className="th sm:w-[10%]">
                  <div className="flex gap-0.5">
                    <div className="relative group">
                      <button
                          type="button"
                          className="hover:cursor-pointer hover:text-underline hover:text-blue"
                          onClick={handleReset}
                      >
                        {strings.table.rating}
                      </button>
                      <div className="tooltip -bottom-[26px]">
                        {strings.table.reset_order}
                      </div>
                    </div>

                    <div className="relative group">
                      <button
                          type="button"
                          className="material-symbols-outlined hover:text-blue cursor-pointer"
                          onClick={() => setSortMethod(sortMethod => !sortMethod)}
                      >
                        swap_vert
                      </button>
                      <div className="tooltip -bottom-[27px]">
                        {(sortMethod || sortMethod === "default") && (
                            <p>{strings.table.order_descending}</p>
                        )}
                        {!sortMethod && (
                            <p>{strings.table.order_ascending}</p>
                        )}
                      </div>
                    </div>
                  </div>
                </th>
                <th className="th sm:w-[15%]">{strings.table.operation}</th>
              </tr>
              </thead>
              <tbody>
              { sortedData.length > 0 ?
                  (
                    sortedData.map((teacher,index) => (
                      <TeacherTableRow 
                        key={index} 
                        teacher={teacher}
                      />
                    ))
                  )
                  :
                  (
                    <tr className="bg-white hover:bg-[#f0f0f0]">
                      <td colSpan="2">
                        <NoRecordsFound />
                      </td> 
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <div id="move-students-modal"></div>
    </>
  );
};

export default TeacherTable;
