import {DetailSearchLayout, Dropdown} from "../../../../components";
import {useLocalization} from "../../../../hooks/useLocalization";
import {useEffect, useRef, useState} from "react";

const StudentListDetailSearch = ({ changeDetail, loading }) => {

    const strings = useLocalization();

    const firstVisit = useRef(true);

    const LESSON_DAYS_STATIC_DATA = [
        {id: 2, name: "#2 #4"},
        {id: 3, name: "#1 #3 #5"},
        {id: 5, name: "#1 #2 #3 #4 #5"},
    ]

    const generateLessonDurationData = () => {
        let arr = [];

        for(let i = 10; i <= 60; i+=10){
            arr.push({ id: i, name: `${i} ${strings.table.minutes.toLowerCase()}` })
        }

        return arr;
    }

    const firstLessonDays = { id: -1, name: strings.form_info.choose_lesson_days }
    const lessonDaysList = [firstLessonDays, ...LESSON_DAYS_STATIC_DATA];
    const [selectedLessonDays, setSelectedLessonDays] = useState(firstLessonDays);

    const firstLessonDuration = { id: -1, name: strings.form_info.choose_lesson_duration }
    const lessonDurationList = [firstLessonDuration, ...generateLessonDurationData()];
    const [selectedLessonDuration, setSelectedLessonDuration] = useState(firstLessonDuration);

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ lessonDays: selectedLessonDays?.id });
            firstVisit.current = false;
        }
    }, [selectedLessonDays.id])

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ lessonDuration: selectedLessonDuration?.id });
            firstVisit.current = false;
        }
    }, [selectedLessonDuration.id])

    useEffect(() => {
        //Sayfa yüklendikten sonra false yaparak her değişiklikte
        //servisin ziyaret edilmesini sağlıyoruz
        firstVisit.current = false;
    }, [])

    return (
        <DetailSearchLayout
            isDisabled={loading}
            open={true}
            children={
                <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap relative">
                    <Dropdown
                        isDisabled={loading}
                        data={lessonDaysList}
                        selectedData={selectedLessonDays}
                        setSelectedData={setSelectedLessonDays}
                    />
                    <Dropdown
                        isDisabled={loading}
                        data={lessonDurationList}
                        selectedData={selectedLessonDuration}
                        setSelectedData={setSelectedLessonDuration}
                    />
                </div>
            }
        />
    )
}

export default StudentListDetailSearch;