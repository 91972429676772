import React from 'react'
import { useLocalization } from '../hooks/useLocalization'

const NotAuthorized = () => {
    const strings = useLocalization();
    
    return (
        <div className="flex justify-center text-[14px]">{strings.errors.not_authorized_for_this_page}</div>
    )
}

export default NotAuthorized