import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {WeekDays} from '../../../../components/Constants';
import {ActiveLesson, BlockedLesson, FreeLesson, TemproraryBlockedLesson} from '../../components';
import {GetTeacherScheduleTerm} from '../../../../services/Teacher/ScheduleService';
import {addTimeZone, calculateTimeDifference, convertToAMPMFormat} from "../../../../components/Utility";
import useAuth from "../../../../hooks/useAuth";
import {getButtonPermission} from "../../../../components/Buttons";

const ProgramTable = (props) => {

  const { scheduleData, teacherId, sendRequestToEndpoint } = props;

  const timeZone = useSelector(state => state.timeZoneStore.timeZone);
  const language = useSelector(state => state.localizationStore.language);

  const { employeeTypeId, checkPermission } = useAuth();

  const timeZoneRoles = getButtonPermission({ group: "timeZone", name: "timeZoneTR" });
  const timeZonePermission = checkPermission(timeZoneRoles, employeeTypeId);

  const [times, setTimes] = useState([]);
  const [startHour, setStartHour] = useState(null);
  const [endHour, setEndHour] = useState(null);
  const [difference, setDifference] = useState(null);

  const [termData, setTermData] = useState([]);

  //Eğitmene ait aktif derslerin bilgisini getirir.
  const getTeacherScheduleTerm = async (teacherId) => {

    const result = await GetTeacherScheduleTerm(teacherId);

    if(result.status === 200){
      let resultContent = JSON.parse(result.content);
      let tmpTermData = [];
      resultContent?.map((term) => {
        let tmpBeginTime = addTimeZone(term.beginTime, timeZone);
        tmpTermData.push({
          beginTime: tmpBeginTime,
          days: term.days,
          lessonDuration: term.lessonDuration,
          fullName: term.fullName,
          memberId: term.memberId,
          termId: term.termId,
          studentNo: term.studentNo
        });
      });

      setTermData(termData => tmpTermData);
    }
  }

  //Eğitmenin çalışma saati ve günü term bilgisi ile uyuşuyorsa term bilgisini içeren objeyi döndürür.
  const filterTermData = (beginTime, day) => {
    return termData.find(term => term.beginTime === beginTime && term.days.includes(day));
  }

  useEffect(() => {
      if(timeZone !== -1 && teacherId)
        getTeacherScheduleTerm(teacherId);
  }, [timeZone, teacherId]);

  useEffect(() => {
    let scheduleTime = [];

    scheduleData.map((schedule) => {
        scheduleTime.push(parseInt(schedule.time.substring(0,2)))
    });

    const set = new Set(scheduleTime);
    const formattedScheduleTime = Array.from(set);

    setStartHour(startHour => formattedScheduleTime[0]);
    setEndHour(endHour => formattedScheduleTime[formattedScheduleTime.length - 1]);
    setDifference(difference => calculateTimeDifference(startHour, endHour));

  }, [scheduleData, times])

  useEffect(() => {
    let hours = [];
    let minutes = Array.from({ length: 6 }, (_, i) => i * 10);

    // Başlangıç saati 24'ten küçükse, doğrudan saatleri ekleyebiliriz
    if (startHour < 24) {
      hours = Array.from({ length: difference + 1 }, (_, i) => (i + startHour) % 24);
    } else {
      hours = Array.from({ length: difference + 1 }, (_, i) => (i + startHour - 24) % 24);
    }

    let tmpTimes = [];

    for (let hour of hours) {
      for (let minute of minutes) {
        let hourString = hour.toString().padStart(2, "0");
        let minuteString = minute.toString().padStart(2, "0");
        tmpTimes.push(hourString + ":" + minuteString);
      }
    }

    let tmpScheduleArr = [];

    tmpScheduleArr = scheduleData.filter(schedule => schedule.day === 1)
    tmpTimes = tmpScheduleArr.map(schedule => schedule.time);

    setTimes(tmpTimes);
  }, [difference, startHour, endHour, scheduleData]);


  return (
    <>
      <div className="mx-auto px-4 my-4">
              <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4">
                  <div className="inline-block min-w-full rounded-lg overflow-x-hidden relative">
                      <table className="min-w-full leading-normal text-header">
                          <thead className="table-fixed sticky top-0 left-0 right-0 bg-white z-10">
                            <tr>
                                <th className="th w-[5%] min-w-[40px]"></th>
                                {WeekDays.length > 0 &&
                                    WeekDays.map((day, index) => (
                                        <th key={index} className='th w-[12.5%] border-l'>{language === 'en' ? day.eng : language === 'tr' ? day.name : ''}</th>
                                    ))
                                }
                            </tr>
                          </thead>
                        <tbody>
                            {times.length > 0 && times.map((time, index) => (
                                <tr key={index}>
                                    <td className="td font-bold py-5 !px-2">{convertToAMPMFormat(time, timeZonePermission)}</td>
                                    {[1, 2, 3, 4, 5].map((day) => {
                                        const matchingSchedule = scheduleData.find(
                                            (schedule) => schedule.time === time && schedule.day === day
                                        );

                                        if (!matchingSchedule) {
                                            return (
                                                <td
                                                    key={day}
                                                    className="teacher-program-td"
                                                ></td>
                                            );
                                        }

                                        switch (matchingSchedule.status) {
                                            case 0:
                                                return (
                                                    <td
                                                        key={day}
                                                        className="teacher-program-td"
                                                    >
                                                        <FreeLesson 
                                                            teacherId={teacherId} 
                                                            day={matchingSchedule.day} 
                                                            time={matchingSchedule.time} 
                                                            sendRequestToEndpoint={sendRequestToEndpoint} 
                                                        />
                                                    </td>
                                                );
                                            case 1:
                                                  return (
                                                      <td
                                                          key={day}
                                                          className="teacher-program-td"
                                                          id={`${matchingSchedule.time}_${matchingSchedule.day}_active`}
                                                      >
                                                        <ActiveLesson
                                                            day={matchingSchedule.day}
                                                            time={matchingSchedule.time}
                                                            data={filterTermData(time, matchingSchedule.day)}
                                                            teacherId={teacherId}
                                                            sendRequestToEndpoint={sendRequestToEndpoint}
                                                        />
                                                      </td>
                                                  );
                                            case 2:
                                                  return (
                                                      <td
                                                          key={day}
                                                          className="teacher-program-td"
                                                          id={`${matchingSchedule.time}_${matchingSchedule.day}_active`}
                                                      >
                                                        <ActiveLesson
                                                            day={matchingSchedule.day}
                                                            time={matchingSchedule.time}
                                                            data={filterTermData(time, matchingSchedule.day)}
                                                            teacherId={teacherId}
                                                            sendRequestToEndpoint={sendRequestToEndpoint}
                                                        />
                                                      </td>
                                                  );

                                            // case 3:
                                            //     return (
                                            //         <td
                                            //             key={day}
                                            //             className="teacher-program-td"
                                            //             id={`${matchingSchedule.time}_${matchingSchedule.day}_missed`}
                                            //         >
                                            //             <MissedLesson
                                            //                 day={matchingSchedule.day}
                                            //                 time={matchingSchedule.time}
                                            //                 data={filterMissedCallData(time, matchingSchedule.day)}
                                            //                 termData={filterTermData(time, matchingSchedule.day)}
                                            //             />
                                            //         </td>
                                            //     );
                                            case 4:
                                                return (
                                                    <td
                                                        key={day}
                                                        className="teacher-program-td"
                                                        id={`${matchingSchedule.time}_${matchingSchedule.day}_blocked`}
                                                    >
                                                        <BlockedLesson 
                                                            teacherId={teacherId} 
                                                            day={matchingSchedule.day} 
                                                            time={matchingSchedule.time} 
                                                            sendRequestToEndpoint={sendRequestToEndpoint}
                                                        />
                                                    </td>
                                                );
                                            case 6:
                                                return (
                                                    <td
                                                        key={day}
                                                        className="teacher-program-td"
                                                        id={`${matchingSchedule.time}_${matchingSchedule.day}_temprorary_blocked`}
                                                    >
                                                        <TemproraryBlockedLesson 
                                                            teacherId={teacherId}  
                                                            day={matchingSchedule.day} 
                                                            time={matchingSchedule.time}
                                                            sendRequestToEndpoint={sendRequestToEndpoint}
                                                        />
                                                    </td>
                                                );
                                            default:
                                                return null;
                                        }
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
  )
};

export default ProgramTable;