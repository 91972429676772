import React, { useEffect } from 'react'
import { useLocalization } from '../hooks/useLocalization';
import { Dropdown } from './Dropdown';

const TimeRangePicker = (props) => {

  const strings = useLocalization();
  const { startHour, setStartHour, endHour, setEndHour, rangeList, dropdownClassnames, heightClassnames, isDisabled = false } = props;
  const dropdownClassname = typeof dropdownClassnames === "undefined" ? 'lg:w-[246px] md:max-w-full' : dropdownClassnames;
  const heightClassname = typeof heightClassnames === "undefined" ? 'lg:w-[246px] md:max-w-full' : heightClassnames;

  const firstBeginTime = { id: -1, name: strings.detail_search_titles.begin_time };
  const tmpRangeList = rangeList.slice(0, rangeList.length - 1);
  const timeRangeList = [firstBeginTime, ...tmpRangeList];

  const firstEndTime = { id: -1, name: strings.detail_search_titles.end_time };
  const filteredHour = rangeList.filter(hour => hour.tmpId > startHour.tmpId);
  const filteredTimeRangeList = [firstEndTime, ...filteredHour];

  useEffect(() => {
    if (startHour.id !== -1) {
      setEndHour(endHour => filteredHour[0]);
    } else {
      setEndHour(firstEndTime);
    }
  }, [startHour])

  return (
      <>
        <div className="flex flex-row gap-3 items-center">
          <Dropdown
              data={timeRangeList}
              selectedData={startHour}
              setSelectedData={setStartHour}
              classnames={dropdownClassname}
              heightClassnames={heightClassname}
              isDisabled={isDisabled}
          />
          <p className="text-[20px]">:</p>
          <Dropdown
              data={filteredTimeRangeList.length === 1 ? rangeList : filteredTimeRangeList}
              selectedData={endHour}
              setSelectedData={setEndHour}
              classnames={dropdownClassname}
              heightClassnames={heightClassname}
              isDisabled={isDisabled}
          />
        </div>
      </>
  )
}

export default TimeRangePicker;