import React, { useEffect, useRef, useState } from 'react'
import { useLocalization } from '../../hooks/useLocalization';
import { EmployeeVerifyCode } from '../../services/Auth/AuthService';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { url } from '../../routes/utility';
import { showTopAlert } from '../../components';

const VerifyCode = () => {

    const strings = useLocalization();
    const navigate = useNavigate();
    const location = useLocation();
    const isFromTruePage = location.state?.from?.includes("/auth/forgotpassword") || false;

    const employeeId = location.state?.employeeId.replace(/["']/g, "");

    const [code, setCode] = useState('');
    const codeErrorRef = useRef();

    const codeHandle = e => {
        setCode(code => e.target.value);
    };

    const checkCode = code => {
        return (code.length >= 4);
    }

    const submitHandle = async e => {
        e.preventDefault();

        //tüm hataları sıfırla
        codeErrorRef.current.innerText = '';

        const codeStatus = checkCode(code);

        if (codeStatus) {

            const result = await EmployeeVerifyCode(employeeId, code);

            //Doğrulama kodu doğru ise
            if (result.status === 200) {
                navigate(url("auth.updatepassword"), { state: { employeeId: result.content, "from": location.pathname } });
            } else {
                //Doüğrulama kodu yanlış ise
                showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
            }

        } else {

            // hata varsa ekrana yaz
            codeErrorRef.current.innerText = !codeStatus ? strings.errors.code_error : '';
        }
    }

    //Doğru sayfadan gelinmediyse sayfadan ayrıl
    useEffect(() => {
        if(!isFromTruePage) {
            navigate(url("home"));
        }
    }, [])

    return (
        <>
            <form className="w-full flex justify-center" onSubmit={submitHandle}>
                <div
                    className="w-[400px] animate-fadeIn p-[25px] md:py-[60px] rounded-[5px] block bg-white border border-[#ebecec] shadow-form mx-5 mt-5 md:mt-0">
                    <h3 className="font-bold text-center text-[19px] md:mb-[25px]">{strings.auth.forgot_password.title}</h3>
                    <div className="login-form">
                        <div className="flex flex-col px-[10px] pb-0">
                            <label className="text-[85%]" htmlFor="code" >{strings.auth.verify_code.your_code}</label>
                            <input id="code" type="text" className="form-input" name="code" onChange={codeHandle} />
                            <span ref={codeErrorRef} className="text-danger field-validation-valid"></span>
                        </div>
                        <div className=" px-[10px] pt-[10px] md:pt-[25px]">
                            <button type="submit" className="btn btn-primary w-full">{strings.auth.verify_code.button}</button>
                        </div>
                        <div className="flex justify-center px-3 pb-2 pt-4">
                            <Link to={url("auth.login")} type="button" className="text-blue hover:underline">{strings.auth.verify_code.login}</Link>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default VerifyCode