import {useEffect, useState} from 'react'
import {GetTeachers, GetWorkingTimes} from '../services/PrimeData/PrimeDataService';
import {addTimeZone, checkPermission, convertToAMPMFormat, decodeToken} from "../components/Utility";
import {useSelector} from "react-redux";
import {getCookie} from "ko-basic-cookie";
import {cookieName} from "../components/Constants";
import {getButtonPermission} from "../components/Buttons";

const usePrimeData = () => {

    const timeZone = useSelector(state => state.timeZoneStore.timeZone);
    const [teacherList, setTeacherList] = useState([]);
    const [workingTimeList, setWorkingTimeList] = useState([]);

    const token = getCookie(cookieName);
    const employee = token && decodeToken(token);

    const timeZoneRoles = getButtonPermission({ group: "timeZone", name: "timeZoneTR" });
    const timeZonePermission = checkPermission(timeZoneRoles, +employee.EmployeeTypeId);

    //Eğitmen listesi işlemleri
    const getTeachers = async () => {
        const result = await GetTeachers();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item) => {
                let arrayItem = {id: parseInt(item.value), name: item.text};
                arrayList.push(arrayItem);
            })
        }

        setTeacherList(teacherList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    //Eğitmen listesi işlemleri
    const getWorkingTimeList = async (timeZonePermission) => {
        const result = await GetWorkingTimes();
        let arrayList = [];

        if (result.status === 200) {
            const data = JSON.parse(result.content);
            data.map((item, index) => {
                let arrayItem = {id: item, name: convertToAMPMFormat(addTimeZone(item, timeZone), timeZonePermission), tmpId: index + 1};
                arrayList.push(arrayItem);
            })
        }

        setWorkingTimeList(workingTimeList => arrayList);
        //fonksiyonu direkt çağıracak bölümler için return ediyoruz
        return arrayList;

    }

    useEffect(() => {
        getTeachers();
    }, [])

    useEffect(() => {
        if (timeZone !== -1) {
            getWorkingTimeList(timeZonePermission);
        }
    }, [timeZone, timeZonePermission]);

    return {
        teacherList, workingTimeList,
        getTeachers, getWorkingTimeList
    }
}

export default usePrimeData