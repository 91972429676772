import {useLocalization} from "../../hooks/useLocalization";
import {PageTitle, showTopAlert} from "../../components";
import {useRef, useState} from "react";
import {Link} from "react-router-dom";
import {url} from "../../routes/utility";
import {useDispatch} from "react-redux";
import {EmployeeUpdatePassword} from "../../services/Auth/AuthService";
import useAuth from "../../hooks/useAuth";
import {employeeLogout} from "../../store/EmployeeSlice";

const ChangePassword = () => {

    const strings = useLocalization();

    const { employee } = useAuth();
    const dispatch = useDispatch();

    const passwordAgainRef = useRef();
    const passwordRef = useRef();
    const visibilityRef = useRef();
    const visibilityRefAgain = useRef();
    const visibilityOffRef = useRef();
    const visibilityOffRefAgain = useRef();

    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");

    const errorRef = useRef();
    const passwordErrorRef = useRef();
    const passwordAgainErrorRef = useRef();

    const togglePassword = () => {
        visibilityRef.current.classList.toggle('hidden');
        visibilityOffRef.current.classList.toggle('hidden');
        passwordRef.current.type = passwordRef.current.type === "password" ? "text" : "password"
    }

    const togglePasswordAgain = () => {
        visibilityRefAgain.current.classList.toggle('hidden');
        visibilityOffRefAgain.current.classList.toggle('hidden');
        passwordAgainRef.current.type = passwordAgainRef.current.type === "password" ? "text" : "password"
    }

    const submitHandle = async (e) => {
        e.preventDefault();

        let valid = true;

        errorRef.current.innerText = "";
        passwordErrorRef.current.innerText = "";
        passwordAgainErrorRef.current.innerText = "";

        if(password.length === 0) {
            valid = false;
            passwordErrorRef.current.innerText = strings.errors.password_null_error;
        }
        if(passwordAgain.length === 0) {
            valid = false;
            passwordAgainErrorRef.current.innerText = strings.errors.password_again_null_error;
        }
        if(password !== passwordAgain && passwordAgain.length !== 0 && password.length !== 0) {
            valid = false;
            errorRef.current.innerText = strings.errors.password_dont_match_error;
        }

        if(valid) {
            setLoading(loading => true);

            const result = await EmployeeUpdatePassword(employee.EmployeeId, password);

            if(result.status === 200) {
                showTopAlert(strings.auth.update_password.success.message, "success");
                setTimeout(() => { dispatch(employeeLogout()) }, 1500);

            } else {
                showTopAlert(strings.errors.an_error_occurred, "success");
                setLoading(loading => false);
            }
        }
    }


    return (
        <>
            <PageTitle title={strings.sidebar.profile.change_password} />
            <form className="w-full flex justify-center" onSubmit={submitHandle}>
                <div className="flex flex-col gap-2 w-[400px] p-[25px] rounded-[5px] block bg-white mx-5 mt-5 md:mt-0">
                        <div className="flex flex-col px-[10px] pt-[5px] relative">
                            <label className="text-[13px]" htmlFor="change-password" >{strings.auth.update_password.your_password}</label>
                            <input ref={passwordRef} id="change-password" name="change-password" type="password" className="form-input"  onChange={(e) => setPassword(e.target.value)} />
                            <span ref={visibilityRef} onClick={togglePassword}
                                  className="toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[43px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility
                            </span>
                            <span ref={visibilityOffRef} onClick={togglePassword}
                                  className="hidden toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[43px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility_off
                            </span>
                            <span ref={passwordErrorRef} className="text-danger field-validation-valid mt-1 block text-center"></span>
                        </div>
                        <div className="flex flex-col px-[10px] pt-[5px] relative">
                            <label className="text-[13px]" htmlFor="change-password-again">{strings.auth.update_password.your_password2}</label>
                            <input ref={passwordAgainRef} id="change-password-again" name="change-password-again" type="password" className="form-input pr-10" onChange={(e) => setPasswordAgain(e.target.value)} />
                            <span ref={visibilityRefAgain} onClick={togglePasswordAgain}
                                  className="toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[43px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility
                            </span>
                            <span ref={visibilityOffRefAgain} onClick={togglePasswordAgain}
                                  className="hidden toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[43px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility_off
                            </span>
                            <span ref={passwordAgainErrorRef} className="text-danger field-validation-valid mt-1 block text-center"></span>
                        </div>
                        <div className="px-[10px] pt-[10px] md:pt-[25px]">
                            <button type="submit" disabled={loading} className={`${loading ? "!opacity-[70%] cursor-progress" : ""} btn btn-primary w-full`}>
                                <div>
                                    {loading ?
                                        <div className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
                                        : strings.auth.update_password.button}
                                </div>
                            </button>
                            <span ref={errorRef} className="text-danger field-validation-valid block text-center mt-1" ></span>
                        </div>
                </div>
            </form>

        </>
    )
}

export default ChangePassword;