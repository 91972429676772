import { fetchApi } from '../index';

//Eğitmenin çalışma saatlerini getiren servis
export const TeacherShiftList = async (teacherId) => {

    const url = '/teacher/v1/shift/shift_list/';

    const payload = {
        teacherId: teacherId
    };

    return await fetchApi(url, payload);
}

//Eğitmene çalışma saati eklemek için kullanılan servis.
export const AddShift = async (model) => {

    const url = '/teacher/v1/shift/add_shift/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Eğitmene çalışma saatini silmek için kullanılan servis.
export const DeleteShift = async (shiftId) => {

    const url = '/teacher/v1/shift/delete_shift/';

    const payload = {
        shiftId: shiftId
    };

    return await fetchApi(url, payload);
}

//Eğitmene çalışma saatini güncellemek için kullanılan servis.
export const EditShift = async (model) => {

    const url = '/teacher/v1/shift/edit_shift/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

//Eğitmene çalışma saatinin durumunu güncellemek için kullanılan servis.
export const EditShiftStatus = async (model) => {

    const url = '/teacher/v1/shift/edit_shift_status/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}