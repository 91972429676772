import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, TimeRangePicker, showTopAlert } from "../../../../components";
import { GeneralStatus } from "../../../../components/Constants";
import {addTimeZone, convertToAMPMFormat, translateStatusById} from "../../../../components/Utility";
import { useLocalization } from "../../../../hooks/useLocalization";
import usePrimeData from "../../../../hooks/usePrimeData";
import { DeleteShift, EditShift } from "../../../../services/Teacher/ShiftService";
import classNames from "classnames";
import {getButtonPermission} from "../../../../components/Buttons";
import useAuth from "../../../../hooks/useAuth";

const ShiftRowEditable = forwardRef((props, ref) => {

  const strings = useLocalization();
  const { shift, teacherId, teacherStatus, sendRequestToShiftListEndpoint } = props;
  const timeZone = useSelector(state => state.timeZoneStore.timeZone);
  const primeData = usePrimeData();
  const language = useSelector(state => state.localizationStore.language);
  const { employeeTypeId, checkPermission } = useAuth();

  const timeZoneRoles = getButtonPermission({ group: "timeZone", name: "timeZoneTR" });
  const timeZonePermission = checkPermission(timeZoneRoles, employeeTypeId);

  const timeRangeErrorRef = useRef();
  const statusErrorRef = useRef();

  const firstBeginTime = { id: -1, name: strings.detail_search_titles.begin_time };
  const [beginTime, setBeginTime] = useState(firstBeginTime);

  const firstEndTime = { id: -1, name: strings.detail_search_titles.end_time };
  const [endTime, setEndTime] = useState(firstEndTime);

  const firstStatus = { id: -1, name: strings.form_info.choose_status };
  const [statusData, setStatusData] = useState([]);
  const [status, setStatus] = useState(firstStatus);

  const updateShift = async (shiftId) => {
    let valid = true;
    
    timeRangeErrorRef.current.innerText = "";
    statusErrorRef.current.innerText = "";

    if(beginTime?.id === -1 || endTime?.id === -1){
      valid = false;
      timeRangeErrorRef.current.innerText = strings.errors.time_range_null_error;
    }
    if(status.id === -1){
      valid = false;
      statusErrorRef.current.innerText = strings.errors.status_null_error;
    }

    if(valid){
      const model = {
        teacherId: teacherId,
        shiftId: shiftId,
        days: [1, 2, 3, 4, 5],
        endTime: endTime?.id,
        beginTime: beginTime?.id,
        status: status.id
      };

      const result = await EditShift(model);

      if(result.status === 200){
        showTopAlert(strings.teacher.update_teacher.update_shift_success_message, "success");
        ref.current.focus();
        sendRequestToShiftListEndpoint();
      } else {
        showTopAlert(result.content, "error")
      }
    }
  };

  const deleteShift = async (shiftId) => {

    const result = await DeleteShift(shiftId);

    if(result.status === 200){
      showTopAlert(strings.teacher.create_teacher.delete_shift_success_message, "success");
      sendRequestToShiftListEndpoint();
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error")
    }
  };

  useEffect(() => {
    // setWeekDays(shift.days);
    setBeginTime({ id: shift.beginTime, name: convertToAMPMFormat(addTimeZone(shift.beginTime, timeZone), timeZonePermission) });
    setStatus({ id: shift.status, name: translateStatusById(shift.status, language) });
  }, [shift, timeZone])

  useEffect(() => {
    beginTime && setEndTime({ id: shift.endTime, name: convertToAMPMFormat(addTimeZone(shift.endTime, timeZone), timeZonePermission) });
  }, [beginTime, timeZone])

  useEffect(() => {
    let tmpStatusData = [firstStatus, ...GeneralStatus.map((status) => language === 'en' ? { id: status.id, name: status.eng } : { id: status.id, name: status.name })];
    setStatusData(statusData => tmpStatusData);
  }, []);

  return (
    <div
      className="flex flex-col xl:flex-row justify-start items-center xl:items-start"
    >
      <div className="flex flex-col px-[10px] pb-0 mt-5 w-full">
        <label className="font-bold mb-2">
          {strings.teacher.create_teacher.select_start_end_hour}
        </label>
        <TimeRangePicker
          rangeList={primeData.workingTimeList}
          startHour={beginTime || {id: shift.beginTime, name: shift.beginTime}}
          setStartHour={setBeginTime}
          endHour={endTime || {id: shift.endTime, name: shift.endTime}}
          setEndHour={setEndTime}
          heightClassnames="!max-h-[150px]"
          dropdownClassnames="lg:w-full md:max-w-full"
          isDisabled={teacherStatus === 0}
        />
        <span
          ref={timeRangeErrorRef}
          className=" text-danger field-validation-valid px-[10px]"
        >
        </span>
      </div>
      <div className="flex flex-col px-[10px] pb-0 mt-5 w-full">
        <label className="font-bold mb-2">{strings.form_info.status}</label>
        <div className="flex gap-5 xs:gap-10">
          <Dropdown
            data={statusData}
            selectedData={status}
            setSelectedData={setStatus}
            classnames="md:max-w-full"
            isDisabled={teacherStatus === 0}
          />
        </div>
        <span
          ref={statusErrorRef}
          className=" text-danger field-validation-valid px-[10px]"
        >
        </span>
      </div>

      <div className="flex gap-1">
        <button
          type="button"
          className={classNames("flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-blue text-blue w-8 h-8 rounded-[3px] relative xl:mt-[52px]", {
            "opacity-70 cursor-not-allowed": teacherStatus === 0
          })}
          onClick={() => teacherStatus === 1 && updateShift(shift.shiftId)}
        >
          <span className="material-symbols-outlined text-[18px]">edit</span>
          {teacherStatus === 1 && (
            <div className="tooltip-top">
              <p>{strings.button.update}</p>
            </div>
          )}
        </button>

        <button
          type="button"
          className={classNames("flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-red text-red w-8 h-8 rounded-[3px] relative xl:mt-[52px]", {
            "opacity-70 cursor-not-allowed": teacherStatus === 0
          })}
          onClick={() => teacherStatus === 1 && deleteShift(shift.shiftId)}
        >
          <span className="material-symbols-outlined text-[18px]">delete</span>
          {teacherStatus === 1 && (
            <div className="tooltip-top">
              <p>{strings.button.delete}</p>
            </div>
          )}
        </button>
      </div>
    </div>
  );
});

export default ShiftRowEditable;
