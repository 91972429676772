import {addTimeZone} from "../../../../components/Utility";
import {SquareButtonWithoutLink} from "../../../../components/Buttons";
import React, {useState} from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import {useSelector} from "react-redux";
import MemberTermDetailModal from "../../../Teacher/components/TeacherProgram/MemberTermDetailModal";
import {Modal} from "../../../../components";

const StudentListTableRow = ({ student, sendRequestToEndpoint }) => {

    const strings = useLocalization();
    const timeZone = useSelector(state => state.timeZoneStore.timeZone);

    const [modal, setModal] = useState(false);
    const [selectedTermData, setSelectedTermData] = useState({});

    const getLessonDays = (day) => {
        switch (day){
            case 2:
                return "#2 #4";
            case 3:
                return "#1 #3 #5";
            case 5:
                return "#1 #2 #3 #4 #5"
            default:
                return "-";
        }
    }

    const handleTransactionButton = (student) => {
        let tmpData = {
            termId: student.termId,
            fullName: student.studentName,
            beginTime: addTimeZone(student.lessonTime, timeZone),
            lessonDuration: student.lessonDuration,
            studentNo: student.studentNo
        };

        setModal(modal => true);
        setSelectedTermData(selectedTermData =>  tmpData);
    }

    const closeModal = () => {
        sendRequestToEndpoint();
        setModal(modal => false);
    }

    return(
        <>
            <tr className="bg-white hover:bg-[#f0f0f0]">
                <td className="td align-middle">
                    <div className="flex flex-col">
                        <p className="min-w-max">{student.studentNo}</p>
                        <p className="min-w-max">{strings.form_info.student} {student?.studentName.split(" ")[0] ?? student?.studentName}</p>
                    </div>
                </td>
                <td className="td align-middle">
                    <p className="min-w-max">{student.teacherName}</p>
                </td>
                <td className="td align-middle">
                    <p className="min-w-max">{getLessonDays(student.lessonDays)}</p>
                </td>
                <td className="td align-middle">
                    <p className="min-w-max">{addTimeZone(student.lessonTime, timeZone)}</p>
                </td>
                <td className="td align-middle">
                    <p className="min-w-max">{student.lessonDuration} {strings.table.minutes.toLowerCase()}</p>
                </td>
                <td className="td align-middle">
                    <SquareButtonWithoutLink
                        tooltip={strings.teacher.teacher_list.detail}
                        classnames="bg-blue"
                        iconName="visibility"
                        action={() => handleTransactionButton(student)}
                    />
                </td>
            </tr>

            <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[90vh] max-w-[1440px]" }} >
                <MemberTermDetailModal data={selectedTermData} teacherId={student.teacherId} closeModal={closeModal} />
            </Modal>
        </>
    )
}

export default StudentListTableRow;