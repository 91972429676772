import { fetchApi } from "../FetchApi"


export const GetTeachers = async () => {
    const url = '/teacher/v1/teacher/all/';

    const payload = {};

    return await fetchApi(url, payload);
}

export const GetWorkingTimes = async () => {
    const url = '/teacher/v1/shift/working-times/';

    const payload = {};

    return await fetchApi(url, payload);
}