import AuthRoute from "../pages/AuthRoute";
import { CheckAuth } from "../pages/Redirect";
import { AuthLayout, Login, ForgotPassword, VerifyCode, UpdatePassword } from "../pages/Auth";
import { Dashboard, DashboardLayout } from "../pages/Dashboard";
import {TeacherLayout, Teacher, CreateTeacher, TeacherProgram, EditTeacher, TeacherBlocks} from "../pages/Teacher";
import { ErrorLayout } from "../pages/Error404";
import {GiftLayout, GiftList} from "../pages/Gift";
import {ChangePassword, ProfileLayout} from "../pages/Profile";
import {StudentLayout} from "../pages/Student";
import StudentList from "../pages/Student/StudentList";

const routes = [

    {
        name: "home",
        path: "/",
        roles: [],
        element: <CheckAuth />,
    },
    {
        name: "auth",
        path: "auth",
        roles: [],
        element: <AuthLayout />,
        children: [
            {
                index: true,
                element: <Login />
            },
            {
                name: "login",
                path: "login",
                roles: [],
                element: <Login />
            },
            {
                name: "forgotpassword",
                path: "forgotpassword",
                roles: [],
                element: <ForgotPassword />
            },
            {
                name: "verifycode",
                path: "verifycode",
                roles: [],
                element: <VerifyCode />
            },
            {
                name: "updatepassword",
                path: "updatepassword",
                roles: [],
                element: <UpdatePassword />
            },
        ]
    },
    {
        name: "dashboard",
        path: "dashboard",
        auth: true,
        roles: [],
        element: <DashboardLayout />,
        children: [
            {
                index: true,
                auth: true,
                roles: [],
                element: <Dashboard />
            },
        ]
    },
    {
        name: "profile",
        path: "profile",
        auth: true,
        roles: [],
        element: <ProfileLayout />,
        children: [
            {
                name: 'changepassword',
                path: 'changepassword',
                auth: true,
                roles: [],
                element: <ChangePassword />
            },
        ]
    },
    {
        name: "teacher",
        path: "teacher",
        auth: true,
        roles: [],
        element: <TeacherLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <Teacher />
            },
            {
                name: 'list',
                path: 'list',
                auth: true,
                roles: [],
                element: <Teacher />
            },
            {
                name: 'create',
                path: 'create',
                auth: true,
                roles: [],
                element: <CreateTeacher />
            },
            {
                name: 'edit',
                path: 'edit/:teacherId',
                auth: true,
                roles: [],
                element: <EditTeacher />
            },
            {
                name: 'program',
                path: 'program/:teacherId',
                auth: true,
                roles: [2, 3, 22],
                element: <TeacherProgram />
            },
            {
                name: 'blocks',
                path: 'blocks',
                auth: true,
                roles: [2, 3, 22],
                element: <TeacherBlocks />
            },
        ]
    },
    {
        name: "student",
        path: "student",
        auth: true,
        roles: [],
        element: <StudentLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <StudentList />
            },
            {
                name: 'list',
                path: 'list',
                auth: true,
                roles: [2, 3, 22],
                element: <StudentList />
            }
        ]
    },
    {
        name: "gift",
        path: "gift",
        auth: true,
        roles: [2, 3, 22],
        element: <GiftLayout />,
        children: [
            {
                index: true,
                auth: true,
                element: <GiftList />
            },
            {
                name: 'list',
                path: 'list',
                auth: true,
                roles: [2, 3, 22],
                element: <GiftList />
            },
        ]
    },
    {
        name: "error404",
        path: "*",
        auth: true,
        roles: [],
        element: <ErrorLayout />
    }
]

const authMap = routes => routes.map(route => {
    if (route?.auth) {
        route.element = <AuthRoute>{route.element}</AuthRoute>;
    }
    if (route?.children) {
        route.children = authMap(route.children)
    }
    return route
})

export default authMap(routes);