import { fetchApi } from '../index';


export const GetGiftList = async (model) => {

    const url = '/international/gift_list/get_gift_list/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetUndeliveredGift = async () => {
    const url = '/international/gift_list/get_undelivered_gift/';

    const payload = {};

    return await fetchApi(url, payload);
}


export const UpdateGiftStatus = async (giftId) => {

    const url = '/international/gift_list/update_gift_status/';

    const payload = {
        giftId: giftId
    };

    return await fetchApi(url, payload);
}
