import React, { forwardRef, useState } from "react";
import { showTopAlert } from "../../../../components";
import { useLocalization } from "../../../../hooks/useLocalization";
import { TeacherShiftList } from "../../../../services/Teacher/ShiftService";
import { AddShiftRow, ShiftRowReadonly } from "../../components";

const CreateShift = forwardRef((props, ref) => {

  const strings = useLocalization();
  const { teacherId, isTeacherCreated } = props

  const [shifts, setShifts] = useState([]);

  //Eğitmenlerin çalışma saatlerini getiren servis
  const getTeacherShiftList = async (id) => {
    const result = await TeacherShiftList(id);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setShifts(shifts => resultContent);
    } else {
      showTopAlert(strings.errors.an_error_occurred, "error")
    }
  }

  const sendRequestToShiftListEndpoint = () => {
    getTeacherShiftList(teacherId);
  }
 
  return (
    <>
      <h5 className="font-bold text-[15px] pl-3 my-3 mt-7">
        {strings.teacher.create_teacher.working_hours}
      </h5>
      <hr />

      <AddShiftRow ref={ref} teacherId={teacherId} isDisabled={!isTeacherCreated} sendRequestToEndpoint={sendRequestToShiftListEndpoint} />

      <ShiftRowReadonly shiftData={shifts} sendRequestToShiftListEndpoint={sendRequestToShiftListEndpoint} />
    </>
  );
});

export default CreateShift;
