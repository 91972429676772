import { useRef, useState } from "react";
import { useLocalization } from "../hooks/useLocalization";
import Input from "./Input";
import classNames from "classnames";

export const SearchBar = ({ placeHolder, changeValue, isDisabled = false }) => {

    const strings = useLocalization();
    const [value, setValue] = useState();
    const inputRef = useRef();

    const handleClick = () => {
        changeValue?.(value);
    }

    const handleClear = () => {
        setValue(value => '');
        changeValue?.('');
        inputRef.current.value = '';
    }

    return (
        <div className="py-6 flex flex-col md:flex-row gap-2 xs:gap-4">
            <Input ref={inputRef}
                id="searchInput"
                onChange={e => setValue(e.target.value)}
                onKeyDown={e => {
                    if (e.keyCode === 13) {
                        handleClick();
                    }
                }}
                type="text"
                classnames="input-field w-full"
                placeholder={placeHolder}
                isDisabled={isDisabled}
            />
            <div className="flex gap-2 max-md:mx-auto">
                <button
                    className={classNames("button search-button gap-3 text-white bg-blue border-blue", {
                        "opacity-70 pointer-events-none" : isDisabled
                    })}
                    onClick={handleClick}
                    disabled={isDisabled}
                >
                    <span className="material-symbols-outlined">search</span>
                    <p>{strings.search_bar.search}</p>
                </button>
                <button
                    className={classNames("button search-button gap-3 text-white bg-red border-red focus:border-red", {
                        "opacity-70 pointer-events-none" : isDisabled
                    })}
                    onClick={handleClear}
                    disabled={isDisabled}
                >
                    <span className="material-symbols-outlined">clear</span>
                    <p>{strings.search_bar.clear}</p>
                </button>
            </div>
        </div>
    );
}
