import React, { useState, useEffect } from "react";
import { useLocalization } from "../hooks/useLocalization";

const Loading = () => {
  const strings = useLocalization();
  const [loadingText, setLoadingText] = useState("   ");
  const loadingSequence = ["   ",".  ", ".. ", "..."];

  useEffect(() => {
    let currentIndex = 0;

    const interval = setInterval(() => {
      currentIndex = (currentIndex + 1) % loadingSequence.length;
      setLoadingText(loadingSequence[currentIndex]);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
      <div className="flex gap-2 items-center justify-center mt-5">
        <div className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
        <p>
          {strings.loading}
          {loadingText}
        </p>
      </div>
  );
};

export default Loading;
