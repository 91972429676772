export const MainItems = (strings) => [
    {
        pathName: "dashboard",
        path: "/dashboard",
        icon: "home",
        isIcon: false,
        title: strings.sidebar.home_page,
    }
]

export const TeacherOperationItems = (strings) => [
    {
        pathName: "teacher.list",
        path: "/teacher/list",
        icon: "school",
        isIcon: false,
        title: strings.sidebar.teacher.list,
    },
    {
        pathName: "teacher.create",
        path: "/teacher/create",
        icon: "add_circle",
        isIcon: false,
        title: strings.sidebar.teacher.add_teacher,
    },
    {
        pathName: "teacher.blocks",
        path: "/teacher/blocks",
        icon: "block",
        isIcon: false,
        title: strings.sidebar.teacher.teacher_blocks,
    }
]

export const StudentOperationItems = (strings) => [
    {
        pathName: "student.list",
        path: "/student/list",
        icon: "person",
        isIcon: false,
        title: strings.sidebar.student.student_list,
    },
]

export const GiftOperationItems = (strings) => [
    {
        pathName: "gift",
        path: "/gift",
        icon: "school",
        isIcon: false,
        title: strings.sidebar.gift.gift_list,
    },
]