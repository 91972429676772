import React, {useEffect, useState} from 'react'
import {useLocalization} from '../../../../hooks/useLocalization';
import {GetLessonHistory, GetTeacherScheduleTermDetail} from '../../../../services/Teacher/ScheduleService';
import {LessonTime, Loading, showTopAlert} from '../../../../components';
import {
    adjustTimeWithTimeZone,
    calculateEndTime,
    convertToAMPMFormat,
    getTeacherName,
    iconForLessonStatus
} from '../../../../components/Utility';
import {useSelector} from 'react-redux';
import useAuth from "../../../../hooks/useAuth";
import {
    GetTeacherFreeTimesByRange,
    TeacherLessonTimeChange,
} from "../../../../services/Teacher/MoveStudentService";
import {getButtonPermission, SquareButtonWithoutLink} from "../../../../components/Buttons";
import {ServiceErrorMessages} from "../../../../consts/Constants";

const MemberTermDetailModal = (props) => {

  const { data, closeModal, teacherId } = props;
  const strings = useLocalization();

  const timeZone = useSelector(state => state.timeZoneStore.timeZone);
  const language = useSelector((state) => state.localizationStore.language);
  const { employee, employeeTypeId, checkPermission } = useAuth();

  const timeZoneRoles = getButtonPermission({ group: "timeZone", name: "timeZoneTR" });
  const timeZonePermission = checkPermission(timeZoneRoles, employeeTypeId);

  const [termDetail, setTermDetail] = useState([]);
  const [lessonHistory, setLessonHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [moveLoading, setMoveLoading] = useState(false);

  const [freeTimeList, setFreeTimeList] = useState([]);
  const [freeTimeLoading, setFreeTimeLoading] = useState(true);

  const getTeacherScheduleTermDetail = async (termId) => {
    const result = await GetTeacherScheduleTermDetail(termId);

    if(result.status === 200){
        let resultContent = JSON.parse(result.content);
        setTermDetail(termDetail => resultContent);
        setLoading(loading => false);
    } else {
        setTimeout(() => closeModal(), 1000);
        showTopAlert(strings.errors.an_error_occurred, "error");
    }
  };

  //Ders geçmişini getiren servis
  const getLessonHistory = async (termId) => {
    const result = await GetLessonHistory(termId);

    if (result.status === 200) {
        let resultContent = JSON.parse(result.content);

        setLessonHistory(lessonHistory => resultContent.lessonStatusItems);
    }
  }

  const getEmptyTeachersByTerm = async (data, teacherId, timeZone) => {

      let beginTimeWithoutTimezone = adjustTimeWithTimeZone(data.beginTime, timeZone);

      let model = {
          termId: data.termId,
          beginTime: beginTimeWithoutTimezone + ":00",
          endTime: calculateEndTime(beginTimeWithoutTimezone, data.lessonDuration) + ":00",
          teacherId: 0
      }

      const result = await GetTeacherFreeTimesByRange(model);

      if(result.status === 200) {
          let resultContent = JSON.parse(result.content).teacherFreeLessonTimeItems;

          if(teacherId !== +process.env.REACT_APP_TEACHER_SANDY_ID) {
              let teacherSandyModel = {
                  lessonTime: beginTimeWithoutTimezone,
                  teacherName: "Teacher Unknown",
                  teacherId: +process.env.REACT_APP_TEACHER_SANDY_ID
              }

              resultContent.unshift(teacherSandyModel);
          }

          setFreeTimeList(freeTimeList => resultContent);
          setFreeTimeLoading(freeTimeLoading => false);
      }
  }

  const moveStudent = async (teacherId, teacherName) => {

      setMoveLoading(moveLoading => true);
      let beginTimeWithoutTimezone = adjustTimeWithTimeZone(data.beginTime, timeZone);

      let model = {
          teacherId: teacherId,
          lessonTime: beginTimeWithoutTimezone,
          termId: data.termId,
          createUserId: employee.EmployeeId
      }

      const result = await TeacherLessonTimeChange(model);

      if(result.status === 200) {
          let successMessage = strings.teacher.move_student.moved_success_message
              .replace("#studentName#", data?.fullName.split(" ")[0] ?? data?.fullName)
              .replace("#teacherName#", teacherName);

          showTopAlert(successMessage, "success");
          closeModal();
      } else {
          if(result.content === ServiceErrorMessages.ESD_NOT_IN_TIME_INTERVAL) {
              showTopAlert(strings.errors.esd_time_frame_error, "error");
          } else if(result.content === ServiceErrorMessages.ESD_TWO_HOURS_ERROR) {
              showTopAlert(strings.errors.esd_two_hour_error, "error");
          } else {
              showTopAlert(strings.errors.an_error_occurred, "error");
          }
      }

      setMoveLoading(moveLoading => false);
  }

  const sendRequestToEmptyTeachersEndpoint = () => {
      getEmptyTeachersByTerm(data, teacherId, timeZone);
  }

  useEffect(() => {
    if(data){
        getTeacherScheduleTermDetail(data.termId);
        getLessonHistory(data.termId);
    }
  }, [data]);

  useEffect(() => {
      if(timeZone !== -1) {
        sendRequestToEmptyTeachersEndpoint();
      }
  }, [teacherId, timeZone, data]);

  return (
    <>
        {loading ? (
            <Loading />
        ) : (
            <>
                <div className="flex flex-col items-center justify-center">
                    <p className="font-bold text-[15px]">{data?.studentNo}</p>
                    <p className="font-bold text-[15px]">{strings.form_info.student} {data?.fullName.split(" ")[0] ?? data?.fullName}</p>
                </div>
                <div className="flex flex-col gap-2">
                    <p><span className="font-bold">{strings.teacher.teacher_program.weekly_lesson_count}: </span>{termDetail?.productLessonDayCount}</p>
                    <p><span className="font-bold">{strings.table.begin_time}: </span>{convertToAMPMFormat(data?.beginTime, timeZonePermission)}</p>
                    <p><span className="font-bold">{strings.teacher.teacher_program.lesson_time}: </span>{data?.lessonDuration} {strings.table.minutes}</p>
                    <p><span className="font-bold">{strings.teacher.teacher_program.remaining_lesson_count}: </span>{termDetail?.incompleteLessonCount}</p>
                </div>
                <div className="flex flex-wrap mt-5 gap-4 justify-center">
                    {lessonHistory.length > 0 && lessonHistory.map((lesson, index) => {
                        const icon = iconForLessonStatus(lesson.status, language);
                        return (
                            <LessonTime
                                key={index}
                                tooltip={icon.tooltip}
                                iconName={icon.iconName}
                                iconClassnames={icon.color}
                                date={lesson.date}
                            />
                        );
                    })}
                </div>


                <div className="pb-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto mt-3">
                    <div className="min-w-full rounded-lg">
                        {freeTimeLoading && (
                            <Loading />
                        )}
                        {!freeTimeLoading && (
                            <div className="max-h-[52vh] overflow-y-auto">
                                <table className="min-w-full leading-normal text-header">
                                    <thead className="table table-fixed w-full">
                                        <tr>
                                            <th className="th sm:w-[80%]"><p className="min-w-max">{strings.table.teacher_name}</p></th>
                                            <th className="th sm:w-[20%]">{strings.table.operation}</th>
                                        </tr>
                                    </thead>
                                    <tbody className="flex flex-col w-full">
                                    {freeTimeList.length > 0 && (

                                        freeTimeList.map((teacher, index) => (
                                            <tr key={index} className="bg-white hover:bg-[#f0f0f0] sm:table table-fixed w-full flex">
                                                <td className="td align-middle sm:w-[80%]">
                                                    <p className="py-3 min-w-max">{getTeacherName(teacher.teacherId, teacher.teacherName)}</p>
                                                </td>
                                                <td className="td align-middle sm:w-[20%]">
                                                    <SquareButtonWithoutLink
                                                        classnames="bg-blue ml-2"
                                                        iconName="check_circle"
                                                        tooltip={strings.button.move}
                                                        isDisabled={moveLoading}
                                                        action={() => moveStudent(teacher.teacherId, teacher.teacherName)}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                    {freeTimeList.length === 0 && (
                                        <tr className="bg-white hover:bg-[#f0f0f0] flex justify-center">
                                            <td colSpan={2}>
                                                <p className="py-3 min-w-full font-bold">
                                                    {strings.errors.no_records_found}
                                                </p>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </>
        )}
    </>
  )
};

export default MemberTermDetailModal;