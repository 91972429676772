import React, { useEffect, useState } from 'react'
import { Modal } from '../../../../components';
import AddBreakModal from './AddBreakModal';

const FreeLesson = (props) => {

  const { time, day, teacherId, sendRequestToEndpoint } = props;  

  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const closeButton = () => {
    setModal(false);
  }

  useEffect(() => {
    if(!modal && modalContent.element === "break"){
      sendRequestToEndpoint();
    }
  }, [modal])

  return (
    <>
        <div className='free-lesson min-h-[60px] -m-[1px]'>
            <button
              onClick={() => {setModal(true); setModalContent({element: "break"}); }}
              className="flex-shrink-0 group inline-flex items-center justify-center bg-transparent border border-text-base-text bg-blue hover:bg-white w-5 h-5 rounded-[3px] relative"
            >
              <span className="material-symbols-outlined text-[14px]">menu</span>
            </button>
        </div>

        {(Object.keys(modalContent).length !== 0 && (
        <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[400px] max-w-[850px]"}} >
          {modalContent.element === "break" && 
            <AddBreakModal 
              teacherId={teacherId} 
              time={time} 
              day={day} 
              closeButton={closeButton} 
              sendRequestToEndpoint={sendRequestToEndpoint}
            />
          }
        </Modal>
        ))}
    </>
  )
};

export default FreeLesson;