import {SquareButtonWithoutLink} from "../../../../components/Buttons";
import {useLocalization} from "../../../../hooks/useLocalization";
import {UpdateGiftStatus} from "../../../../services/Gift/GiftService";
import {Modal, showTopAlert} from "../../../../components";
import {useState} from "react";
import GiftImageModal from "./GiftImageModal";
import Portal from "../../../../components/Portal";

const GiftListTableRow = ({ gift }) => {

    const strings = useLocalization();
    const [modal, setModal] = useState(false);
    const [imageName, setImageName] = useState("");

    const sendGift = async (giftId) => {
        const result = await UpdateGiftStatus(giftId);
        showTopAlert(strings.gift.gift_list.gift_delivered_success_message, "success");
        setTimeout(() => {window.location.reload()}, 1500)
    }

    return (
        <>
            <tr className="bg-white hover:bg-[#f0f0f0]">
                {/*<td className="td align-middle">*/}
                {/*    {gift.Id}*/}
                {/*</td>*/}
                <td className="td align-middle">
                    <div className="flex flex-col gap-0.5">
                        <p className="min-w-max font-bold">{gift.StudentNo} - {strings.form_info.student} {gift?.StudentName.split(" ")[0] ?? gift?.StudentName}</p>
                        <p className="min-w-max">{gift.CreateDate}</p>
                        <hr className="m-2 w-28"/>
                        <p className="flex flex-col gap-0.5">
                            <span className="min-w-max">{gift.TeacherName}</span>
                            {/*<span>Message: {gift.Message}</span>*/}
                        </p>
                    </div>
                </td>
                <td className="td align-middle">
                    <div className="flex items-center">
                        <button
                            type="button"
                            className="min-w-max font-bold text-blue hover:underline focus:underline"
                            onClick={() => {
                                setImageName(imageName => gift.ProductName)
                                setModal(modal => true);
                            }}
                        >
                            {gift.ProductName}
                        </button>
                    </div>
                </td>
                {/*<td className="td align-middle">*/}
                {/*    <div className="flex items-center">*/}
                {/*        <p className="min-w-max">${formatter.format(gift.Price)}</p>*/}
                {/*    </div>*/}
                {/*</td>*/}
                <td className="td align-middle">
                    {gift.IsSended === "1" && (
                        <span className="material-symbols-outlined text-green font-bold text-[40px]">
                            check
                        </span>
                    )}
                    {(gift.IsSended === "0" || gift.IsSended === null) && (
                        <SquareButtonWithoutLink
                            role={{ group: "giftList", name: "giftOperations" }}
                            classnames="bg-blue"
                            iconName="send"
                            tooltip={strings.button.delivered}
                            action={() => {
                                sendGift(gift.Id);
                            }}
                        />
                    )}
                </td>
            </tr>

            <Portal container={document.getElementById('gift-image-modal')}>
                <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[430px] max-w-[520px]"}}>
                    <GiftImageModal imageName={imageName} />
                </Modal>
            </Portal>
        </>
    )

}

export default GiftListTableRow;
