import { useLocalization } from "../../../../hooks/useLocalization";
import { SquareButton } from "../../../../components";
import { url } from "../../../../routes/utility";
import {getTeacherName} from "../../../../components/Utility";

const TeacherTableRow = (props) => {

  const { teacher } = props;

  const strings = useLocalization();

  const handleAverageRating = (teacherId, averageRating, studentRateCount) => {
      if(averageRating === null || +process.env.REACT_APP_TEACHER_SANDY_ID === teacherId){
          return "-"
      } else {
          return `${averageRating} (${studentRateCount})`;
      }
  }

  return (
      <>
          <tr className="bg-white hover:bg-[#f0f0f0]">
              <td className="td align-middle">
                  <div className="flex items-center mt-[6px]">
                <span className="material-symbols-outlined mr-1 flex-shrink-0 text-[19px]">
                  person
                </span>
                      <p className="min-w-max font-bold">{getTeacherName(teacher.id, teacher.name)}</p>
                  </div>
              </td>
              <td className="td align-middle">
                  <p className="min-w-max">{handleAverageRating(teacher?.id, teacher?.averageRating, teacher?.studentRateCount)}</p>
              </td>
              <td className="td align-middle">
                  <div className="flex gap-1 mr-5">
                      <SquareButton
                          role={{group: "teacherList", name: "calendar"}}
                          classnames="bg-blue"
                          iconName="calendar_month"
                          to={url("teacher.program", {teacherId: teacher.id})}
                          tooltip={strings.teacher.teacher_list.education_program}
                      />
                      <SquareButton
                          classnames="bg-green"
                          iconName="edit"
                          to={url("teacher.edit", {teacherId: teacher.id})}
                          tooltip={strings.button.update}
                      />
                  </div>
              </td>
          </tr>
      </>
  );
};

export default TeacherTableRow;