import {TeacherOperationItems} from "../../../consts/menu/SidebarMenu";
import useMobileView from "../../../hooks/useMobileView";
import {NavLink, useLocation} from "react-router-dom";
import {useLocalization} from "../../../hooks/useLocalization";
import classNames from "classnames";
import { url } from "../../../routes/utility";

const TeacherOperations = () => {

    const strings = useLocalization();
    const location = useLocation();
    const path = location?.pathname;
    const { isMobileView } = useMobileView();

    const TEACHER_OPERATION_ITEMS = TeacherOperationItems(strings);

    return (
        <div>
            <div className="header text-section">
                <h4>{strings.sidebar.teacher.title}</h4>
                <span className="material-symbols-outlined ml-1 font-bold hidden">more_horiz</span>
            </div>

            {TEACHER_OPERATION_ITEMS.map((item, index) => (
                <NavLink
                    key={index}
                    to={url(item.pathName)}
                    className={({ isActive }) =>
                        classNames("sidebar-buttons group", {
                            "active-button": isActive,
                            "!justify-end": !isMobileView
                        })
                    }
                    onClick={() => {
                        if (path === item.path) {
                            window.location.href = `${item.path}`;
                        }
                    }}
                >
                    <span className="material-symbols-outlined mr-3 flex-shrink-0 h-6 w-6">
                      {item.icon}
                    </span>
                    <span className="toggled w-full">{item.title}</span>
                </NavLink>
            ))}
        </div>
    );
}

export default TeacherOperations;