import {useEffect, useState} from "react";
import {GiftList} from "../../../../consts/GiftList";
import {useLocalization} from "../../../../hooks/useLocalization";


const GiftImageModal = ({ imageName }) => {

    const strings = useLocalization();
    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {

        const gift = GiftList.find(gift => imageName.toUpperCase().includes(gift.name.toUpperCase()));
        gift !== undefined && setImageUrl(gift.imageUrl);

    }, [imageName, GiftList]);

    return (
        <>
            <div className="flex flex-col justify-center items-center">
                <p className="font-bold text-[15px] mb-1">{imageName}</p>
                <img src={imageUrl} width={400} className="rounded-[10px]"/>
            </div>
            {imageUrl === "" && (
                <p className="flex items-center justify-center mt-2">{strings.gift.gift_list.image_not_found}</p>
            )}
        </>
    )
}

export default GiftImageModal;