import {GetTNTTimeZone} from "../services/Teacher/TeacherService";
import {useEffect, useState} from "react";
import {getCookie} from "ko-basic-cookie";
import {cookieName} from "../components/Constants";
import {checkPermission, decodeToken} from "../components/Utility";
import {getButtonPermission} from "../components/Buttons";

const useTimeZoneService = () => {

    const token = getCookie(cookieName);
    const employee = token && decodeToken(token);
    const [timeZone, setTimeZone] = useState(-1);

    const timeZoneRoles = getButtonPermission({ group: "timeZone", name: "timeZoneTR" });
    const timeZonePermission = checkPermission(timeZoneRoles, +employee.EmployeeTypeId);

    useEffect(() => {
        if(timeZonePermission) {
            setTimeZone(timeZone => +employee.TimeZone);
        } else {
            GetTNTTimeZone().then(result => {
                setTimeZone(result);
            });
        }
    }, [timeZonePermission]);

    return { timeZone }
}

export default useTimeZoneService