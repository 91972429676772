import {useEffect, useState} from "react";
import {useLocalization} from "../../hooks/useLocalization";
import useAuth from "../../hooks/useAuth";
import StudentListDetailSearch from "./components/StudentList/StudentListDetailSearch";
import {Loading, PageTitle, Pagination, SearchBar, SearchCount, showTopAlert} from "../../components";
import {GetAllTermFilter} from "../../services/Student/StudentService";
import NotAuthorized from "../../components/NotAuthorized";
import StudentListTable from "./components/StudentList/StudentListTable";
import {areObjectsEqual} from "../../components/Utility";

const StudentList = () => {

    const strings = useLocalization();

    const { employeeTypeId, checkPermission, getPermission } = useAuth();
    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);

    const limit = parseInt(process.env.REACT_APP_TABLE_ROW_LIMIT);
    const [start, setStart] = useState(0);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRecord, setTotalRecord] = useState(0);
    const [data, setData] = useState([]);

    const [detailSearch, setDetailSearch] = useState({
        lessonDays: null,
        lessonDuration: null,
        searchText: ""
    })

    //Detail Search objesini ilk başta tutup değişip-değilmediğini anlamak için kullanılır.
    const [tmpDetailSearch, setTmpDetailSearch] = useState({});
    //Servise gidip-gitmeme durumunu kontrol eden state
    const [sendRequest, setSendRequest] = useState();
    //Servise gidildiğinde çalışır.
    const [isFirstRequest, setIsFirstRequest] = useState(false);

    //Arama verileri değiştiğinde değerleri sıfırlıyoruz
    const resetValue = () => {
        setStart(start => 0);
        setCurrentPage(currentPage => 1);
        setLoading(loading => true);
    }

    //select değişiklikleri bu alandan yönetiliyor
    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
        resetValue();
    }

    //input değişiklikleri bu alandan yönetiliyor
    const changeValue = (value) => {
        setDetailSearch({ ...detailSearch, searchText: value });
        resetValue();
    }

    const getAllTermFilter = async (detailSearch) => {
        const model = {
            start: start,
            length: limit,
            lessonDuration: detailSearch.lessonDuration === -1 ? null : detailSearch.lessonDuration,
            lessonDays: detailSearch.lessonDays === -1 ? null : detailSearch.lessonDays,
            searchText: detailSearch.searchText
        }

        const result = await GetAllTermFilter(model);

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            setIsFirstRequest(isFirstRequest => true);
            setData(data => resultContent.data);
            setTotalRecord(totalRecord => resultContent.recordsTotal);
            setLoading(loading => false);
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }

    const sendRequestToEndpoint = () => {
        getAllTermFilter(detailSearch);
    }

    //sayfa değiştikçe bilgileri yeniden çağırıyoruz
    useEffect(() => {
        if (totalRecord !== 0) {
            sendRequestToEndpoint();
        }
    }, [start])

    useEffect(() => {

        let abortController = new AbortController();

        //Endpoint'e gitmeden loading'i true'ya çekip dil değiştiğinde de loading'in devreye girmesini sağlıyoruz
        if(sendRequest && permission) {
            setLoading(loading => true);
            sendRequestToEndpoint();
        }

        return () => {
            abortController.abort();
        }
    }, [detailSearch, sendRequest])

    useEffect(() => {
        setTmpDetailSearch(tmpDetailSearch => detailSearch);
    }, []);

    useEffect(() => {
        if(Object.keys(tmpDetailSearch).length > 0) {
            setSendRequest(sendRequest => !areObjectsEqual(detailSearch, tmpDetailSearch));
        }
    }, [detailSearch, tmpDetailSearch])

    return(
        <>
            {permission && (
                <>
                    <StudentListDetailSearch
                        changeDetail={changeDetail}
                        loading={loading}
                    />
                    <SearchBar
                        changeValue={changeValue}
                        placeHolder={strings.search_bar.search_student}
                        isDisabled={loading}
                    />
                    {!loading && <SearchCount count={totalRecord} />}
                </>
            )}

            <PageTitle title={strings.sidebar.student.student_list} />

            {permission && (
                loading ?
                    (
                        <Loading />
                    )
                    :
                    (
                        <>
                            <StudentListTable
                                data={data}
                                sendRequestToEndpoint={sendRequestToEndpoint}
                                isFirstRequest={isFirstRequest}
                            />
                            <Pagination
                                totalCount={totalRecord}
                                limit={limit}
                                start={start}
                                setStart={setStart}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                setLoading={setLoading}
                                loadScreen={true}
                            />
                        </>
                    )
            )}

            {!permission && <NotAuthorized />}
        </>
    )
}

export default StudentList;