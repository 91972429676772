import { getCookie } from "ko-basic-cookie";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { showTopAlert } from "../../components";
import { EmployeeStatusType, cookieName } from "../../components/Constants";
import { decodeToken, validateEmail } from "../../components/Utility";
import { useLocalization } from "../../hooks/useLocalization";
import { url } from "../../routes/utility";
import { EmployeeLogin } from "../../services/Auth/AuthService";
import { employeeLogin } from "../../store/EmployeeSlice";
import useAuth from "../../hooks/useAuth";
import { getButtonPermission } from "../../components/Buttons";

const Login = () => {
    const strings = useLocalization();
    const { checkPermission } = useAuth();
    const loginAuthorityRoles = getButtonPermission({ group: "teacherLogin", name: "login" });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)

    const inputRef = useRef();
    const visibilityRef = useRef();
    const visibilityOffRef = useRef();
    const emailErrorRef = useRef();
    const passwordErrorRef = useRef();

    const token = getCookie(cookieName) || false;


    const emailHandle = e => {
        setEmail(email => e.target.value);
    };

    const passwordHandle = e => {
        setPassword(password => e.target.value);
    };

    const checkPassword = pass => {
        return (pass.length >= 4);
    }

    const togglePassword = () => {
        visibilityRef.current.classList.toggle('hidden');
        visibilityOffRef.current.classList.toggle('hidden');
        inputRef.current.type = inputRef.current.type === "password" ? "text" : "password"
    }

    const submitHandle = async e => {
        e.preventDefault();

        //tüm hataları sıfırla
        passwordErrorRef.current.innerText = '';
        emailErrorRef.current.innerText = '';

        const emailStatus = validateEmail(email);
        const passwordStatus = checkPassword(password);

        if (emailStatus && passwordStatus) {

            setLoading(loading => true)
            const result = await EmployeeLogin(email, password);

            result.status && (
                setLoading(loading => false)
            )

            //Giriş başarılı ise
            if (result.status === 200) {
                const token = result.content;
                const tokenInformations = token && decodeToken(token);
                const permission = checkPermission(loginAuthorityRoles, +tokenInformations.EmployeeTypeId);

                // Giriş yapan kullanıcının sistem için yetkisi varsa login işlemini yapıyoruz.
                // Yetkisi yok ise 'Sisteme giriş için yetkiniz yok' hatası fırlatıyoruz.
                if(permission) {
                    // Login işlemini gerçekleştir
                    dispatch(employeeLogin({ token }));
                    navigate("/dashboard");
                } else {
                    showTopAlert(strings.errors.not_authorized_for_system, "error");
                }
            } else {
                //Giriş başarısız ise
                showTopAlert(strings.errors.an_error_occurred, "error");
            }

        } else {

            // hata varsa ekrana yaz
            passwordErrorRef.current.innerText = !passwordStatus ? strings.errors.password_error : '';
            emailErrorRef.current.innerText = !emailStatus ? strings.errors.email_error : '';
        }
    }

    //login kontrol
    useEffect(() => {
        if (token) {
            navigate(url("dashboard"))
        }
    }, []);

    return (
        <>
            <form className="w-full flex justify-center" onSubmit={submitHandle}>
                <div
                    className="w-[400px] animate-fadeIn p-[25px] md:py-[60px] rounded-[5px] block bg-white border border-[#ebecec] shadow-form mx-5 mt-5 md:mt-0">
                    <h3 className="font-bold text-center text-[19px] md:mb-[25px]">{strings.auth.login.title}</h3>
                    <div className="login-form">
                        <div className="flex flex-col px-[10px] pb-2">
                            <label className="text-[85%]" htmlFor="email" >{strings.auth.login.your_email}</label>
                            <input id="email" type="text" className="form-input" name="email" onChange={emailHandle} />
                            <span ref={emailErrorRef} className="text-danger field-validation-valid"></span>
                        </div>
                        <div className="flex flex-col px-[10px] pt-[5px] relative">
                            <label className="text-[85%]" htmlFor="password">{strings.auth.login.your_password}</label>
                            <input ref={inputRef} id="password" type="password" className="form-input pr-10" name="password" onChange={passwordHandle} />
                            <span ref={visibilityRef} onClick={togglePassword}
                                className="toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[43px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility
                            </span>
                            <span ref={visibilityOffRef} onClick={togglePassword}
                                className="hidden toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[43px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility_off
                            </span>
                            <span ref={passwordErrorRef} className="text-danger field-validation-valid" ></span>
                        </div>
                        <div className="px-[10px] pt-[10px] md:pt-[25px]">
                            <button type="submit" disabled = {loading} className={`${loading ? "!opacity-[70%] cursor-progress" : ""} btn btn-primary w-full`}>
                                <div>
                                    {loading ? 
                                    <div className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div> 
                                    : strings.auth.login.login_button}
                                </div>
                            </button>
                        </div>
                        {/*<div className="flex justify-center px-3 pb-2 pt-4">*/}
                        {/*    <Link to={url("auth.forgotpassword")} type="button" className="text-blue hover:underline">{strings.auth.login.forgot_password}</Link>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </form>
        </>
    )
}

export default Login