import React, {useEffect, useState} from "react";
import {useLocalization} from "../../hooks/useLocalization";
import useAuth from "../../hooks/useAuth";
import {useSelector} from "react-redux";
import {Loading, NoRecordsFound, PageTitle, showTopAlert} from "../../components";
import {addTimeZone, calculateEndTime, convertToAMPMFormat} from "../../components/Utility";
import {getButtonPermission, SquareButtonWithoutLink} from "../../components/Buttons";
import NotAuthorized from "../../components/NotAuthorized";
import {DeleteBreak, GetAllTeacherBlockList} from "../../services/Teacher/ScheduleService";
import {GetTeacherNameByTeacherId} from "../../services/Teacher/TeacherService";

const TeacherBlocks = () => {

    const strings = useLocalization();
    const { checkPermission, getPermission, employeeTypeId } = useAuth();
    const timeZone = useSelector(state => state.timeZoneStore.timeZone);

    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);

    const timeZoneRoles = getButtonPermission({group: "timeZone", name: "timeZoneTR"});
    const timeZonePermission = checkPermission(timeZoneRoles, employeeTypeId);

    const [loading, setLoading] = useState(false);
    const [blockData, setBlockData] = useState([]);
    const [teacherNameArr, setTeacherNameArr] = useState([])

    const getAllTeacherBlockList = async () => {

        setLoading(loading => true);

        const result = await GetAllTeacherBlockList();
        let tmpTeacherIds =[];


        if(result.status === 200) {
            let resultContent = JSON.parse(result.content);

            resultContent.map((block) => {
                tmpTeacherIds.push(block.teacherId);
            })

            let uniqueTeacherIds = new Set(tmpTeacherIds);
            let uniqueTeacherIdsArray = Array.from(uniqueTeacherIds);

            uniqueTeacherIdsArray.map((id) => {
                GetTeacherNameByTeacherId(id)
                    .then(
                        response => {
                            if(response.status === 200){
                                setTeacherNameArr( teacherNameArr => [...teacherNameArr, {
                                    id: id,
                                    name: response.content
                                }])
                            }
                        }
                    );
            })

            setBlockData(blockData => resultContent);
            setLoading(loading => false);
        }

    }

    const deleteBlock = async (block) => {

        let [hour, minute] = block?.blockTime.split(':');

        const model = {
            teacherId: parseInt(block.teacherId),
            beginTime: `${hour}:${minute}`,
            endTime: calculateEndTime(block?.blockTime, block?.duration),
            day: block.day,
            status: block?.blockType,
            duration: block?.duration,
            blockTypeStatus: block?.blockType
        }

        const result = await DeleteBreak(model);

        if(result.status === 200){
            showTopAlert(strings.teacher.add_break.block_delete_success_message, "success");
            getAllTeacherBlockList();
        } else {
            showTopAlert(strings.errors.an_error_occurred, "error");
        }
    }

    useEffect(() => {
        permission && getAllTeacherBlockList();
    }, [permission]);

    return(
        <>
            <PageTitle title={strings.sidebar.teacher.teacher_blocks}/>

            {permission && (
                <div className="mx-auto px-4 my-4">
                    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                        <div className="inline-block min-w-full rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal text-header">
                                <thead>
                                <tr>
                                    <th className="th sm:w-[20%] min-w-max">{strings.table.teacher_name}</th>
                                    <th className="th sm:w-[20%] min-w-max">{strings.table.block_begin_time}</th>
                                    <th className="th sm:w-[20%] min-w-max">{strings.table.block_duration}</th>
                                    <th className="th sm:w-[20%] min-w-max">{strings.table.operation}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {loading && (
                                    <tr>
                                        <td colSpan="5">
                                            <Loading />
                                        </td>
                                    </tr>
                                )}
                                {!loading && (
                                    <>
                                        { (blockData.length > 0) ?
                                            (
                                                blockData.map((block, index) => {
                                                    if (block.duration !== 0) {
                                                        return (
                                                            <tr key={index} className="bg-white hover:bg-[#f0f0f0]">
                                                                <td className="td align-middle">
                                                                    <p className="min-w-max">{teacherNameArr?.find(teacher => teacher.id === block.teacherId)?.name}</p>
                                                                </td>
                                                                <td className="td align-middle">
                                                                    <div className="flex items-center">
                                                                        <p className="min-w-max">{convertToAMPMFormat(addTimeZone(block.blockTime, timeZone), timeZonePermission)}</p>
                                                                    </div>
                                                                </td>
                                                                <td className="td align-middle">
                                                                    <div className="flex items-center">
                                                                        <p className="min-w-max">{block.duration} {strings.table.minutes}</p>
                                                                    </div>
                                                                </td>
                                                                <td className="td align-middle">
                                                                    <SquareButtonWithoutLink
                                                                        classnames="bg-red"
                                                                        tooltip={strings.table.remove_block}
                                                                        iconName="delete"
                                                                        action={() => {
                                                                            deleteBlock(block);
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                })

                                            )
                                            :
                                            (
                                                <tr className="bg-white hover:bg-[#f0f0f0]">
                                                    <td colSpan="5">
                                                        <NoRecordsFound />
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            {!permission && (
                <NotAuthorized />
            )}
        </>
    )
}

export default TeacherBlocks;