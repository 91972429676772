import { getCookie } from 'ko-basic-cookie';
import { cookieName, EmployeeStatusType } from '../components/Constants';
import { decodeToken } from '../components/Utility';
import { useLocation } from 'react-router-dom';
import routes from '../routes/routes';

const useAuth = () => {

    const token = getCookie(cookieName);
    const employee = token && decodeToken(token);
    const employeeType = Object.keys(EmployeeStatusType).find(key => EmployeeStatusType[key] === parseInt(employee.EmployeeTypeId));
    const employeeTypeId = parseInt(employee.EmployeeTypeId);
    const IsEmployeeTeamLead = employee.IsEmployeeTeamLead === "True" ? true : false;

    const location = useLocation();

    const getPermission = () => {
        let lastRoute, roles;
        const path = location.pathname.replace(/\//, '');
        path.split('/').map(p => {
            if (!lastRoute) {
                lastRoute = routes.find(r => r.name === p);
                roles = lastRoute.roles;
            } else {
                //Eğer path id içermiyorsa route objesini kontrol ediyoruz çünkü path name'i id ile eşleşmediğinden hata veriyor.
                const isIncludeId = lastRoute.path.split("/").find(lr => lr.includes(":"));

                if(!isIncludeId) {
                    lastRoute = lastRoute.children.find(r => r.name === p);
                    roles = lastRoute.roles;
                }
            }
        })

        return roles;
    }

    const checkPermission = (roles, employeeTypeId) => {
        let pathRoles = roles.length > 0 ? roles.some(role => role === employeeTypeId) : true;
        return pathRoles;
    }

    return { employee, employeeType, employeeTypeId, IsEmployeeTeamLead, token, getPermission, checkPermission }
}

export default useAuth