import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { DetailSearchLayout, Dropdown } from '../../../../components'
import { TeacherStatus } from '../../../../components/Constants';
import { useLocalization } from '../../../../hooks/useLocalization';

const TeacherDetailSearch = (props) => {

    const { changeDetail, loading } = props;

    const strings = useLocalization();
    const language = useSelector(state => state.localizationStore.language);

    const firstTeacherStatus = { id: -1, name: strings.form_info.choose_status };
    const [teacherStatus, setTeacherStatus] = useState({id:1, name: strings.form_info.active});

    const [teacherStatusData, setTeacherStatusData] = useState([]);

    const firstVisit = useRef(true);

    useEffect(() => {
        //Sayfa ilk kez yüklenmiyorsa işlemi yap
        //Servise birden çok gez gitmesini engelliyoruz
        if (!firstVisit.current) {
            changeDetail?.({ status: teacherStatus?.id });
            firstVisit.current = false;
        }
    }, [teacherStatus])

    useEffect(() => {
        //Statik datalar dile göre kontrol yapılıp state'ler dolduruluyor.
        let tmpTeacherStatusData = [firstTeacherStatus, ...TeacherStatus.map((status) => language === 'en' ? { id: status.id, name: status.eng } : { id: status.id, name: status.name })];
        setTeacherStatusData(teacherStatusData => tmpTeacherStatusData);

        //Sayfa yüklendikten sonra false yaparak her değişiklikte
        //servisin ziyaret edilmesini sağlıyoruz
        firstVisit.current = false;
    }, []);

    return (
        <>
            <DetailSearchLayout
                isDisabled={loading}
                children={
                    <div className="!flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap">
                        <Dropdown
                            title={strings.detail_search_titles.teachers}
                            data={teacherStatusData}
                            selectedData={teacherStatus}
                            setSelectedData={setTeacherStatus}
                            isDisabled={loading}
                        />
                    </div>
                }
            />
        </>
    )
}

export default TeacherDetailSearch