/*
Sayfalara ve sayfalardaki buton gruplarına göre roller tanımlanıyor.
Buradaki değerlere göre kullanıcının yetkisi var ya da yok durumlarına
göre buton aktif/pasif durumu belirleniyor.
*/
export const buttonRights = [
    {
        group: "teacherLogin",
        buttons: [
            //Yönetici, Yazılımcı, Eğitmen Supervisor, Eğitmen İnsan Kaynakları
            { name: "login", roles: [2, 3, 22, 23] },
        ],
    },
    {
        group: "dashboardAuthCheck",
        buttons: [
            { name: "dashboard", roles: [2, 3, 22, 23] },
        ]
    },
    {
        group: "teacherList",
        buttons: [
            { name: "calendar", roles: [2, 3, 22] },
            { name: "detail", roles: [2, 3, 22] },
            { name: "moveTeacher", roles: [2, 3, 22] },
            { name: "list", roles: [2, 3] },
        ]
    },
    {
        group: "giftList",
        buttons: [
            { name: "giftOperations", roles: [2, 3, 22] },
        ]
    },
    {
        group: "timeZone",
        buttons: [
            { name: "timeZoneTR", roles: [2, 3] }
        ]
    }
]
