import React from "react";
import { Outlet } from "react-router-dom";
import { LoginIsometric } from "../../assets/img";
import { LogoBeyaz } from "../../assets/svg";

const AuthLayout = () => {
  return (
    <>
      <div className="min-h-full sm:h-full flex flex-col md:flex-row relative overflow-hidden">
        <div className="left-login">
          <img src={LoginIsometric} width="650" alt="Login Isometric" />
          <img
            className="mb-5 md:mb-0"
            src={LogoBeyaz}
            width="190"
            alt="Clickivo Prime Logo"
          />
        </div>

        {/* <!-- Right --> */}
        <div className="right-login">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
