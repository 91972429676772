import React, {useEffect, useState} from 'react'
import { Modal } from '../../../../components';
import MemberTermDetailModal from './MemberTermDetailModal';
import {useLocalization} from "../../../../hooks/useLocalization";

const ActiveLesson = (props) => {

  const { data, teacherId, sendRequestToEndpoint } = props;
  const strings = useLocalization();

  const [modal, setModal] = useState(false);

  const closeModal = () => {
    setModal(modal => false);
    sendRequestToEndpoint();
  }
  
  return (
    <>
        <div className='active-lesson  min-h-[60px] -m-[1px] py-1 flex gap-1'>
            {data && (
                <>
                    <button
                        onClick={() => setModal(true)}
                        className="flex-shrink-0 group inline-flex items-center justify-center bg-transparent border border-white bg-card text-base-text search-button w-5 h-5 rounded-[3px] relative"
                    >
                        <span className="material-symbols-outlined text-white text-[14px]">visibility</span>
                    </button>
                    <div className='flex flex-col justify-center ml-1'>
                        <p>{data?.studentNo}</p>
                        <p>{strings.form_info.student} {data?.fullName.split(" ")[0] ?? data?.fullName}</p>
                    </div>
                </>
            )}
        </div>
      <Modal modal={modal} setModal={setModal} classes={{ modal: "h-[90vh] max-w-[1440px]" }} >
        <MemberTermDetailModal data={data} teacherId={teacherId} closeModal={closeModal} />
      </Modal>
    </>
  )
};

export default ActiveLesson;