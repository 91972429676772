import {NoRecordsFound} from "../../../../components";
import React from "react";
import {useLocalization} from "../../../../hooks/useLocalization";
import StudentListTableRow from "./StudentListTableRow";

const StudentListTable = ({ data, sendRequestToEndpoint, isFirstRequest }) => {

    const strings = useLocalization();

    return(
        <>
            {isFirstRequest && (
                <div className="mx-auto px-4 my-4">
                    <div
                        className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                        <div className="inline-block min-w-full rounded-lg overflow-hidden">
                            <table className="min-w-full leading-normal text-header">
                                <thead>
                                    <tr>
                                        <th className="th"><p className="min-w-max">{strings.table.student_name}</p></th>
                                        <th className="th"><p className="min-w-max">{strings.table.teacher_name}</p></th>
                                        <th className="th"><p className="min-w-max">{strings.table.lesson_days}</p></th>
                                        <th className="th"><p className="min-w-max">{strings.table.begin_time}</p></th>
                                        <th className="th"><p className="min-w-max">{strings.table.lesson_duration}</p></th>
                                        <th className="th"><p className="min-w-max">{strings.table.operation}</p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 && (
                                        data.map((student, index) => (
                                            <StudentListTableRow
                                                key={index}
                                                student={student}
                                                sendRequestToEndpoint={sendRequestToEndpoint}
                                            />
                                        ))
                                    )}

                                    {data.length === 0 && (
                                        <tr className="bg-white hover:bg-[#f0f0f0]">
                                            <td colSpan="6">
                                                <NoRecordsFound/>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}

            {!isFirstRequest && (
                <div className="py-4 min-w-full text-center">{strings.table.filter_for_result}</div>
            )}
        </>
    )
}

export default StudentListTable;