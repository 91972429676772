export const English = {
    alert: {
        title: {
            information: "Information Message"
        },
        centered_alert_two_buttons: {
            title: "Are you sure?",
            title_confirm: "Transaction Confirmation",
            confirm_text: "Okay",
            cancel_text: "Cancel",
            save_question: "Do you want to save the changes?",
            irreversible_action: "This transaction is irreversible."
        },
        top_alert: {
            inform: "Please enter complete information!",
            success: "Transaction completed successfully!"
        }
    },
    form_info: {
        student: "Student",
        full_name: 'Full Name',
        full_name_upper: 'FULL NAME',
        name: 'First Name',
        surname: 'Last Name',
        email: 'Email Address',
        other_email: 'Other Email Address',
        corporate_email: 'Corporate Email Address',
        phone_number: 'Phone Number',
        choose_brand: 'Select Brand',
        choose_gender: 'Select Gender',
        choose_status: 'Select Status',
        choose_teacher: 'Select Teacher',
        choose_marital_status: 'Select Marital Status',
        choose_lesson_duration: 'Select Lesson Duration',
        choose_lesson_days: 'Select Lesson Days',
        job: 'Occupation',
        choose_job: 'Select Occupation',
        birth_date: 'Birth Year',
        birth_date_upper: 'Birth Year',
        birth_place: 'State',
        choose_state: "Select State",
        birth_place_upper: 'PLACE OF BIRTH',
        gender: 'Gender',
        gender_upper: 'GENDER',
        date_placeholder: 'dd/mm/yyyy',
        gift_date_placeholder: 'YYYYmmDD',
        profile_photo: 'Profile Picture',
        work_start_date: 'Start Date of Employment',
        work_start_date_upper: 'START DATE OF EMPLOYMENT',
        end_date: 'End Date of Employment',
        work_end_date_upper: 'END DATE OF EMPLOYMENT',
        status: 'Status',
        status_upper: 'STATUS',
        marital_status: 'Marital Status',
        marital_status_upper: 'MARITAL STATUS',
        image_name: 'Profile Photo Name',
        image_name_upper: 'PROFILE PHOTO NAME',
        sound_name: "Sound Record Name",
        sound_name_upper: "SOUND RECORD NAME",
        tooltip_profile: "Example Profile Photo Name: 8051.profile.jpg",
        tooltip_sound: "Example Sound Record Name: 1029.webm.mp3",
        university: 'Institute',
        university_upper: 'INSTITUTE',
        department: 'Degree',
        department_upper: 'DEGREE',
        about: 'About',
        basic_information: "Basic Information",
        teacher_id_upper: 'INSTRUCTOR ID',
        about_upper: 'ABOUT',
        days: 'Days',
        start_end_date: 'Start-End Date',
        start_end_date_hour: 'Start-End Time',
        start_date: 'Start Date',
        yes: 'Yes',
        no: 'No',
        description: 'Description',
        active: 'Active',
        inactive: 'Inactive',
        removed: 'Removed',
        title: 'Title',
        city: 'City',
        male: 'Male',
        female: 'Female',
        undefined: 'Not Specified',
        validity_date: 'Expiration Date'
    },
    count: '<span class="font-bold">#count#</span> number of records found.',
    detail_search_titles: {
        detail_search: 'Advanced Search',
        students: 'All Students',
        teachers: 'All Teachers',
        status: 'All Statuses',
        begin_end_hours: 'All Start / End Times',
        begin_time: 'Start Time',
        end_time: 'End Time',
        choose_all: 'Select All',
        minutes: 'All Minutes',
        discounts: 'All Discounts',
        week_days: 'All Days',
        search_teacher: 'Search for Teacher',
        selected_data_count: 'Selected Data Count'
    },
    button: {
        save: 'Save',
        save_upper: 'SAVE',
        cancel: 'Cancel',
        cancel_upper: 'CANCEL',
        confirm: 'Confirm',
        confirm_upper: 'CONFIRM',
        create: 'Create',
        clear: 'Clear',
        update: 'Update',
        update_upper: 'UPDATE',
        add: 'Add',
        add_upper: 'ADD',
        delete: 'Delete',
        undo: 'Undo',
        send: 'Send',
        list: 'List',
        browse: "Browse",
        choose: 'Select',
        move: "Move",
        choose_upper: 'SELECT',
        list_upper: 'LIST',
        edit: 'Edit',
        continue: 'Continue',
        delivered: "Delivered"
    },
    search_bar: {
        search: 'Search',
        clear: 'Clear',
        search_student: 'Enter the student number to search'
    },
    pagination: {
        previous: 'Back',
        next: 'Next'
    },
    table: {
        profile_photo: 'Profile Picture',
        profile: 'Profile',
        employee_informations: 'User Information',
        corparate_informations: 'Corporate Information',
        teacher_informations: 'Teacher Information',
        working_hours: 'Working Hours',
        working_informations: 'Working Information',
        date: 'Date',
        teacher_name: "Teacher Name",
        student_name: "Student Name",
        lesson_duration: "Lesson Duration",
        begin_time: "Lesson Begin Time",
        lesson_days: "Lesson Days",
        operation: 'Transaction',
        hour: 'Time',
        duration: 'Duration',
        minute: 'Minute',
        minutes: 'Minutes',
        block_day: 'Block Day',
        block_begin_time: 'Block Begin Time',
        block_duration: 'Block Duration',
        remove_block: 'Remove Block',
        rating: 'Rating',
        reset_order: 'Reset Order',
        order_descending: 'Order Descending',
        order_ascending: 'Order Ascending',
        filter_for_result: 'Filter to View Records'
    },
    errors: {
        // General errors
        title: "Error",
        an_error_occurred: "Operation failed!",
        an_error_occured_refresh_page: "Operation failed! Please refresh page.",
        not_authorized: "You are not authorized!",
        no_records_found: "No Record Found",
        not_authorized_for_system: "You are not authorized to login to the system!",
        not_authorized_for_this_page: "You are not authorized to access this page!",
        not_allowed: "Closed for Operation",
        it_is_not_specified: "Not Specified",
        it_is_not_specified_upper: 'NOT SPECIFIED',
        error404_title: "Page not found",
        error404_content: "The page you are looking for cannot be found. You can access any page you want using the menus.",
        password_dont_match_error: "The passwords you entered do not match.",
        two_hour_rule_error: "The teacher couldn't set to passive because of students who didn't follow the 2-hour rule.(2-hour rule compliant students moved to Teacher Unknown.)",
        // Required field errors
        email_error: "Email address field is required!",
        code_error: "Verification code field is required!",
        password_error: "Password field is required!",
        passwords_cannot_be_different: "Passwords must match!",
        birth_date_format_error: "The birth year field must be 4 characters",
        // Format errors
        email_format_error: "Email format is not valid.",
        phone_number_format_error: "Phone number format is not valid.",
        identity_number_format_error: 'The format of the ID number is incorrect.',
        // Cannot be left blank errors
        general_null_error: 'This field cannot be left blank',
        teacher_id_null_error: 'The teacher id field cannot be left blank.',
        full_name_null_error: "The name and surname field cannot be left blank.",
        birth_date_null_error: 'The date of birth field cannot be left blank.',
        gender_null_error: 'The gender field cannot be left blank.',
        start_date_null_error: 'The start date field cannot be left blank.',
        teacher_null_error: 'The instructor field cannot be left blank.',
        week_days_null_error: 'The day selection field cannot be left blank.',
        time_range_null_error: 'The time range field cannot be left blank.',
        minute_null_error: 'The minute field cannot be left blank.',
        status_null_error: 'The status information field cannot be left blank.',
        interest_null_error: 'The interests field cannot be left blank.',
        password_null_error : 'The password field cannot be left blank.',
        password_again_null_error : 'The password(again) field cannot be left blank.',
        // Service errors
        esd_time_frame_error: 'No action can be taken with less than 2 hours left to class time!',
        esd_two_hour_error: "There is one active lesson for today. You can change the time 2 hours after your lesson time."
    },
    loading: "Loading",
    sidebar: {
        home_page: "Home",
        profile: {
            my_profile: "My Profile",
            change_password: "Change Password",
            logout: "Logout"
        },
        teacher: {
            title: "Teacher Operations",
            list: "Teachers",
            add_teacher: "Add Teacher",
            teacher_program: "Teacher Schedule",
            teacher_blocks: "Teacher Blocks"
        },
        gift: {
            gift_title: "Gift Operations",
            gift_list: "Gift List"
        },
        student: {
            title: "Student Operations",
            student_list: "Students"
        }
    },
    navbar: {
        search: "Search...",
    },
    auth: {
        login: {
            title: "Login",
            your_email: "Email Address",
            your_password: "Password",
            your_password_again: "Password (Again)",
            forgot_password: "Forgot Password",
            login_button: "Login",
        },
        forgot_password: {
            title: "Forgot Password",
            your_email: "Corporate Email Address",
            button: "Send Verification Code",
            login: "Login",
        },
        verify_code: {
            title: "Forgot Password",
            your_code: "Enter Verification Code",
            button: "Check",
            login: "Login",
        },
        update_password: {
            title: "Change Password",
            your_password: "New Password",
            your_password2: "New Password (Again)",
            button: "Change My Password",
            login: "Login",
            success: {
                title: "Operation Successful",
                message: "Your password has been successfully changed.",
            }
        },
    },
    dashboard: {
        welcome: "Welcome"
    },
    teacher: {
        teacher_title: 'Teacher',
        teacher_list: {
            search_text: 'Enter the teacher name to search',
            detail: 'Detail',
            education_program: 'Teacher Schedule',
            start_date_upper: 'START DATE',
            end_date_upper: 'END DATE',
            move_students: 'Move Students',
        },
        teacher_program: {
            lesson_text: '<span class=\"text-blue\">Lesson</span>',
            empty_text: '<span class=\"text-base-text\">Available</span>',
            blocked_text: '<span class=\"text-warning\">Blocked</span>',
            holiday_text: '<span class=\"text-[#6861ce]\">Vacation</span>',
            temporary_blocked_text: '<span class=\"text-red\">Temporary Block</span>',
            missed_call_text: '<span class=\"text-danger\">Missed Lesson</span>',
            missed_lesson: 'Missed Lesson',
            postponed: 'Postponed',
            lesson_blocked: 'This time range of the instructor will be blocked until you remove it.',
            lesson_temprorary_blocked: 'This time range of the instructor will be blocked only until #lessonDay#.',
            blocked: 'Block',
            temporary_blocked: 'Temporary Block',
            blocked_temprorary_blocked: 'Block / Unblock',
            weekly_lesson_count: 'Weekly Lesson Count',
            lesson_time: 'Lesson Duration',
            remaining_lesson_count: 'Remaining Lesson Count',
            select_teacher_to_see: 'Select an Teacher to View the Teacher Program',
            shift_not_found: 'No Working Hours Found For This Teacher',
            days: {
                monday: 'Monday',
                tuesday: 'Tuesday',
                wednesday: 'Wednesday',
                thursday: 'Thursday',
                friday: 'Friday',
                saturday: 'Saturday',
                sunday: 'Sunday'
            }
        },
        create_teacher: {
            personal_informations: 'Personal Information',
            working_hours: 'Working Hours',
            call_address: 'Contact Addresses',
            areas_of_interest: 'Interest(s)',
            areas_of_personals: 'Personal(s)',
            create_teacher_success_message: 'Teacher creation completed successfully!',
            create_interests_success_message: 'Interests successfully added!',
            create_shift_success_message: 'Working hours successfully added!',
            select_start_end_hour: 'Start / End Time',
            delete_shift_success_message: 'Working hours successfully deleted!',
            sound_record: 'Sound Record'
        },
        update_teacher: {
            teacher_update_success_message: 'Teacher successfully updated!',
            update_teacher: 'Update Teacher',
            areas_of_interests_success_message: 'Interests were successfully updated!',
            update_shift_success_message: 'Working hours were successfully updated!',
            shift_not_found: 'Working Hours Not Found',
            add_new_shift: 'Add New Working Hours',
        },
        detail: {
            teacher_details: "Teacher Details"
        },
        move_student: {
            move_students: 'Move Students',
            students: 'Students',
            search_student: "Search Student",
            student_moved_success_message: 'Student #studentName# moved successfully!',
            moved_success_message: "Student #studentName# moved to #teacherName# successfully!",
            students_moved_success_message: 'Students moved successfully!',
            avaliable_teacher_not_found: 'No available instructor found!',
        },
        add_break: {
            block_success_message: 'Working hours successfully blocked!',
            temporary_block_success_message: 'Working hours temporarily blocked!',
            block_delete_confirm_question: 'Are you sure you want to delete the block?',
            temporary_block_delete_confirm_question: 'Are you sure you want to delete the temporary block?',
            block_delete_success_message: 'Block successfully deleted!',
            temporary_block_delete_success_message: 'Temporary block successfully deleted!',
        }
    },
    gift: {
        gift_list: {
            student: "Student",
            gift: "Gift",
            price: "Price",
            date_range: "Date Range",
            all_list: "All List",
            incoming_gift_requests: "Incoming Gift Requests",
            gift_delivered_success_message: "Gift successfully delivered!",
            image_not_found: "Image Not Found"
        }
    }
}