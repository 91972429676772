import React, {forwardRef, useEffect, useRef, useState} from "react";
import {Dropdown, showTopAlert, TimeRangePicker} from "../../../../components";
import {useLocalization} from "../../../../hooks/useLocalization";
import usePrimeData from "../../../../hooks/usePrimeData";
import {useSelector} from "react-redux";
import {GeneralStatus} from "../../../../components/Constants";
import {AddShift} from "../../../../services/Teacher/ShiftService";
import classNames from "classnames";

const AddShiftRow = forwardRef((props, ref) => {

  const strings = useLocalization();
  const { sendRequestToEndpoint, teacherId, classnames, isDisabled = false } = props;

  const classname = typeof classnames === "undefined" ? '' : classnames;

  const language = useSelector((state) => state.localizationStore.language);
  const primeData = usePrimeData();

  const timeRangeErrorRef = useRef();
  const statusErrorRef = useRef();

  const firstBeginTime = { id: -1, name: strings.detail_search_titles.begin_time };
  const [beginTime, setBeginTime] = useState(firstBeginTime);

  const firstEndTime = { id: -1, name: strings.detail_search_titles.end_time };
  const [endTime, setEndTime] = useState(firstEndTime);

  const firstStatus = { id: -1, name: strings.form_info.choose_status };
  const [status, setStatus] = useState(firstStatus);
  const [statusData, setStatusData] = useState([]);

  const clearShiftRow = () => {
    setBeginTime(beginTime => firstBeginTime);
    setEndTime(endTime => firstEndTime);
    setStatus(status => firstStatus);
  }

  const createShift = async () => {
    let valid = true;

    timeRangeErrorRef.current.innerText = "";
    statusErrorRef.current.innerText = "";

    if (beginTime.id === -1 || endTime.id === -1) {
      valid = false;
      timeRangeErrorRef.current.innerText =
        strings.errors.time_range_null_error;
    }
    if (status.id === -1) {
      valid = false;
      statusErrorRef.current.innerText = strings.errors.status_null_error;
    }

    if (valid) {
      const model = {
        teacherId: teacherId,
        beginTime: beginTime.id,
        endTime: endTime.id,
        days: [1,2,3,4,5],
        status: status.id,
      };

      const result = await AddShift(model);

      if (result.status === 200) {
        ref.current.focus();
        sendRequestToEndpoint();
        clearShiftRow();
        showTopAlert(strings.teacher.create_teacher.create_shift_success_message, "success");
      } else {
        showTopAlert(result.content, "error");
      }
    }
  };
  
  useEffect(() => {
    let tmpStatusData = [firstStatus, ...GeneralStatus.map((status) => language === 'en' ? { id: status.id, name: status.eng } : { id: status.id, name: status.name })];
    setStatusData(statusData => tmpStatusData);
  }, []);

  return (
    <div className={`flex flex-col xl:flex-row pb-10 justify-start items-center xl:items-start ${classname}`}>
      <div className="flex flex-col px-[10px] pb-0 mt-5 w-full">
        <label className="font-bold mb-2">
          {strings.teacher.create_teacher.select_start_end_hour}
        </label>
        <TimeRangePicker
          rangeList={primeData.workingTimeList}
          startHour={beginTime}
          setStartHour={setBeginTime}
          endHour={endTime}
          setEndHour={setEndTime}
          heightClassnames="!max-h-[150px]"
          dropdownClassnames="lg:w-full md:max-w-full"
          isDisabled={isDisabled}
        />
        <span
          ref={timeRangeErrorRef}
          className=" text-danger field-validation-valid px-[10px]"
        ></span>
      </div>
      <div className="flex flex-col px-[10px] pb-0 mt-5 w-full">
        <label className="font-bold mb-2">{strings.form_info.status}</label>
        <div className="flex gap-5 xs:gap-10">
          <Dropdown
            data={statusData}
            selectedData={status}
            setSelectedData={setStatus}
            classnames="md:max-w-full"
            isDisabled={isDisabled}
          />
        </div>
        <span
          ref={statusErrorRef}
          className="text-danger field-validation-valid px-[10px]"
        ></span>
      </div>

      <button
          className={classNames("flex-shrink-0 xl:mb-2 mt-2 group inline-flex items-center justify-center bg-transparent border border-blue text-blue w-8 h-8 rounded-[3px] relative xl:mt-[52px]", {
            "opacity-70 cursor-default": isDisabled,
            "cursor-pointer": !isDisabled
          })}
          disabled={isDisabled}
          onClick={() => {
            createShift();
          }}
      >
        <span className="material-symbols-outlined text-[18px]">
          add
        </span>
        {!isDisabled && (
            <div className="tooltip-top">
              <p>{strings.button.add}</p>
            </div>
        )}
      </button>
    </div>
  );
});

export default AddShiftRow;
