import {useLocalization} from "../../../../hooks/useLocalization";
import {GiftListTableRow} from "../index";
import {NoRecordsFound} from "../../../../components";

const GiftListTable = ({ data }) => {

    const strings = useLocalization();

    return (
        <>
            <div className="mx-auto px-4 my-4">
                <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto sm:overflow-x-hidden hover:overflow-x-auto">
                    <div className="inline-block min-w-full rounded-lg overflow-hidden">
                        <table className="min-w-full leading-normal text-header">
                            <thead>
                            <tr>
                                {/*<th className="th "><p className="min-w-max">Id</p></th>*/}
                                <th className="th  min-w-max">{strings.gift.gift_list.student}</th>
                                <th className="th  min-w-max">{strings.gift.gift_list.gift}</th>
                                {/*<th className="th  min-w-max">{strings.gift.gift_list.price}</th>*/}
                                <th className="th min-w-max">{strings.table.operation}</th>
                            </tr>
                            </thead>
                            <tbody>
                            { data.length > 0 ?
                                (
                                    data.map((gift,index) => (
                                        <GiftListTableRow
                                            key={index}
                                            gift={gift}
                                        />
                                    ))
                                )
                                :
                                (
                                    <tr className="bg-white hover:bg-[#f0f0f0]">
                                        <td colSpan="5">
                                            <NoRecordsFound />
                                        </td>
                                    </tr>
                                )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div id="gift-image-modal"></div>
        </>
    )

}

export default GiftListTable;