import { useEffect, useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useLocalization } from "../hooks/useLocalization";
import { useLocation } from "react-router-dom";
import {toggleDropdown, closeDropdown, openDropdown} from "./Toggle";
import { url } from "../routes/utility";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import { useSelector } from "react-redux";

export const Dropdown = (props) => {

  const { selectedData, setSelectedData, classnames, heightClassnames, data, isDisabled, dark, tooltip } = props;
  const strings = useLocalization();
  const language = useSelector(state => state.localizationStore.language);

  const dropdownRef = useRef();
  const dropdownArrowRef = useRef();

  const classname = typeof classnames === "undefined" ? '' : classnames;
  const heightClassname = typeof classnames === "undefined" ? '' : heightClassnames;
  const [selectedDataName, setSelectedDataName] = useState(null);

  //selected data id olarak gönderiliyor ise ilgili id'ye ait verinin adını yazdırır.
  //selected data obje olarak gönderiliyor ise objenin içerisindeki verinin adını yazdırır.
  const getSelectedDataName = () => {
    if (typeof selectedData !== "object") {
      if (data.length > 1) {
        const filteredData = data.filter(data => data.id === selectedData);
        setSelectedDataName(language === 'tr' ? filteredData[0].name : filteredData[0].eng ? filteredData[0].eng : filteredData[0].name);
      } else {
        //Data boş olarak gelirse 'yükleniyor...' yazısını dropdown title'a yazdıracak
        setSelectedDataName(strings.loading);
      }
    } else {
      setSelectedDataName(language === 'tr' ? selectedData.name : selectedData.eng ? selectedData.eng : selectedData.name);
    }
  }

  //isDisabled props'u yoksa default olarak toggleDropdown çalışır
  //isDisabled props'u varsa isDisabledTrue olduğunda çalışır
  const handleDisabled = () => {
    if (!isDisabled || isDisabled === undefined) {
      toggleDropdown(dropdownRef, dropdownArrowRef);
    }
  }

  const handleKeyDown = (event) => {
    const currentIndex = data.findIndex(item => item.id === selectedData.id);

    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        const prevIndex = currentIndex > 0 ? currentIndex - 1 : data.length - 1;
        setSelectedData(data[prevIndex]);
        break;
      case "ArrowDown":
        event.preventDefault();
        const nextIndex = (currentIndex + 1) % data.length;
        openDropdown(dropdownRef, dropdownArrowRef);
        setSelectedData(data[nextIndex]);
        break;
      case "Escape":
        event.preventDefault();
        closeDropdown(dropdownRef, dropdownArrowRef);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getSelectedDataName();
  }, [selectedData, data])

  return (
    <div className={`relative w-full md:max-w-[246px] ${classname}`}>
      <OutsideClickHandler onOutsideClick={() => closeDropdown(dropdownRef, dropdownArrowRef)}>
        <button
          //dropdown'ın pasif olduğu durumdaki css'i kontrol etmek için isDisabled props'u kullanıldı
          className={classNames(`button dropdown md:max-w-[246px] ${(tooltip) ? 'group relative' : ''} ${classname}`, { 'bg-[#f5f5f5] focus:border-[#ebedf2] cursor-not-allowed': isDisabled }, { '!bg-white focus:!border-blue': isDisabled === undefined }, { '!bg-[#e1e1e1]': isDisabled && dark })}
          onClick={handleDisabled}
          onKeyDown={handleKeyDown}
          type="button"
        >
          {selectedDataName === null ? selectedData.name : selectedDataName}
          <div
            ref={dropdownArrowRef}
            className="dropdown-arrow transition-transform duration-300 absolute right-4 top-[calc(50%-2px)]"
          ></div>
          {(tooltip) && (
            <div className="tooltip-top">
              <p>{tooltip}</p>
            </div>
          )}
        </button>

        {/* <!-- Dropdown menu --> */}
        <div
          ref={dropdownRef}
          className={`w-full md:max-w-[246px] ${classname} ${heightClassname} absolute mt-[2px] z-10 bg-white rounded origin-top animate-fadeIn scale-y-0 transition-transform duration-200 shadow-dropdownContent max-h-[250px] overflow-y-scroll sm:overflow-y-hidden sm:overflow-y-scroll-hover`}>
          <ul className="py-1">
            {data &&
              data.map((item, index) => (
                <li key={index}>
                  <div
                    className={`block py-2 px-4 tab-button ${(selectedData.id === item.id || selectedData === item.id) ? 'bg-blue text-white hover:bg-blue hover:text-white search-button' : ''}`}
                    onClick={() => {
                      setSelectedData(item);
                      toggleDropdown(dropdownRef, dropdownArrowRef);
                    }}
                  >
                    <p className="cursor-pointer">{item.name}</p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export const DropdownMultiSelect = (props) => {

  const strings = useLocalization();
  const dropdownRef = useRef();
  const dropdownArrowRef = useRef();
  const searchTextRef = useRef();

  const { selectedData, setSelectedData, data, classnames, title, inputPlaceholder, randomId, isDisabled, uKey, tooltip, showChooseAll, showSearchBar, isDisabledSelectBox, heightClassnames, disableAllExceptSelected } = props;
  //showChooseAll prop'u 'notShow' gelir ise 'Tümünü Seç' butonu gizleniyor.
  //showSearchBar prop'u 'notShow' gelir ise 'Arama Alanı' gizleniyor.

  const classname = typeof classnames === "undefined" ? '' : classnames;
  const heightClassname = typeof classnames === "undefined" ? '' : heightClassnames;
  
  const [oldKey, setOldKey] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [isChooseAll, setIsChooseAll] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  
  //Dropdown içerisindeki arama kısmını konrol eden fonksiyon
  const handleChange = (e) => {
    e.preventDefault();
    showSearchBar !== 'notShow' && setSearchText(searchText => e.target.value);
  };
  
  //Tümünü seç butonunu kontrol eden fonksiyon
  const chooseAll = () => {
    //Tümü seçilmiş veriler kaldırılırken sadece filtrelenmiş veriler listeden selectedData array'inden çıkarılıyor.
    if (isChooseAll) {
      const filtered = selectedData.filter((id) => !filteredData.map((item) => item.id).includes(id))
      setSelectedData(setSelectedData => filtered);
      setIsChooseAll(isChooseAll => false);
    } 
    //Tümünü Seç butonunu çalıştırırken sadece unique olan id'leri selectedData array'ine atıyoruz.
    else {
      setSelectedData((prevSelectedData) => [
        ...new Set([...prevSelectedData, ...filteredData.map((item) => item.id)]),
      ]);
      setIsChooseAll(isChooseAll => true);
    }
  };  
  
  //Dropdown içerisindeki verilerin seçilme durumunu kontrol eden fonksiyon
  const handleChoose = (id) => {
    if (selectedData.includes(id)) {
      setSelectedData(selectedData.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedData([...selectedData, id]);
    }
  };
  
  //Tümünü seç butonunun css kodunu kontrol eden fonksiyon
  const handleAllCheckbox = (status) => {
    if (status) {
      return 'bg-green border border-green text-white'
    } else {
      return 'border border-[#aaaaaa]'
    }
  };
  
  //Dropdown içerisindeki verilerin başında bulunan checkbox butonunun css kodunu kontrol eden fonksiyon
  const handleSelectedCheckbox = (id) => {
    if (selectedData.includes(id)) {
      return 'bg-green border border-green text-white'
    } else {
      return 'border border-[#aaaaaa]'
    }
  };
  
  //isDisabled props'u yoksa default olarak toggleDropdown çalışır
  //isDisabled props'u varsa isDisabledTrue olduğunda çalışır
  const handleDisabled = () => {
    if (!isDisabled || isDisabled === undefined) {
      toggleDropdown(dropdownRef, dropdownArrowRef)
      if (dropdownRef.current?.classList?.contains('scale-y-0')) {
        
        if(showSearchBar !== 'notShow'){
          searchTextRef.current.value = '';
        }
        
        setSearchText('');
        if (data.length !== selectedData.length) {
          setIsChooseAll(false);
        }
      }
    }
  }

  //Arama metni değiştikçe data filtreleniyor.
  useEffect(() => {
    setFilteredData(data.filter(d => d.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())));
    setIsChooseAll(false);
  }, [data, searchText]);

  //'Tümünü Seç' butonunun filtered data'ya göre davranışı kontrol ediliyor.
  //Filtreleme yapılıp tümü seçilen verilerin, tekrar aynı filtreleme yapıldığında seçili kalmasını sağlıyoruz.
  useEffect(() => {
    const isAllFilteredDataSelected = filteredData.every(item => selectedData.includes(item.id));

    if (isAllFilteredDataSelected) {
      setIsChooseAll(true);
    } else {
      setIsChooseAll(false);
    }
  }, [selectedData, filteredData]);

  //Dropdown'a gönderilen veri değiştiğinde dropdown'ı sıfırlar.
  useEffect(() => {
    if (oldKey !== null && oldKey !== uKey) {
      setIsChooseAll(false);
      setSelectedData([]);
    }
    setOldKey(uKey);
  }, [uKey])

  return (
    <>
      <div className={`relative w-full md:max-w-[246px] ${classname}`}>
        <OutsideClickHandler onOutsideClick={() => closeDropdown(dropdownRef, dropdownArrowRef)}>
          <button
            //dropdown'ın pasif olduğu durumdaki css'i kontrol etmek için isDisabled props'u kullanıldı
            className={classNames(`button dropdown md:max-w-[246px] ${(tooltip) ? 'group relative' : ''} ${classname}`, { 'bg-[#f5f5f5] focus:border-[#ebedf2] cursor-not-allowed': isDisabled }, { '!bg-white focus:!border-blue': isDisabled === undefined })}
            onClick={handleDisabled}
            type="button"
          >
            {selectedData.length === 0 ? title : `${strings.detail_search_titles.selected_data_count}: ${selectedData.length}`}
            <div
              ref={dropdownArrowRef}
              className="dropdown-arrow transition-transform duration-300 absolute right-4 top-[calc(50%-2px)]"
            ></div>
            {(tooltip) && (
              <div className="tooltip-top">
                <p>{tooltip}</p>
              </div>
            )}
          </button>
          <div
            ref={dropdownRef}
            className={`w-full md:max-w-[246px] ${classname} ${heightClassname} absolute mt-[2px] z-10 bg-white rounded origin-top animate-fadeIn scale-y-0 transition-transform duration-200 shadow-dropdownContent max-h-[250px] overflow-y-scroll sm:overflow-y-hidden sm:overflow-y-scroll-hover`}
          >
            <ul className="py-1">
              {showSearchBar !== 'notShow' && (
                <li className="p-2">
                  <input
                    type="text"
                    ref={searchTextRef}
                    className="px-2 h-8 border w-full border-input-border outline-none rounded-md placeholder:text-[12px]"
                    placeholder={inputPlaceholder}
                    onChange={handleChange}
                  />
                </li>
              )}
              {filteredData.length > 0 ? (
                <>
                  {showChooseAll !== 'notShow' && (
                    <li>
                      <div className="block py-2 px-4 tab-button">
                        <div className="flex items-center absolute ">
                          <input
                            id={randomId}
                            type="checkbox"
                            className="hidden peer"
                            onClick={() => {
                              setIsChooseAll(!isChooseAll);
                              chooseAll();
                            }}
                          />
                          <label
                            htmlFor={randomId}
                            className={`${handleAllCheckbox(isChooseAll)} mr-2 w-4 h-4 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                          >
                            <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !isChooseAll })}>
                              done
                            </span>
                          </label>
                        </div>
                        <p className="pl-6 font-bold">{strings.detail_search_titles.choose_all}</p>
                      </div>
                    </li>
                  )}
                  {filteredData.map((item, index) => {
                    const isDisabledExceptSelected = disableAllExceptSelected && selectedData.length === 1 && !selectedData.includes(item.id);
                    const isDisabled = isDisabledSelectBox || isDisabledExceptSelected;
                    return (
                      <li key={index}>
                        <div
                          className="block py-2 px-4 tab-button"
                        >
                          <div className="flex items-center absolute ">
                            <input
                              id={`${randomId}_${index}`}
                              type="checkbox"
                              className="hidden peer"
                              disabled={isDisabled}
                              onChange={() => {  
                               !isDisabled && handleChoose(item.id)
                              }}
                            />
                            <label
                              htmlFor={`${randomId}_${index}`}
                              className={`${handleSelectedCheckbox(item.id)} ${isDisabled ? '!cursor-not-allowed opacity-70' : ''} mr-2 w-4 h-4 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                            >
                              <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !selectedData.includes(item.id) }, { 'cursor-not-allowed opacity-70' : isDisabled})}>
                                done
                              </span>
                            </label>
                          </div>
                          <label htmlFor={`${randomId}_${index}`} className="cursor-pointer">
                            <p className="pl-6">{item.name}</p>
                          </label>
                        </div>
                      </li>
                    )
                  })}
                </>
              ) : (
                <li>
                  <div
                    className="flex justify-center py-2 px-5 tab-button"
                  >
                    <p>{strings.errors.no_records_found}</p>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};


export const DropdownSearch = (props) => {

  const strings = useLocalization();
  const language = useSelector(state => state.localizationStore.language);

  const dropdownRef = useRef();
  const dropdownArrowRef = useRef();
  const inputRef = useRef();

  const { selectedData, setSelectedData, classnames, data, inputPlaceholder, heightClassnames, isDisabled, dark, tooltip } = props;
  const classname = typeof classnames === "undefined" ? '' : classnames;
  const heightClassname = typeof heightClassnames === "undefined" ? '' : heightClassnames;
  const [searchText, setSearchText] = useState('');
  const [selectedDataName, setSelectedDataName] = useState(null);

  //selected data id olarak gönderiliyor ise ilgili id'ye ait verinin adını yazdırır.
  //selected data obje olarak gönderiliyor ise objenin içerisindeki verinin adını yazdırır.
  const getSelectedDataName = () => {
    if (typeof selectedData !== "object") {
      if (data.length > 1) {
        const filteredData = data.filter(data => data.id === selectedData);
        setSelectedDataName(language === 'tr' ? filteredData[0].name : filteredData[0].eng ? filteredData[0].eng : filteredData[0].name);
      } else {
        //Data boş olarak gelirse 'yükleniyor...' yazısını dropdown title'a yazdıracak
        setSelectedDataName(strings.loading);
      }
    } else {
      setSelectedDataName(language === 'tr' ? selectedData.name : selectedData.eng ? selectedData.eng : selectedData.name);
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value)
  };

  const clearSearchbar = () => {
    setSearchText('');
    inputRef.current.value = '';
  }

  //isDisabled props'u yoksa default olarak toggleDropdown çalışır
  //isDisabled props'u varsa isDisabledTrue olduğunda çalışır
  const handleDisabled = () => {
    if (!isDisabled || isDisabled === undefined) {
      toggleDropdown(dropdownRef, dropdownArrowRef);
    }
  }

  const handleKeyDown = (event) => {
    const currentIndex = data.findIndex(item => item.id === selectedData.id);

    switch (event.key) {
      case "ArrowUp":
        event.preventDefault();
        const prevIndex = currentIndex > 0 ? currentIndex - 1 : data.length - 1;
        setSelectedData(data[prevIndex]);
        break;
      case "ArrowDown":
        event.preventDefault();
        const nextIndex = (currentIndex + 1) % data.length;
        openDropdown(dropdownRef, dropdownArrowRef);
        setSelectedData(data[nextIndex]);
        break;
      case "Escape":
        event.preventDefault();
        closeDropdown(dropdownRef, dropdownArrowRef);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    getSelectedDataName();
  }, [selectedData, data])

  const filteredData = data && data.filter(d => d.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()))

  return (
    <>
      <div className={`relative w-full md:max-w-[246px] ${classname}`}>
        <OutsideClickHandler onOutsideClick={() => closeDropdown(dropdownRef, dropdownArrowRef)}>
          <button
            //dropdown'ın pasif olduğu durumdaki css'i kontrol etmek için isDisabled props'u kullanıldı
            className={classNames(`button dropdown md:max-w-[246px] ${(tooltip) ? 'group relative' : ''} ${classname}`, { 'bg-[#f5f5f5] focus:border-[#ebedf2] cursor-not-allowed': isDisabled }, { '!bg-white focus:!border-blue': isDisabled === undefined }, { '!bg-[#e1e1e1]': isDisabled && dark })}
            onClick={handleDisabled}
            onKeyDown={handleKeyDown}
            type="button"
          >
            {selectedDataName === null ? selectedData.name : selectedDataName}
            <div
              ref={dropdownArrowRef}
              className="dropdown-arrow transition-transform duration-300 absolute right-4 top-[calc(50%-2px)]"
            ></div>
            {(tooltip)&& (
              <div className="tooltip-top">
                <p>{tooltip}</p>
              </div>
            )}
          </button>
          <div
            ref={dropdownRef}
            className={`${classname} w-full md:max-w-[246px] absolute mt-[2px] z-10 bg-white rounded origin-top animate-fadeIn scale-y-0 transition-transform duration-200 shadow-dropdownContent `}
          >
            <div className="p-2 ">
              <input
                type="text"
                ref={inputRef}
                className="px-2 h-8 border w-full border-input-border outline-none rounded-md placeholder:text-[12px] text-[13px]"
                placeholder={inputPlaceholder}
                onChange={handleChange}
              />
            </div>
            <ul className={`${heightClassname} py-1 max-h-[250px] overflow-y-scroll sm:overflow-y-hidden sm:overflow-y-scroll-hover`}>
              {
                filteredData
                &&
                filteredData.map((item, index) => (
                  <li key={index}>
                    <div
                      className={`block py-2 px-4 tab-button ${(selectedData.id === item.id) ? 'bg-blue text-white search-button hover:bg-blue hover:text-white search-button' : ''}`}
                      onClick={() => {
                        setSelectedData(item);
                        toggleDropdown(dropdownRef, dropdownArrowRef);
                        clearSearchbar();
                      }}
                    >
                      <p className="cursor-pointer">{item.name}</p>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export const DropdownMemberDetail = (props) => {

  const { headers, title, memberStatus, params } = props;
  const dropdownMemberDetailRef = useRef();
  const dropdownMemberDetailArrowRef = useRef();

  const location = useLocation();
  const language = useSelector(state => state.localizationStore.language);
  const tempPathName = location.pathname;
  const pathName = tempPathName.slice(0, tempPathName.length - 37);
  const registrationType = location.state?.registrationType || false;

  return (
    <OutsideClickHandler onOutsideClick={() => closeDropdown(dropdownMemberDetailRef, dropdownMemberDetailArrowRef)}>
      <div className="relative">
        <button
          className={classNames('member-nav-button group transition-colors duration-200 relative pr-4', { 'member-nav-button-active': (headers.some(header => header.path === pathName) && registrationType !== 3) })}
          onClick={() =>
            toggleDropdown(
              dropdownMemberDetailRef,
              dropdownMemberDetailArrowRef
            )
          }
          type="button"
        >
          {title}
          <div
            ref={dropdownMemberDetailArrowRef}
            className={classNames('dropdown-arrow transition-transform duration-300 absolute right-0 top-[calc(50%-2px)] group-hover:border-t-blue group-focus:border-t-blue', { 'border-t-blue': headers.some(header => header.path === pathName) && registrationType !== 3 })}
          ></div>
        </button>
        <div
          ref={dropdownMemberDetailRef}
          className="absolute bg-white z-10 rounded origin-top animate-fadeIn scale-y-0 transition-transform duration-200 shadow-dropdownContent min-w-max"
        >
          <ul className="py-1">
            {/* Filter ile status değerlerini dönüyoruz.
                Status değeri [1, 2, 3] olanlar her zaman listede var olacaktır.
                Status değeri [1, 2] olanlar member status 1 ve 2 olduğunda tabte olacaktır. */}
            {headers &&
              headers
                .filter((header) => header.status.includes(memberStatus))
                .map((header, index) => (

                  <li key={index}>
                    <Link
                      to={url(header.pathName, params)}
                      state={header.state && header.state}
                      className={classNames('block py-2 px-4 tab-button', { 'tab-button-active': (header.path === pathName && registrationType !== 3) })}
                      onClick={() => toggleDropdown(dropdownMemberDetailRef, dropdownMemberDetailArrowRef)}
                    >
                      {language === 'tr' ? header.tr : header.eng}
                    </Link>
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export const DropdownReadonly = (props) => {

  const { classnames, data, title, arrowClassnames } = props;
  const classname = typeof classnames === "undefined" ? '' : classnames;
  const arrowClassname = typeof arrowClassnames === "undefined" ? '' : arrowClassnames;

  const dropdownRef = useRef();
  const dropdownArrowRef = useRef();

  return (
    <div className={`relative w-full ${classname}`}>
      <OutsideClickHandler onOutsideClick={() => closeDropdown(dropdownRef, dropdownArrowRef)}>
        <button
          //dropdown'ın pasif olduğu durumdaki css'i kontrol etmek için isDisabled props'u kullanıldı
          onClick={() => {
            if (data.length > 0) {
              toggleDropdown(dropdownRef, dropdownArrowRef)
            }
          }}
          className={`button dropdown ${classname}`}
          type="button"
        >
          <p>{title}</p>
          <div
            ref={dropdownArrowRef}
            className={`${arrowClassname} dropdown-arrow transition-transform duration-300 absolute right-4 top-[calc(50%-2px)]`}
          ></div>
        </button>
        <div
          ref={dropdownRef}
          className={`w-full ${classname} absolute mt-[2px] z-10 bg-white rounded origin-top animate-fadeIn scale-y-0 transition-transform duration-200 shadow-dropdownContent max-h-[250px] overflow-y-hidden hover:overflow-y-scroll`}
        >
          <ul className="py-1">
            {data &&
              data.map((item, index) => (
                <li key={index}>
                  <div
                    className="block py-2 px-4 tab-button"
                  >
                    <p>{item.name}</p>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
