import {DetailSearchLayout, Input} from "../../../../components";
import {useLocalization} from "../../../../hooks/useLocalization";
import {useEffect, useState} from "react";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-US';
import {convertDateFormat} from "../../../../components/Utility";

const GiftListDetailSearch = ({ changeDetail }) => {
    registerLocale('en-US', en)

    const strings = useLocalization();

    const [isBeginDateOpen, setIsBeginDateOpen] = useState(false);
    const [beginDate, setBeginDate] = useState(new Date());

    const [isEndDateOpen, setIsEndDateOpen] = useState(false);
    const [endDate, setEndDate] = useState(new Date());

    // const [isAllList, setIsAllList] = useState(false);

    useEffect(() => {
        changeDetail({
            beginDate: convertDateFormat(beginDate)
        })
    }, [beginDate]);

    useEffect(() => {
        changeDetail({
            endDate: convertDateFormat(endDate)
        })
    }, [endDate]);

    // useEffect(() => {
    //     changeDetail({
    //         isAllList: isAllList
    //     })
    // }, [isAllList])

    return (
        <>
            <DetailSearchLayout
                children={
                    <div className="flex gap-x-4 gap-y-5 max-md-lg:justify-center flex-wrap">

                        <div className="flex flex-col">
                            <label className="font-bold mb-2 ml-2">{strings.gift.gift_list.date_range}</label>
                             <div className="flex flex-row">
                                 <div className="w-full md:max-w-[480px] flex flex-col gap-2 relative">
                                     <DatePicker
                                         locale="en-US"
                                         dateFormat="yyyyMMdd"
                                         className="form-input w-full md:max-w-[480px]"
                                         selected={beginDate}
                                         onChange={(date) => { setBeginDate(date); setIsBeginDateOpen(false) }}
                                         onClickOutside={() => setIsBeginDateOpen(false)}
                                         placeholderText={strings.form_info.gift_date_placeholder}
                                         open={isBeginDateOpen}
                                         onFocus={() => setIsBeginDateOpen(true)}
                                     />
                                 </div>
                                <span className="p-2 items-center flex">-</span>
                                 <div className="w-full md:max-w-[480px] flex flex-col gap-2 relative">
                                     <DatePicker
                                         locale="en-US"
                                         dateFormat="yyyyMMdd"
                                         className="form-input w-full md:max-w-[480px]"
                                         selected={endDate}
                                         onChange={(date) => { setEndDate(date); setIsEndDateOpen(false) }}
                                         onClickOutside={() => setIsEndDateOpen(false)}
                                         placeholderText={strings.form_info.gift_date_placeholder}
                                         open={isEndDateOpen}
                                         onFocus={() => setIsEndDateOpen(true)}
                                     />
                                 </div>
                            </div>
                        </div>

                        {/*<div*/}
                        {/*    className="w-full md:w-[246px] flex items-center ml-5"*/}
                        {/*>*/}
                        {/*    <input type="checkbox" id="isActive" className="hidden peer" defaultChecked={isAllList} onClick={(e) => setIsAllList(isSubstituteTeacher => e.target.checked)} />*/}
                        {/*    <label*/}
                        {/*        htmlFor="isActive"*/}
                        {/*        id="slider"*/}
                        {/*        className="relative mr-2 w-12 h-6 rounded-full bg-red peer-checked:bg-green cursor-pointer duration-500 before:switch-circle"*/}
                        {/*    ></label>*/}
                        {/*    {!isAllList && (*/}
                        {/*    <p className="flex animate-fadeIn">*/}
                        {/*        {strings.gift.gift_list.incoming_gift_requests}*/}
                        {/*    </p>*/}
                        {/*    )}*/}

                        {/*    {isAllList && (*/}
                        {/*        <p className="animate-fadeIn">*/}
                        {/*            {strings.gift.gift_list.all_list}*/}
                        {/*        </p>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                }
            />
        </>
    )
}

export default GiftListDetailSearch;