import { useLocalization } from "../hooks/useLocalization"

export const SearchCount = ({ count }) => {
    const strings = useLocalization();

    return (
        <>
            <hr className="mb-4" />
            <div
                className="text-end px-4"
                dangerouslySetInnerHTML={{
                    __html: strings.count.replace(/#count#/g, count)
                }}
            />
        </>
    )
}

export default SearchCount