import {fetchApi} from "../FetchApi";

export const GetAllTermFilter = async (model) => {

    const url = '/member/v1/term/get_all_term_filter/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}