import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {DefaultProfile} from "../../assets/img";
import {Dropdown, Input, Loading, PageTitle, showTopAlert} from "../../components";
import {Gender, MaritalStatus, StateList, TeacherStatus} from "../../components/Constants";
import {checkNumber, getTeacherName} from "../../components/Utility";
import {useLocalization} from "../../hooks/useLocalization";
import {
    EditTeacherInformations,
    GetTeacherDetail,
    GetTeacherInterests, GetTeacherPersonals,
    UpdateInterests, UpdatePersonals
} from "../../services/Teacher/TeacherService";
import {EditShifts} from "./components";
import {url} from "../../routes/utility";
import {ServiceErrorMessages} from "../../consts/Constants";

const EditTeacher = () => {

    const strings = useLocalization();
    const { teacherId } = useParams();
    const language = useSelector(state => state.localizationStore.language);
    const navigate = useNavigate();

    const topRef = useRef();

    const teacherIdErrorRef = useRef();
    const fullNameErrorRef = useRef();
    const genderErrorRef = useRef();
    const birthDateErrorRef = useRef();
    const statusErrorRef = useRef();

    const [loading, setLoading] = useState(true);
    //kişisel bilgiler stateler
    const [teacherDetail, setTeacherDetail] = useState({
        teacherId: null,
        about: '',
        fullName: '',
        gender: -1,
        birthPlace: '',
        birthDate: null,
        university: '',
        department: '',
        soundUrl: '',
        profilePictureUrl: '',
        status: -1,
        maritalStatus: -1
    });
    const [tmpTeacherStatus, setTmpTeacherStatus] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);

    //Dropdown'a state göndermek zorunlu olduğu için dropdown kısımları için ayrı state'ler oluşturuluyor.
    const firstGender = { id: -1, name: strings.form_info.choose_gender }
    const [gender, setGender] = useState(firstGender);

    const firstTeacherStatus = { id: -1, name: strings.form_info.choose_status };
    const [teacherStatus, setTeacherStatus] = useState(firstTeacherStatus);

    const firstMaritalStatus = { id: -1, name: strings.form_info.choose_marital_status };
    const [maritalStatus, setMaritalStatus] = useState(firstMaritalStatus);

    const firstState = { id: -1, name: strings.form_info.choose_state};
    const [selectedState, setSelectedState] = useState(firstState);

    //Birth year değeri servise gönderilirken manipüle edileceği için ayrı bir state üzerinden ilerlendi.
    const [birthYear, setBirthYear] = useState("");

    //Statik data'lar için stateler.
    const [genderData, setGenderData] = useState([]);
    const [maritalStatusData, setMaritalStatusData] = useState([]);
    const [teacherStatusData, setTeacherStatusData] = useState([]);

    //İlgi alanları için state'ler
    const [areasOfInterestInput, setAreasOfInterestInput] = useState("");
    const [areasOfInterestData, setAreasOfInterestData] = useState([]);
    const [areasOfInterestCurrentData, setAreasOfInterestCurrentData] = useState([]);

    //Personal alanları için statle'ler
    const [areasOfPersonalInput, setAreasOfPersonalInput] = useState("");
    const [areasOfPersonalData, setAreasOfPersonalData] = useState([]);
    const [areasOfPersonalCurrentData, setAreasOfPersonalCurrentData] = useState([]);

    const getTeacherDetail = async (teacherId) => {
        setIsUpdated(isUpdated => false);
        const result = await GetTeacherDetail(teacherId);
        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setTeacherDetail({
                ...teacherDetail,
                teacherId: resultContent.teacherId,
                about: resultContent.about,
                fullName: resultContent.fullName,
                gender: resultContent.gender,
                birthPlace: resultContent.birthPlace,
                birthDate: resultContent.birthdate,
                university: resultContent.university,
                department: resultContent.department,
                profilePictureUrl: resultContent.profilePictureUrl?.split(process.env.REACT_APP_TEACHER_PROFILE_URL)[1] || "",
                soundUrl: resultContent.soundUrl?.split(process.env.REACT_APP_TEACHER_SOUND_URL)[1] || "",
                status: resultContent.status,
                maritalStatus: resultContent.maritalStatus
            });
            setTmpTeacherStatus(tmpTeacherStatus => resultContent.status);
            setLoading(loading => false);
            
            //Dropdown'lara state göndermek zorunlu olduğu için ayrıca dolduruluyor.
            setGender(gender => (resultContent.gender === -1 || resultContent.gender === null) ? firstGender : Gender.find(g => g.id === resultContent.gender));
            setMaritalStatus(maritalStatus => (resultContent.maritalStatus === -1 || resultContent.maritalStatus === null) ? firstMaritalStatus : MaritalStatus.find(ms => ms.id === resultContent.maritalStatus));
            setTeacherStatus(teacherStatus => (resultContent.teacherStatus === -1 || resultContent.teacherStatus === null) ? firstTeacherStatus : TeacherStatus.find(ts => ts.id === resultContent.status));
        } else {
            showTopAlert(strings.errors.an_error_occurred + " / " + result.content, "error");
        }
    }

    //Eğitmenlerin ilgi alanlarını getiren servis
    const getTeacherInterests = async (id) => {
        const result = await GetTeacherInterests(id);
        let tmpInterestData = [];

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            resultContent.map((interest) => {
                tmpInterestData.push(interest.text);
            })
        }

        setAreasOfInterestCurrentData(areasOfInterestData => tmpInterestData);
    }

    //Eğitmenlerin personal alanlarını getiren servis
    const getTeacherPersonals = async (id) => {
        const result = await GetTeacherPersonals(id);
        let tmpPersonalData = [];

        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);

            resultContent.map((interest) => {
                tmpPersonalData.push(interest.text);
            })
        }

        setAreasOfPersonalCurrentData(areasOfPersonalCurrentDate => tmpPersonalData);
    }

    //Input alanları için onChange durumu name'e göre kontrol ediliyor.
    //Name alanı state'teki değer ile aynıysa değişiklik state'e yansıtılıyor.
    const handleChange = e => {
        setTeacherDetail({
            ...teacherDetail,
            [e.target.name]: e.target.value
        })
    };

    //İlgi alanlarını güncelleyen fonksiyon.
    const updateInterests = async (teacherId, areasOfInterestData) => {

        let model = {
            teacherId: teacherId,
            interests: areasOfInterestData
        };

        await UpdateInterests(model);
    }

    //Personal alanlarını güncelleyen fonksiyon.
    const updatePersonals = async (teacherId, areasOfPersonalData) => {

        let model = {
            teacherId: teacherId,
            personals: areasOfPersonalData
        };

        await UpdatePersonals(model);
    }

    const submitHandle = async (e) => {

        e.preventDefault();

        let valid = true;

        teacherIdErrorRef.current.innerText = '';
        fullNameErrorRef.current.innerText = '';
        genderErrorRef.current.innerText = '';
        birthDateErrorRef.current.innerText = '';
        statusErrorRef.current.innerText = '';

        if (teacherDetail.teacherId === null) {
            valid = false;
            teacherIdErrorRef.current.innerText = strings.errors.teacher_id_null_error;
        }
        if (teacherDetail.fullName === '') {
            valid = false;
            fullNameErrorRef.current.innerText = strings.errors.full_name_null_error;
        }
        if (birthYear.length > 4) {
            valid = false;
            birthDateErrorRef.current.innerText = strings.errors.birth_date_format_error;
        }
        if (teacherDetail.gender === -1) {
            valid = false;
            genderErrorRef.current.innerText = strings.errors.gender_null_error;
        }
        if (teacherDetail.status === -1) {
            valid = false;
            statusErrorRef.current.innerText = strings.errors.status_null_error;
        }

        if (valid) {
            const result = await EditTeacherInformations(teacherDetail);
            if (result.status === 200) {
                updateInterests(teacherDetail.teacherId, areasOfInterestData);
                updatePersonals(teacherDetail.teacherId, areasOfPersonalData);
                topRef.current.focus();
                setIsUpdated(isUpdated => true);
                showTopAlert(strings.teacher.update_teacher.teacher_update_success_message, "success");
            } else {
                if(result.content === ServiceErrorMessages.TWO_HOUR_RULE) {
                    showTopAlert(strings.errors.two_hour_rule_error, "error");
                } else {
                    showTopAlert(strings.errors.an_error_occurred, "error");
                }
            }
        }
    };

    useEffect(() => {
        //Statik datalar dile göre kontrol yapılıp state'ler dolduruluyor.
        let tmpGenderData = [firstGender, ...Gender.map((gender) => language === 'en' ? { id: gender.id, name: gender.eng } : { id: gender.id, name: gender.name })];
        setGenderData(genderData => tmpGenderData);

        let tmpTeacherStatusData = [firstTeacherStatus, ...TeacherStatus.map((status) => language === 'en' ? { id: status.id, name: status.eng } : { id: status.id, name: status.name })];
        setTeacherStatusData(teacherStatusData => tmpTeacherStatusData);

        let tmpMaritalStatusData = [firstMaritalStatus, ...MaritalStatus.map((maritalStatus) => language === 'en' ? { id: maritalStatus.id, name: maritalStatus.eng } : { id: maritalStatus.id, name: maritalStatus.name })];
        setMaritalStatusData(maritalStatusData => tmpMaritalStatusData);
    }, []);

    useEffect(()  => {
        if (teacherId) {
            getTeacherDetail(teacherId);
        }
    }, [isUpdated, teacherId])

    //Dropdown değişiklikleri ayrıca kontrol ediliyor.
    useEffect(() => {
        setTeacherDetail({
            ...teacherDetail,
            gender: gender.id
        });
    }, [gender])

    //Dropdown değişiklikleri ayrıca kontrol ediliyor.
    useEffect(() => {
        setTeacherDetail({
            ...teacherDetail,
            status: teacherStatus.id
        });
    }, [teacherStatus])

    useEffect(() => {
        if(teacherDetail?.birthDate) {
            setBirthYear(birthYear => teacherDetail.birthDate.split("-")[0])
        }
    }, [teacherDetail])

    useEffect(() => {
        setTeacherDetail({
            ...teacherDetail,
            birthDate: `${birthYear}-01-01T00:00:00`
        })
    }, [birthYear]);

    //Dropdown değişiklikleri ayrıca kontrol ediliyor.
    useEffect(() => {
        setTeacherDetail({
            ...teacherDetail,
            maritalStatus: maritalStatus.id
        });
    }, [maritalStatus])

    //Seçili state değeri servisten gelene göre manipüle ediliyor.
    useEffect(() => {
        if(teacherDetail?.birthPlace?.length > 0){
            let matchedState = StateList.find(st => st.name === teacherDetail.birthPlace)
            matchedState && setSelectedState(selectedState => matchedState);
        }
    }, [teacherDetail]);

    useEffect(() => {
        setTeacherDetail({
            ...teacherDetail,
            birthPlace: selectedState.name,
        });
    }, [selectedState]);

    useEffect(() => {
        if(teacherDetail.profilePictureUrl === "") {
            setTeacherDetail({
                ...teacherDetail,
                profilePictureUrl: `${teacherId}.jpg`
            })
        }
    }, [teacherDetail.profilePictureUrl])

    // İlgi alanları
    useEffect(() => {
        if(teacherId){
            getTeacherInterests(teacherId);
            getTeacherPersonals(teacherId);
        }
    }, [])

    useEffect(() => {
        setAreasOfInterestInput(areasOfInterestInput => areasOfInterestCurrentData.join());
    }, [areasOfInterestCurrentData]);

    useEffect(() => {
        setAreasOfPersonalInput(areasOfPersonalInput => areasOfPersonalCurrentData.join());
    }, [areasOfPersonalCurrentData]);

    useEffect(() => {
        const inputArray = areasOfInterestInput
            .split(',') // Inputu ',' karakterine göre ayır
            .map(item => item.trim()) // Her elemanın başındaki ve sonundaki boşlukları temizle
            .filter(item => item !== ''); // Boş olmayan elemanları filtrele

        setAreasOfInterestData(areasOfInterestData => inputArray);
    }, [areasOfInterestInput]);

    useEffect(() => {
        const inputArray = areasOfPersonalInput
            .split(',') // Inputu ',' karakterine göre ayır
            .map(item => item.trim()) // Her elemanın başındaki ve sonundaki boşlukları temizle
            .filter(item => item !== ''); // Boş olmayan elemanları filtrele

        setAreasOfPersonalData(areasOfInterestData => inputArray);
    }, [areasOfPersonalInput]);

    return (
        <>
            <PageTitle title={strings.teacher.update_teacher.update_teacher} />
            <p tabIndex={-1} ref={topRef} className="h-0"></p>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <div className="h-[100px] rounded-md mb-8">
                        <div className="flex justify-center">
                            <div className="relative mt-8 flex flex-col items-center">
                                <img
                                    className="w-[100px] h-[100px] shadow-profilePhoto rounded-full border-[2px]"
                                    src={teacherDetail.profilePictureUrl !== '' ? process.env.REACT_APP_TEACHER_PROFILE_URL + teacherDetail.profilePictureUrl : DefaultProfile}
                                    alt=""
                                />
                                <p className="text-lg font-bold text-center">{getTeacherName(teacherDetail.teacherId, teacherDetail.fullName)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="p-5 max-xs:px-2">
                        <h5 className="font-bold text-[15px] pl-3 my-3">{strings.teacher.create_teacher.personal_informations}</h5>
                        <hr />
                        <form onSubmit={submitHandle}>
                            <div className="flex flex-col lg:flex-row lg:justify-between">
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="teacherId">
                                            {strings.teacher.teacher_title} Id <span className="text-red">*</span>
                                        </label>
                                        <Input
                                            id="teacherId"
                                            name="teacherId"
                                            onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                            value={teacherDetail.teacherId === null ? '' : teacherDetail.teacherId}
                                            onChange={(e) => setTeacherDetail({ ...teacherDetail, teacherId: e.target.value !== '' ? parseInt(e.target.value) : null })}
                                        />
                                        <span ref={teacherIdErrorRef} id="teacherIdError" className="text-danger field-validation-valid px-[10px]"></span>
                                    </div>
                                </div>
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="username">
                                            {strings.form_info.full_name}{" "}
                                            <span className="text-red">*</span>
                                        </label>
                                        <Input
                                            id="username"
                                            name="fullName"
                                            value={teacherDetail.fullName === null ? '' : teacherDetail.fullName}
                                            onChange={handleChange}
                                        />
                                        <span ref={fullNameErrorRef} id="fullNameError" className="text-danger field-validation-valid px-[10px]"></span>
                                    </div>
                                </div>
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="gender">
                                            {strings.form_info.gender}{" "}
                                            <span className="text-red">*</span>
                                        </label>
                                        <Dropdown
                                            classnames="md:!max-w-full"
                                            data={genderData}
                                            selectedData={gender}
                                            setSelectedData={setGender}
                                        />
                                        <span ref={genderErrorRef} id="genderError" className="text-danger field-validation-valid px-[10px]"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-x-5">
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="birthPlace">
                                            {strings.form_info.birth_place}
                                        </label>
                                        <Dropdown
                                            data={[firstState, ...StateList]}
                                            selectedData={selectedState}
                                            setSelectedData={setSelectedState}
                                            classnames="md:!max-w-full"
                                        />
                                    </div>
                                </div>
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="birthDate">
                                            {strings.form_info.birth_date}{" "}
                                            <span className="text-red">*</span>
                                        </label>
                                        <div className="w-full md:max-w-full flex flex-col relative">
                                            <Input
                                                name="birthDate"
                                                value={birthYear}
                                                onChange={(e) => setBirthYear(e.target.value)}
                                                onKeyDown={(e) => !checkNumber(e) && e.preventDefault()}
                                            />
                                        </div>
                                        <span ref={birthDateErrorRef} id="birthDateError" className="text-danger field-validation-valid px-[10px]"></span>
                                    </div>
                                </div>
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label
                                            className="text-[13px] font-bold"
                                        >
                                            {strings.form_info.marital_status}
                                        </label>
                                        <Dropdown
                                            classnames="md:!max-w-full"
                                            data={maritalStatusData || []}
                                            selectedData={maritalStatus}
                                            setSelectedData={setMaritalStatus}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-x-5">
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <div className="flex align-middle gap-1">
                                            <label htmlFor="profilePictureUrl">
                                                <span>{strings.form_info.image_name}</span>
                                            </label>
                                            <div className="relative group">
                                                <span
                                                    className="material-symbols-outlined flex-shrink-0 text-[19px]">info</span>
                                                <span
                                                    className="tooltip-top right-[-150px] left-[-150px]">{strings.form_info.tooltip_profile}</span>
                                            </div>
                                        </div>
                                        <Input
                                            id="profilePictureUrl"
                                            name="profilePictureUrl"
                                            value={teacherDetail.profilePictureUrl === null ? '' : teacherDetail.profilePictureUrl}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="university">
                                            {strings.form_info.sound_name}
                                        </label>
                                        <Input
                                            id="soundUrl"
                                            name="soundUrl"
                                            value={teacherDetail.soundUrl === null ? '' : teacherDetail.soundUrl}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="university">
                                            {strings.form_info.university}
                                        </label>
                                        <Input
                                            id="university"
                                            name="university"
                                            value={teacherDetail.university === null ? '' : teacherDetail.university}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col lg:flex-row lg:justify-between lg:gap-x-5">
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="department">
                                            {strings.form_info.department}
                                        </label>
                                        <Input
                                            id="department"
                                            name="department"
                                            value={teacherDetail.department === null ? '' : teacherDetail.department}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="mt-5 w-full lg:w-[32.5%]">
                                    <div className="flex flex-col px-[10px] pb-0">
                                        <label htmlFor="status">
                                            {strings.form_info.status} <span className="text-red">*</span>
                                        </label>
                                        <Dropdown
                                            id="status"
                                            data={teacherStatusData}
                                            selectedData={teacherStatus}
                                            setSelectedData={setTeacherStatus}
                                            classnames="md:!max-w-full"
                                        />
                                        <span
                                            ref={statusErrorRef}
                                            id="statusError"
                                            className="text-danger field-validation-valid px-[10px]"
                                        ></span>
                                    </div>
                                </div>
                                <div className="mt-5 w-full lg:w-[32.5%]"></div>
                            </div>
                            <div className="flex flex-col px-[10px] pb-0 w-full my-5">
                                <label htmlFor="about">{strings.form_info.basic_information}</label>
                                <textarea
                                    id="about"
                                    name="about"
                                    className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
                                    type="text"
                                    rows="5"
                                    value={teacherDetail.about === null ? '' : teacherDetail.about}
                                    onChange={handleChange}
                                >
                                </textarea>
                            </div>
                            <div className="flex flex-col px-[10px] pb-0 w-full my-5">
                                <label htmlFor="about">{strings.teacher.create_teacher.areas_of_interest}</label>
                                <Input
                                    value={areasOfInterestInput}
                                    onChange={(e) => setAreasOfInterestInput(e.target.value)}
                                />
                            </div>
                            <div className="flex flex-col px-[10px] pb-0 w-full my-5">
                                <label htmlFor="about">{strings.teacher.create_teacher.areas_of_personals}</label>
                                <Input
                                    value={areasOfPersonalInput}
                                    onChange={(e) => setAreasOfPersonalInput(e.target.value)}
                                />
                            </div>
                            <div className="mt-7 mb-5 flex gap-3 justify-center">
                                <button
                                    type="submit"
                                    className="button search-button text-white bg-blue border-blue px-7"
                                >
                                    <p>{strings.button.save}</p>
                                </button>
                                <button
                                    type="button"
                                    className="button search-button text-white bg-red border-red px-7 focus:border-red"
                                    onClick={() => navigate(url("dashboard"))}
                                >
                                    <p>{strings.button.cancel}</p>
                                </button>
                            </div>
                        </form>

                        <EditShifts ref={topRef} teacherId={teacherId} teacherStatus={tmpTeacherStatus} />
                    </div>
                </>
            )}
        </>
    );
};

export default EditTeacher;
