import React from 'react'

const LessonTime = (props) => {

  const { date, iconName, iconClassnames, tooltip } = props;  

  return (
    <>
        <div className="border rounded-[5px] max-w-max py-2 px-3">
            {date}
            <hr className='my-[2px] -mx-1'/>
            <div className="group relative">
                <span className={`${iconClassnames ? iconClassnames : ''} material-symbols-outlined text-[16px] font-bold text-center w-full align-bottom`}>{iconName}</span>
                <div className="tooltip-top">
                    <p>{tooltip}</p>
                </div>
            </div>
        </div>
    </>
  )
};

export default LessonTime;