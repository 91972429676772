import { isValidPhoneNumber } from "libphonenumber-js";

export const decodeToken = token => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const validateEmail = mail => {
    if (mail === null || mail === '')
        return false;
    let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return mail.match(mailFormat) ? true : false;

}

export const validPhoneNumber = phone => {
    return isValidPhoneNumber(phone);
}

export const getddMMyyyyDate = date => {
    return (("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear());
}

export const getDateWithDayDifference = (date, difference) => {
    const tmpDate = new Date();
    return new Date(tmpDate.setDate(date.getDate() - difference));
}

//string veriyi datee çevirerek servise iletiyoruz
export const stringToDate = (strDate, hour = 0, minute = 0, second = 0) => {
    let dateArr = strDate !== null && strDate.split("/");
    return strDate !== null && new Date(dateArr[2], parseInt(dateArr[1]) - 1, parseInt(dateArr[0]), hour, minute, second);
}

//Boş gelen verileri ekrana istediğimiz metin ile yazdırmamızı sağlıyor
export const isItSpecified = (value, message) => {
    return value ? value : message;
}

export const ISOStringToDate = (date, timeZone) => {
    let tmpDate = new Date(date);

    if (date.includes("T")) {
        const dateArr = date.split("T");
        const dateTmpTime = dateArr[1].split(":");
        tmpDate = new Date(Date.UTC(tmpDate.getFullYear(), tmpDate.getMonth(), tmpDate.getDate(), dateTmpTime[0], dateTmpTime[1], dateTmpTime[2].split(".")[0]));
    }

    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear();
    let tmpTime = tmpDate.getUTCHours() + ":" + tmpDate.getUTCMinutes();
    return returnDate + " / " + addTimeZone(tmpTime, timeZone)
}

//ISO Formatlı datetime içerikleri formatlamak için kullanılıyor. TimeZone ihtiyacı duyulmuyor
export const ISOStringToDate2 = date => {
    let tmpDate = new Date(date);
    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear()
    return returnDate;
}

//ISO Formatlı datetime içerikleri formatlamak için kullanılıyor. Tarihin yanına saatte ekleniyor. TimeZone ihtiyacı duyulmuyor
export const ISOStringToDateWithTime = date => {
    let tmpDate = new Date(date);
    let returnDate = ("0" + tmpDate.getDate()).slice(-2) + "." + ("0" + (tmpDate.getMonth() + 1)).slice(-2) + '.' + tmpDate.getFullYear()
    let returnTime = ("0" + tmpDate.getHours()).slice(-2) + ":" + ("0" + tmpDate.getMinutes()).slice(-2) + ":" + ("0" + tmpDate.getSeconds()).slice(-2);

    return returnDate + " / " + returnTime;
}

//Full Date formatını Short Date(2023-03-16T00:00:00) formatına çeviren fonksiyon.
export const fullDateToShortDate = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

//Full Date formatını Short Date(2023-03-16T00:00:00) formatına çeviren fonksiyon.
export const fullDateToShortDateWithTime = (dateString, hour = 0, minute = 0, second = 0) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = hour.toString().padStart(2, "0");
    const minutes = minute.toString().padStart(2, "0");
    const seconds = second.toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

//Timezone eklemek için kullanılan fonksiyon
export const addTimeZone = (tm, timeZone) => {
    let today = new Date();
    let timeArr = tm.split(":");
    let time = new Date(today.getFullYear(), today.getMonth(), today.getDay(), parseInt(timeArr[0]), parseInt(timeArr[1]));
    let returnTime = new Date(time.setMinutes(time.getMinutes() + parseInt(timeZone)));
    return ("0" + returnTime.getHours()).slice(-2) + ':' + ("0" + returnTime.getMinutes()).slice(-2);
}

export const adjustTimeWithTimeZone = (time, employeeTimeZone) => {
    const adjustedHour = (parseInt(time.split(':')[0]) - Math.floor(employeeTimeZone / 60) + 24) % 24;
    const adjustedTime = `${adjustedHour.toString().padStart(2, "0")}:${time.split(':')[1]}`;
    return adjustedTime;
}

export const calculateTimeDifference = (startHour, endHour) => {
    const hoursPerDay = 24;
    const diff = endHour - startHour;

    if (diff < 0) {
        return hoursPerDay + diff;
    }

    return diff;
}

export const convertDateFormat = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}${month}${day}`;
}

//'13:40:00' gelen değeri '13:40' formatına çeviren fonksiyon
export const convertTimestapToReadbleTime = (timeString, timeZone) => {
    const [hours, minutes] = timeString.split(":");
    const formattedTime = `${hours}:${minutes}`;
    return addTimeZone(formattedTime, formattedTime);
}

//Verilen başlangıç zamanına ve süreye göre bitiş zamanını hesaplayan fonksiyon
export const calculateEndTime = (beginTime, duration) => {

    const [hour, minute] = beginTime.split(":");

    let hourInt = parseInt(hour);
    let minuteInt = parseInt(minute);

    minuteInt += duration;

    if (minuteInt >= 60) {
        hourInt += Math.floor(minuteInt / 60);
        minuteInt = minuteInt % 60;
    }
    if (hourInt >= 24) {
        hourInt = hourInt % 24;
    }

    const endTime = ('0' + hourInt).slice(-2) + ':' + ('0' + minuteInt).slice(-2);
    return endTime;
}

//Verilen zamanı AM/PM formatına çeviren fonksiyon
export const convertToAMPMFormat = (time, permission) => {
    // permission parametresi true ise saat formatı AMPM'e çevrilmez. Bu rol yönetici ve yazılımcı için geçerlidir.
    // permission false ise diğer roller için AMPM format kullanılır.
    if(permission) {
        return time;
    } else {
        const timeParts = time.split(':');

        const timePeriod = parseInt(timeParts[0], 10) >= 12 ? ' PM' : ' AM';

        let formattedTime = (("0" + (parseInt(timeParts[0], 10) % 12 || 12)).toString().slice(-2)) + ':' + timeParts[1] + timePeriod;
        return formattedTime;
    }
}
  
//girilen değerin number olup olmadığını kontrol eden fonksiyon
export const checkNumber = e => {

    let keypressed = e.keyCode;

    if ((keypressed >= 48 && keypressed <= 57) ||
        (keypressed >= 96 && keypressed <= 105)
        || keypressed === 8
        || keypressed === 27
        || keypressed === 46
        || keypressed === 9
        || (keypressed >= 35 && keypressed <= 40)) {
        return true;
    }

    return false;
}

//Checkbox css'ini kontrol eden fonksiyon
export const handleCheckbox = (status) => {
    if (status) {
        return 'bg-green border border-green text-white'
    } else {
        return 'border border-[#aaaaaa]'
    }
}

//Kısaltılmış hafta günlerinin çevirisinde kullanılan fonksiyon 
export const translateWeekDays = (day, language) => {

    let result = '';

    switch (day) {
        case 0:
            result = language === 'tr' ? 'Paz' : language === 'en' ? 'Sun' : '';
            return result;
        case 1:
            result = language === 'tr' ? 'Pzt' : language === 'en' ? 'Mon' : '';
            return result;
        case 2:
            result = language === 'tr' ? 'Sal' : language === 'en' ? 'Tue' : '';
            return result;
        case 3:
            result = language === 'tr' ? 'Çar' : language === 'en' ? 'Wed' : '';
            return result;
        case 4:
            result = language === 'tr' ? 'Per' : language === 'en' ? 'Thu' : '';
            return result;
        case 5:
            result = language === 'tr' ? 'Cum' : language === 'en' ? 'Fri' : '';
            return result;
        case 6:
            result = language === 'tr' ? 'Cmt' : language === 'en' ? 'Sat' : '';
            return result;
        default:
            return '';
    }
}

//Hafta günlerinin çevirisinde kullanılan fonksiyon 
export const translateWeekDaysLong = (day, language) => {

    let result = '';

    switch (day) {
        case 0:
            result = language === 'tr' ? 'Pazar' : language === 'en' ? 'Sunday' : '';
            return result;
        case 1:
            result = language === 'tr' ? 'Pazartesi' : language === 'en' ? 'Monday' : '';
            return result;
        case 2:
            result = language === 'tr' ? 'Salı' : language === 'en' ? 'Tuesday' : '';
            return result;
        case 3:
            result = language === 'tr' ? 'Çarşamba' : language === 'en' ? 'Wednesday' : '';
            return result;
        case 4:
            result = language === 'tr' ? 'Perşembe' : language === 'en' ? 'Thursday' : '';
            return result;
        case 5:
            result = language === 'tr' ? 'Cuma' : language === 'en' ? 'Friday' : '';
            return result;
        case 6:
            result = language === 'tr' ? 'Cumartesi' : language === 'en' ? 'Saturday' : '';
            return result;
        default:
            return '';
    }
}

export const translateStatusById = (id, langugage) => {
    switch (id) {
        case 0:
            return langugage === 'tr' ? 'Pasif' : langugage === 'en' ? 'Passive' : '';
        case 1:
            return langugage === 'tr' ? 'Aktif' : langugage === 'en' ? 'Active' : '';
        default:
            return '';
    }
}

export const iconForLessonStatus = (status, language) => {
    switch(status){
        case 1:
            return { iconName: "schedule", color: "", tooltip: language === "tr" ? 'Aktif' : language === 'en' ? 'Active' : '' };             
        case 2:
            return { iconName: "done", color: "text-green", tooltip: language === "tr" ? 'Katıldı' : language === 'en' ? 'Attented' : '' };             
        case 3:
            return { iconName: "close", color: "text-red", tooltip: language === "tr" ? 'Dersi Kaçırdı' : language === 'en' ? 'Missed' : '' };               
        case 4:
            return { iconName: "history", color: "text-blue", tooltip: language === "tr" ? 'Erteledi' : language === 'en' ? 'Postponed' : '' };                 
        case 5:
            return { iconName: "ac_unit", color: "", tooltip: language === "tr" ? 'Dondurdu' : language === 'en' ? 'Frozen' : '' };                   
        case 6:
            return { iconName: "hotel", color: "", tooltip: language === "tr" ? 'Tatil' : language === 'en' ? 'Holiday' : '' };                   
        case 7:
            return { iconName: "category", color: "", tooltip: language === "tr" ? 'Hafta Sonu' : language === 'en' ? 'Weekend' : '' };                   
        case 8:
            return { iconName: "do_not_disturb_on", color: "", tooltip: language === "tr" ? 'Ders Yok' : language === 'en' ? 'No Lesson' : '' };                 
        case 9:
            return { iconName: "construction", color: "", tooltip: language === "tr" ? 'Sistem Erteleme' : language === 'en' ? 'System Postpone' : '' };                   
        case 10:
            return { iconName: "delete", color: "", tooltip: language === "tr" ? 'Silindi' : language === 'en' ? 'Deleted' : '' };
        default:
            return { iconName: "", color: "", tooltip: ""};
    }
}

export const employeeTypeTranslations = (employeeType, language) => {
    switch (employeeType) {
        case "Sistem":
            return language === "tr" ? 'Sistem' : language === 'en' ? 'System' : '';
        case "Yazılımcı":
            return language === "tr" ? 'Yazılımcı' : language === 'en' ? 'Developer' : '';
        case "Yönetici":
            return language === "tr" ? 'Yönetici' : language === 'en' ? 'Manager' : '';
        case "Satış Temsilcisi":
            return language === "tr" ? 'Satış Temsilcisi' : language === 'en' ? 'Sales Person' : '';
        case "Satış Takım Lideri":
            return language === "tr" ? 'Satış Takım Lideri' : language === 'en' ? 'Sales Team Leader' : '';
        case "Satış Takım Lideri Yardımcısı":
            return language === "tr" ? 'Satış Takım Lideri Yardımcısı' : language === 'en' ? 'Sales Team Leader Assistant' : '';
        case "Verimlilik Temsilcisi":
            return language === "tr" ? 'Verimlilik Temsilcisi' : language === 'en' ? 'Productivity Person' : '';
        case "Verimlilik Takım Lideri":
            return language === "tr" ? 'Verimlilik Takım Lideri' : language === 'en' ? 'Productivity Team Leader' : '';
        case "Verimlilik Takım Lideri Yardımcısı":
            return language === "tr" ? 'Verimlilik Takım Lideri Yardımcısı' : language === 'en' ? 'Productivity Team Leader Assistant' : '';
        case "Kurumsal Destek Temsilcisi":
            return language === "tr" ? 'Kurumsal Destek Temsilcisi' : language === 'en' ? 'Corporate Support Person' : '';
        case "Kurumsal Takım Lideri":
            return language === "tr" ? 'Kurumsal Takım Lideri' : language === 'en' ? 'Corporate Team Leader' : '';
        case "Kurumsal Takım Lideri Yardımcısı":
            return language === "tr" ? 'Kurumsal Takım Lideri Yardımcısı' : language === 'en' ? 'Corporate Team Leader Assistant' : '';
        case "Kurumsal Kilit Hesap Yöneticisi":
            return language === "tr" ? 'Kurumsal Kilit Hesap Yöneticisi' : language === 'en' ? 'Corporate Key Account Manager' : '';
        case "Muhasebe":
            return language === "tr" ? 'Muhasebe' : language === 'en' ? 'Accounting' : '';
        case "Destek":
            return language === "tr" ? 'Destek' : language === 'en' ? 'Support' : '';
        case "Referansatör":
            return language === "tr" ? 'Referansatör' : language === 'en' ? 'Referencer' : '';
        case "İnsan Kaynakları":
            return language === "tr" ? 'İnsan Kaynakları' : language === 'en' ? 'Human Resources' : '';
        case "Pazarlama":
            return language === "tr" ? 'Pazarlama' : language === 'en' ? 'Marketing' : '';
        case "Ürün Yöneticisi":
            return language === "tr" ? 'Ürün Yöneticisi' : language === 'en' ? 'Product Manager' : '';
        case "Santral":
            return language === "tr" ? 'Santral' : language === 'en' ? 'Santral' : '';
        case "Supervisor":
            return language === "tr" ? 'Supervisor' : language === 'en' ? 'Supervisor' : '';
        case "Eğitmen Supervisor":
            return language === "tr" ? 'Eğitmen Supervisor' : language === 'en' ? 'Teacher Supervisor' : '';
        case "Eğitmen İnsan Kaynakları":
            return language === "tr" ? 'Eğitmen İnsan Kaynakları' : language === 'en' ? 'Teacher HR' : '';
        default:
            return '';
    }
};

export const getTeacherName = (teacherId, currentTeacherName) => {
    if(+teacherId === +process.env.REACT_APP_TEACHER_SANDY_ID) {
        return "Teacher Unknown";
    } else {
        return currentTeacherName;
    }
}

export const checkPermission = (roles, employeeTypeId) => {
    let pathRoles = roles.length > 0 ? roles.some(role => role === employeeTypeId) : true;
    return pathRoles;
}

//Girilen iki objenin birbirine eşit olup olmadığını getiren fonksiyon
export const areObjectsEqual = (obj1, obj2) => {
    for (let key in obj1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
    for (let key in obj2) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }
    return true;
}