import { configureStore } from '@reduxjs/toolkit'
import { LocalizationSlice } from './LocalizationSlice'
import { EmployeeSlice } from './EmployeeSlice'
import { TimeZoneSlice } from "./TimeZoneSlice";

const store = configureStore({
  reducer: {
    localizationStore: LocalizationSlice.reducer,
    employeeStore: EmployeeSlice.reducer,
    timeZoneStore: TimeZoneSlice.reducer,
  },
})

export default store