import { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import { useLocalization } from "../../hooks/useLocalization";
import { useDispatch } from "react-redux";
import { employeeLogout } from "../../store/EmployeeSlice";
import { EmployeeStatusType } from "../../components/Constants";
import {getButtonPermission} from "../../components/Buttons";
import {GetTNTTimeZone} from "../../services/Teacher/TeacherService";

const Dashboard = () => {

  const strings = useLocalization()
  const dispatch = useDispatch();
  const { employee, employeeTypeId, checkPermission } = useAuth();
  const roles = getButtonPermission({ group: "dashboardAuthCheck", name: "dashboard" });

  // Kullanıcı rolü Yönetici, Yazılımcı, Supervisor değilse onu sistemden dışarıya atıyoruz.
  // Not: Bu kontrol farklı rollerden giriş yapmış olan kullanıcı var ise onları sistemden atmak için eklenmiştir.
  useEffect(() => {
    const permission = checkPermission(roles, employeeTypeId);
    !permission && dispatch(employeeLogout());
  }, [])

  return (
    <>
      <h2>{strings.dashboard.welcome} {employee.FullName}!</h2>
      <hr className="mb-10 mt-3 text-[#0000001a]" />
    </>
  );
};

export default Dashboard;
