import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ContentCount, Loading, PageTitle} from '../../components';
import {useLocalization} from '../../hooks/useLocalization';
import {GetTeacherProgram} from '../../services/Teacher/ScheduleService';
import {ProgramTable, TeacherSandyList} from './components';
import useAuth from "../../hooks/useAuth";
import NotAuthorized from "../../components/NotAuthorized";
import {addTimeZone, getTeacherName} from "../../components/Utility";
import {GetTeacherDetail} from "../../services/Teacher/TeacherService";
import {useSelector} from "react-redux";

const TeacherProgram = () => {

  const strings = useLocalization();
  const { checkPermission, getPermission, employeeTypeId } = useAuth();
  const timeZone = useSelector(state => state.timeZoneStore.timeZone);
  const roles = getPermission();
  const permission = checkPermission(roles, employeeTypeId)

  const { teacherId } = useParams();

  const [scheduleData, setScheduleData] = useState([]);
  const [teacherName, setTeacherName] = useState("");
  const [loading, setLoading] = useState(true);
  const [isCrashed, setIsCrashed] = useState(false);

  const getProgram = async (teacherId, timeZone) => {

    const result = await GetTeacherProgram(+teacherId);
    let tmpScheduleData = [];

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      resultContent?.schedules?.map((schedule) => {
        let tmpScheduleTime = addTimeZone(schedule.time, timeZone);
        tmpScheduleData.push({ time: tmpScheduleTime, day: schedule.day, status: schedule.status });
      });

      setScheduleData(scheduleData => tmpScheduleData);
    } else {
      setIsCrashed(isCrashed => true);
    }

    setLoading(loading => false);
  }

  const getTeacherDetail = async (teacherId) => {

    const result = await GetTeacherDetail(teacherId);

    if(result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setTeacherName(teacherName => resultContent.fullName);
    }
  }

  const sendRequestToEndpoint = () => {
    if(timeZone !== -1 && teacherId) {
        getProgram(teacherId, timeZone);
    }
  };

  useEffect(() => {
    setLoading(true)
    sendRequestToEndpoint();
  }, [teacherId, timeZone])

  useEffect(() => {

    teacherId && getTeacherDetail(teacherId);

  }, [teacherId]);


  return (
      <>
        {permission && (
            <>
              <p className={`${teacherId ? '' : 'mt-4'} my-2 text-[14px] h-[35px] leading-[35px] text-base-text bg-card px-5 rounded-md flex flex-col md:flex-row md:justify-between max-md:mb-20`}>
                {teacherId && <span className="font-bold">{getTeacherName(teacherId, teacherName)} - {teacherId}</span>}
              </p>

              {loading ? (
                  <Loading />
              ) : (
                  <>
                    {isCrashed && (
                        <div className='py-4 min-w-full text-center'>{strings.errors.an_error_occured_refresh_page}</div>
                    )}

                    {!isCrashed && (
                        (teacherId) ? (
                            scheduleData.length > 0 ? (
                                <>
                                  {teacherId !== process.env.REACT_APP_TEACHER_SANDY_ID && (
                                      <>
                                        <ContentCount
                                            classnames="scale-y-0"
                                            blueText={strings.teacher.teacher_program.lesson_text}
                                            whiteText={strings.teacher.teacher_program.empty_text}
                                            warningText={strings.teacher.teacher_program.blocked_text}
                                        />
                                        <ProgramTable scheduleData={scheduleData} teacherId={teacherId} sendRequestToEndpoint={sendRequestToEndpoint} />
                                      </>
                                  )}

                                  {teacherId === process.env.REACT_APP_TEACHER_SANDY_ID && (
                                      <TeacherSandyList />
                                  )}
                                </>
                            ) : (
                                <div className='py-4 min-w-full text-center'>{strings.teacher.teacher_program.shift_not_found}</div>
                            )
                        ) : (
                            <div className="py-4 min-w-full text-center">{strings.teacher.teacher_program.select_teacher_to_see}</div>
                        )
                    )}
                  </>
              )}
            </>
        )}

        {!permission && (
            <>
              <PageTitle title={strings.sidebar.teacher.teacher_program} />
              <NotAuthorized />
            </>
        )}
      </>
  )
};

export default TeacherProgram;