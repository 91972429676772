import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useLocalization } from "../hooks/useLocalization";
import { buttonRights } from "./rights";

//Butonlara özel rol tanımlandığında kontroller bu fonksiyon tarafından yapılıyor
export const getButtonPermission = (role) => {
    let buttonRole = [];
    let group = buttonRights.find(grp => grp.group === role.group);

    if (group) {
        group.buttons.map(btn => {
            if (btn.name === role.name) {
                buttonRole = btn.roles;
            }
        })
    }

    return buttonRole;
}

export const Button = (props) => {
    const { employeeTypeId, checkPermission } = useAuth();
    const roles = props.role ? getButtonPermission(props.role) : [];
    const strings = useLocalization();
    return (
        <>
            {checkPermission(roles, employeeTypeId) ? (
                <button type={props.type} className={`button search-button text-white ${props.icon && props.text && 'gap-3'} ${props.classnames}`} onClick={props.action} disabled={props.disabled}>
                    <span className="material-symbols-outlined">{props.icon}</span>
                    <p>{props.text}</p>
                </button>
            ) :
                (<button disabled="disabled" className={`button search-button text-white ${props.icon & props.text && 'gap-3'} ${props.classnames}`}>
                    <span className="material-symbols-outlined">{props.icon}</span>
                    <p>{props.text + " / " + strings.errors.not_authorized }</p>
                </button>)
            }
        </>
    );
}

export const SquareButton = (props) => {
    const { employeeTypeId, checkPermission } = useAuth();
    const roles = props.role ? getButtonPermission(props.role) : [];
    const strings = useLocalization();
    
    return (
        <Link
            to={checkPermission(roles, employeeTypeId) && props.to}
            className={`inline-flex items-center justify-center search-button text-white w-11 h-11 rounded-[3px] relative group ${props.classnames}
            ${!checkPermission(roles, employeeTypeId) && props.role ? "opacity-70 !cursor-not-allowed" : ""}
            `}
            type={props.type}
            onClick={props.isPermissionButton ? checkPermission(roles, employeeTypeId) && props.action : props.action}
            disabled={props.disabled}
            state={props.state}
        >
            <span className={`material-symbols-outlined text-[18px] font-bold ${props.iconClassnames}
            ${!checkPermission(roles, employeeTypeId) && props.role ? "opacity-70 !cursor-not-allowed" : ""}`}>
                {props.iconName}
            </span>
            <div className={`${props.tooltipClassnames ? props.tooltipClassnames : ''} tooltip-top`}>
                <p>{!checkPermission(roles, employeeTypeId) && props.role ? strings.errors.not_authorized : props.tooltip}</p>
            </div>
        </Link>
    );
}

//Square button componentinin aynısıdır, link yerine buton kullanılmıştır.
export const SquareButtonWithoutLink = (props) => {
    
    const { type, action, isDisabled, classnames, iconName, iconClassnames, tooltip, tooltipClassnames, role} = props;
    const strings = useLocalization();

    const { employeeTypeId, checkPermission } = useAuth();
    const roles = props.role ? getButtonPermission(props.role) : [];
    const permission = checkPermission(roles, employeeTypeId);

    const classname = classnames === undefined ? '' : classnames;
    const iconClassname = iconClassnames === undefined ? '' : iconClassnames;
    const tooltipClassname = tooltipClassnames === undefined ? '' : tooltipClassnames; 
    
    return (
        <button
            className={`
                inline-flex items-center justify-center search-button text-white w-11 h-11 rounded-[3px] relative group 
                ${classname}
                ${(isDisabled || !permission) ? 'opacity-70 hover:opacity-70 !cursor-not-allowed' : ''}
            `}
            type={type}
            onClick={action}
            disabled={isDisabled || !permission}
        >
            <span 
                className={
                    `material-symbols-outlined text-[18px] font-bold 
                    ${iconClassname}
                    ${(isDisabled ||!permission)? 'opacity-70 hover:opacity-70 !cursor-not-allowed' : ''}
                `}
            >
                {iconName}
            </span>
            <div className={`tooltip-top ${tooltipClassname}`}>
                <p>{permission ? tooltip : strings.errors.not_authorized}</p>
            </div>
        </button>
    );
}

export const LargeButton = (props) => {

    const { action, iconName, iconClassnames, iconText, isDisabled, tooltipText } = props;

    return(
        <>
            <button 
                type="button"
                className={`
                    rounded-md border text-white flex items-center px-2 h-9 text-center w-full sm:max-w-max min-w-max group relative
                    ${iconName ? "search-button" : ""} 
                    ${isDisabled ? "opacity-70 hover:opacity-70 !cursor-not-allowed" : ""}
                    ${iconClassnames}
                `}
                onClick={action}
                disabled={isDisabled}
            >
                <div className='flex gap-1'>
                    {iconName && (
                        <span className={`material-symbols-outlined text-[18px] font-bold`}>
                            {iconName}
                        </span>
                    )}
                    <p>{iconText}</p>
                </div>
                {(isDisabled && tooltipText) && (
                    <div className="tooltip-top">
                        {tooltipText}
                    </div>
                )}
            </button>
        </>
    );
}

export const NormalButton = (props) => {

    const { type, classnames, isDisabled, buttonText, action, tooltip } = props;

    const classname = classnames === undefined ? '' : classnames;
    const buttonType = type === undefined ? 'button' : type;

    return(
      <button
        type={buttonType}
        className={
            `group button search-button gap-3 text-white px-10 mt-7 group relative
            ${isDisabled ? "opacity-70 hover:opacity-70 cursor-not-allowed" : ''} 
            ${classname}
        `}
        disabled={isDisabled}
        onClick={action}
      >
        {buttonText}

        {tooltip && (
            <div className="tooltip-top">
                {tooltip}
            </div>
        )}
      </button>
    );
}