
import {Loading, PageTitle} from "../../components";
import {useLocalization} from "../../hooks/useLocalization";
import {useEffect, useState} from "react";
import {GiftListTable, GiftListDetailSearch} from "./components";
import {convertDateFormat} from "../../components/Utility";
import {GetGiftList, GetUndeliveredGift} from "../../services/Gift/GiftService";
import useAuth from "../../hooks/useAuth";
import NotAuthorized from "../../components/NotAuthorized";

const GiftList = () => {

    const strings = useLocalization();

    const { employeeTypeId, checkPermission, getPermission } = useAuth();
    const roles = getPermission();
    const permission = checkPermission(roles, employeeTypeId);

    const [loading, setLoading] = useState(true);

    const [detailSearch, setDetailSearch] = useState({
        beginDate: convertDateFormat(new Date()),
        endDate: convertDateFormat(new Date()),
        isAllList: 0
    });
    const [giftData, setGiftData] = useState([]);

    const changeDetail = (values) => {
        setDetailSearch({ ...detailSearch, ...values });
    }

    const getGiftList = async () => {

        const result = await GetGiftList(detailSearch);

        setGiftData(giftData => result);
        setLoading(loading => false);
    }

    const getUndeliveredGiftList = async () => {

        const result = await GetUndeliveredGift();
        setGiftData(giftData => result);
        setLoading(loading => false);
    }

    useEffect(() => {

        let abortController = new AbortController();

        if(detailSearch.beginDate === convertDateFormat(new Date()) && detailSearch.endDate === convertDateFormat(new Date())) {
            getUndeliveredGiftList();
        } else {
            getGiftList();
        }

        return () => {
            abortController.abort();
        }
    }, [detailSearch])

    return (
        <>
            {permission && (
                <GiftListDetailSearch changeDetail={changeDetail} detailSearch={{detailSearch}} />
            )}
            <PageTitle title={strings.sidebar.gift.gift_list} />
            {!permission && (
                <NotAuthorized />
            )}

            {permission && (
                <>
                    {loading && (
                        <Loading />
                        )}

                    {!loading && (
                        <GiftListTable data={giftData} />
                    )}
                </>
            )}
        </>
    )

}

export default GiftList;